import React, { useState, useEffect, useRef } from 'react';
import { Box, TableContainer, useTheme, MenuItem, TextField, Stack, Button ,Paper} from '@mui/material';
import { Link } from 'react-router-dom';

import { NotificationManager } from 'react-notifications';  
import api from 'api'; 
import DeleteConfirmationDialog from '../../../component/DeleteConfirmationDialog/DeleteConfirmationDialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Header from 'components/Header';  
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader'; 
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { tokens } from '../../../theme';
export default function RoomTariffMaster(props) { 
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(false);
    const [rooms,setRooms]=useState(null);
    const [roomId,setRoomId]=useState(0);

    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null); 
    const deleteConfirmationDialog = useRef(null);

    var intialVal = {
        roomAno: 0,
        planName: '',
        singleBed: 0,
        doubleBed: 0,
        extraPersonWithBed: 0,
        extraPersonWithoutBed: 0

    };
    const [addNewItemDetail, setAddNewItemDetail] = useState(intialVal);

    useEffect(() => {
        if (rooms == null) {
            (async () => {
                await api
                    .get('api/master/hotel-room/get-room-list?status=Active')
                    .then((response) => {
                        //console.log(response.data);
                        setRooms(response.data);
                    })
                    .catch((error) => { 
                    });
            })();
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (roomId>0)
            await loadItem();
            else
            setItems([]);
        })();
    }, [roomId]); 

    const loadItem = async () =>{
        setLoading(true);
            await api.get('api/master/hotel-room-tariff/' + roomId)
                .then((response) => {
                    console.log(response.data);
                    setItems(response.data);
                })
                .catch(error => { 
                }).finally(() => {
                    setLoading(false);
                });
    }

    const onDelete = (index) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(index)]);
    }

    const deleteItemPermanently = async () => {
        deleteConfirmationDialog.current.close();
        setLoading(true);
        await api.delete('api/master/hotel-room-tariff?detailsId=' + selectedItem.id)
            .then((response) => {
                let itemsLocal = [...items];
                let indexOfDeleteItem = itemsLocal.indexOf(selectedItem);
                itemsLocal.splice(indexOfDeleteItem, 1);
               
                setTimeout(() => {
                    setLoading(false);
                    setSelectedItem(null);
                    setItems(itemsLocal);
                    NotificationManager.success('Tariff Deleted!');
                }, 2000);
            })
            .catch(error => {
            }).finally(() => {
                setLoading(false);
            });


    } 


    const saveTariff = async () =>{
        if (roomId === '') {
            NotificationManager.error("Select room to continue...");
            return;
        }

if (addNewItemDetail.planName === '') {
    NotificationManager.error("Plan Name Required !");
    return;
}

console.log(addNewItemDetail);
var temp = {...addNewItemDetail};
temp.roomAno = roomId;

console.log(JSON.stringify(temp));
setLoading(true);
await api.post('api/master/hotel-room-tariff', JSON.stringify(temp))
    .then(async (response) => {
        NotificationManager.success("Room Tariff Added Successfully.");
        setAddNewItemDetail(intialVal);
       await loadItem()
    })
    .catch(error => { 
    }).finally(() => {
        setLoading(false);
    });
    }

    return (
        <Box m="20px">
            <Header title="ROOM TARIFF MANAGEMENT" subtitle="manage hotel room tariff" />
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    p={2}
                > 
                       <TextField
                        label="Select Room"
                        value={roomId}
                        onChange={(e) => setRoomId(e.target.value)}
                        select 
                        fullWidth
                        variant="standard"
                    >
                         <MenuItem key={0} value={0}>
                                    Select Room
                                </MenuItem>
                      {rooms &&
                            rooms.map((room) => (
                                <MenuItem key={room.id} value={room.id}>
                                    {room.roomNo}
                                </MenuItem>
                            ))}
                    </TextField>
                    
                    {loading && <RctSectionLoader />}
                </Stack>

                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    p={2}
                >  
                        <TextField 
                            type="text"
                            label="Enter Plan Name"
                            value={addNewItemDetail.planName}
                            onChange={(e) => setAddNewItemDetail({...addNewItemDetail, planName:e.target.value})}
                        />   
                        <TextField 
                            type="number"
                            label="Single Bed (Charge)"
                            value={addNewItemDetail.singleBed}
                            onChange={(e) => setAddNewItemDetail({...addNewItemDetail, singleBed: e.target.value})}
                        />  
                        <TextField 
                            type='number'
                            label="Double Bed (Charge)"
                            value={addNewItemDetail.doubleBed}
                            onChange={(e) => setAddNewItemDetail({...addNewItemDetail, doubleBed : e.target.value})}
                        />  
                        <TextField 
                            type='number'
                            label="E.P. with Bed (Charge)"
                            value={addNewItemDetail.extraPersonWithBed}
                            onChange={(e) => setAddNewItemDetail({...addNewItemDetail, extraPersonWithBed : e.target.value})}
                        />  
                        <TextField 
                            type='text'
                            label="E.P. without Bed (Charge)"
                            value={addNewItemDetail.extraPersonWithoutBed}
                            onChange={(e) => setAddNewItemDetail({...addNewItemDetail, extraPersonWithoutBed : e.target.value})}
                        /> 
                        <Button variant='contained' color='secondary' onClick={(e) => saveTariff()}>Save</Button>
                </Stack>

                <Box
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    sx={{
                        '& .MuiTableHead-root': {
                            backgroundColor: colors.greenAccent[700]
                        },
                        '& .MuiTableRow-footer': {
                            backgroundColor: colors.redAccent[700]
                        }
                    }}
                >
                    <TableContainer component={Paper}>
                        {items && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Plan</TableCell>
                                        <TableCell>Single</TableCell>
                                        <TableCell>Double</TableCell>
                                        <TableCell>Extra Person (with Bed)</TableCell>
                                        <TableCell>Extra Person (without Bed)</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items &&
                                        items.map((item, key) =>
                                        <TableRow key={key}>
                                        <TableCell>{item.planName}</TableCell>
                                        <TableCell>{item.singleBed}</TableCell>
                                        <TableCell>{item.doubleBed}</TableCell>
                                        <TableCell>{item.extraPersonWithBed}</TableCell>
                                        <TableCell>{item.extraPersonWithoutBed}</TableCell>
                                        <TableCell> 
                                            <IconButton
                                                onClick={() => onDelete(item)}
                                                color="error"
                                                aria-label="Delete"
                                                size="small"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </Box>

                <DeleteConfirmationDialog
                    ref={deleteConfirmationDialog}
                    title="Are You Sure Want To Delete?"
                    message="This will delete item permanently."
                    onConfirm={() => deleteItemPermanently()}
                />
            </Box>
        </Box>
    );

}
