import React, { createContext, useState,useEffect } from 'react';
import api from 'api';
import { NotificationManager } from 'react-notifications';

const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {

    
    const handleLogin = async (formValue,setLoading) => { 
        setLoading(true);
        await api.post('api/TOKEN', formValue)
            .then((response) => {
                let expiresAt = JSON.stringify((response.data.expiration * 1000) + new Date().getTime());
                localStorage.setItem('access_token', response.data.token);
                localStorage.setItem('expires_at', expiresAt);
                localStorage.setItem("user_id", response.data.username);
                localStorage.setItem("tenantID", formValue.tenantID); 
                localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
                window.location.href = "/";
                NotificationManager.success('User Login Successfully!');
            })
            .catch(error => {
            }).finally(()=>{
                setLoading(false);
            });
    };

    const handleLogout = () => {
        console.log("logout callled");
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user_id');
        localStorage.removeItem('tenantID');
        window.location.href = "/login";

    };

    const value = {
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    return (
        <AuthContext.Provider value={value}> 
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return React.useContext(AuthContext);
};