import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme, TextField, Box, Button, MenuItem, Stack } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';

export default function AddNewItemForm() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();

    const [states, setStates] = useState([]);
    const [groups, setGroups] = useState(null);

    const id = location.state.id;

    const [addNewItemDetail, setAddNewItemDetail] = useState({
        no: 0,
        accountCode: '',
        accountName: '',
        accountGroup: 0,
        registrationType: '',
        partyType: '',
        name: '',
        address1: '',
        address2: '',
        state: '',
        gstin: '',
        mobile: '',
        email: '',
        status: 'Active'
    });

    const [loading, setLoading] = useState(false);

    const addNewItem = async () => {
        if (addNewItemDetail.accountName === '') {
            NotificationManager.error('AccountName Required !');
            return;
        }
        if (addNewItemDetail.accountGroup === 0) {
            NotificationManager.error('AccountGroup Required !');
            return;
        }
        setLoading(true);
        await api
            .post('api/master/account', JSON.stringify(addNewItemDetail))
            .then((response) => {
                console.log(response.data);
                NotificationManager.success('Added Successfully.');
                navigate('/app/account-master-list');
            })
            .catch((error) => {
                NotificationManager.error(error.response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (addNewItemDetail.no == 0 && id > 0) {
            (async () => {
                await api
                    .get('api/master/account/' + id)
                    .then((response) => {
                        console.log(response.data);

                        setAddNewItemDetail({
                            ...addNewItemDetail,
                            no: response.data.no,
                            accountCode: response.data.accountCode,
                            accountName: response.data.accountName,
                            accountGroup: response.data.accountGroup,
                            registrationType: response.data.registrationType,
                            partyType: response.data.partyType,
                            name: response.data.name,
                            address1: response.data.address1,
                            address2: response.data.address2,
                            state: response.data.state,
                            gstin: response.data.gstin,
                            mobile: response.data.mobile,
                            email: response.data.email,
                            status: response.data.status
                        });
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error.response.data));
                    });
            })();
        }
    }, [id > 0]);

    const updateItem = async () => {
        if (addNewItemDetail.accountName === '') {
            NotificationManager.error('AccountName Required !');
            return;
        }
        if (addNewItemDetail.accountGroup === '') {
            NotificationManager.error('AccountGroup Required !');
            return;
        }

        setLoading(true);
        await api
            .put('api/master/account?id=' + id, JSON.stringify(addNewItemDetail))
            .then((response) => {
                NotificationManager.success('Updated Successfully.');
                navigate('/app/account-master-list');
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error.response.data));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (groups === null) {
            (async () => {
                await api
                    .get('api/master/account-group')
                    .then((response) => {
                        setGroups(response.data);
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error.response.data));
                    });
            })();
        }
        if (states.length == 0) {
            (async () => {
                await api
                    .get('/api/master/state?PageNumber=1&PageSize=100&status=Active')
                    .then((response) => {
                        setStates(response.data.data);
                    })
                    .catch((error) => {
                        NotificationManager.error(JSON.stringify(error));
                    })
                    .finally(() => {});
            })();
        }
    });

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="ACCOUNT MASTER" subtitle="ledgers" />
            </Box>
            {loading && <RctSectionLoader />}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                    <TextField
                        type="text"
                        label="Account Code"
                        value={addNewItemDetail.accountCode}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, accountCode: e.target.value })}
                        variant="standard"
                        autoFocus={true}
                    />
                    <TextField
                        label="Account Name"
                        value={addNewItemDetail.accountName}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, accountName: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Select Group"
                        value={addNewItemDetail.accountGroup}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, accountGroup: e.target.value })}
                        select
                        fullWidth
                        variant="standard"
                    > 
                    <MenuItem key={0} value={0}>Select Account Group</MenuItem>
                        {groups &&
                            groups.map((group) => (
                                <MenuItem key={group.no} value={group.no}>
                                    {group.groupName}
                                </MenuItem>
                            ))}
                    </TextField>
                    <TextField
                        label="Name"
                        value={addNewItemDetail.name}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, name: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        type="text"
                        label="Addres1"
                        value={addNewItemDetail.address1}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, address1: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Addres2"
                        value={addNewItemDetail.address2}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, address2: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Select State"
                        value={addNewItemDetail.state}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, state: e.target.value })}
                        select
                        fullWidth
                        variant="standard"
                    > 
                        {states &&
                            states.map((group) => (
                                <MenuItem key={group.stateName} value={group.stateName}>
                                    {group.stateName}
                                </MenuItem>
                            ))}
                    </TextField>
                    <TextField
                        label="GSTIN"
                        value={addNewItemDetail.gstin}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, gstin: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        type={'number'}
                        label="Mobile"
                        value={addNewItemDetail.mobile}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, mobile: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Email"
                        type={'email'}
                        value={addNewItemDetail.email}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, email: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Select Status"
                        value={addNewItemDetail.status}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, status: e.target.value })}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem selected key={0} value="Active">
                            Active
                        </MenuItem>
                        <MenuItem key={1} value="InActive">
                            InActive
                        </MenuItem>
                    </TextField>
                    <Stack direction="row" spacing={2}>
                        {id === 0 ? (
                            <Button variant="contained" color="secondary" className="text-white btn-success" onClick={() => addNewItem()}>
                                Add
                            </Button>
                        ) : (
                            <Button variant="contained" color="secondary" onClick={() => updateItem()}>
                                Update
                            </Button>
                        )}

                        {'  '}
                        <Button variant="contained" color="error" onClick={() => navigate('/app/account-master-list')}>
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}
