import { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Box, Stack, useTheme, Button } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';
const ImportItems = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const navigate = useNavigate();

    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const selectFile = (event) => {
        setSelectedFiles(event.target.files);
    };

    const uploadFile = (file, onUploadProgress) => {
        let formData = new FormData();

        formData.append('file', file);

        return api.post('api/master/product/import-product', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress
        });
    };

    const upload = () => {
        let currentFile = selectedFiles[0];

        setProgress(0);
        setCurrentFile(currentFile);

        uploadFile(currentFile, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((response) => {})
            .then((files) => {
                NotificationManager.success('Success');
                navigate('/app/item-master-list');
            })
            .catch(() => {
                setProgress(0);
                NotificationManager.error('Could not upload the file!');
                setCurrentFile(undefined);
            });

        setSelectedFiles(undefined);
    };

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="IMPORT ITEM MASTER" subtitle="import item from excel" />
            </Box>
            {loading && <RctSectionLoader />}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                    {loading && <RctSectionLoader />}
                    {currentFile && (
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                aria-valuenow={progress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progress + '%' }}
                            >
                                {progress}%
                            </div>
                        </div>
                    )}

                    <input type="file" onChange={selectFile} />

                    <Button variant="contained" color="secondary" disabled={!selectedFiles} onClick={upload}>
                        Upload
                    </Button>
                    <Button variant="contained" color="error" onClick={() => navigate('/app/item-master-list')}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default ImportItems;
