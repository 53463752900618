/**
 * Blank Page
 */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { Helmet } from "react-helmet";
import api from 'api';
// page title bar
import Header from 'components/Header';
import { Button } from 'reactstrap';

import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
    Card,
    CardBody,
    CardHeader,
    CardFooter
} from 'reactstrap';

import ApplyDiscount from '../../component/CustomComponentRestaurant/ApplyDiscount';
import SearchCustomer from '../../component/CustomComponentRestaurant/SearchCustomer';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader'; 

export default function TableList(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const customerInputRef = useRef();
    const billDetailsInitialValue = {
        menuItems: [],
        startSubmit: false,
        discPer: 0,
        netAmt: 0,
        mopDatas: [],
        paidAmount: 0,
        customer: null,
    };
    const [billDetails, setBillDetails] = useState(billDetailsInitialValue);

    const TableName = location.state.tableName;

    const savedBillDetailsInatialValue = {
        billID: 0,
        printBillTag: false,
        sendSMSTag: false
    };
    const [savedBillDetails, setSavedBillDetails] = useState(savedBillDetailsInatialValue);


    useEffect(() => {
        if (billDetails.menuItems === null || billDetails.menuItems === undefined || billDetails.menuItems.length == 0) {
            (async () => {
                setBillDetails({ ...billDetails, startSubmit: true });
                await api.get(`api/rest/kot-bill/get-item-from-table?tableName=${TableName}`)
                    .then((response) => {
                        //console.log(response.data);
                        setBillDetails({ ...billDetails, menuItems: response.data, startSubmit: false, discPer: 0 });
                        // tempDataForFristTimeCalculation = response.data;
                        // applyDiscount();
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error));
                        navigate('/app/new-order');
                    }).finally(() => {
                    });
            })();
        }
    }, []);

    const applyDiscount = () => {
        if (billDetails.menuItems.length == 0) {
            return;
        }
        if (billDetails.discPer < 0 || billDetails.discPer > 100) {
            NotificationManager.error("Discount must be greater than ZERO (0) or less than 100.")
            return
        }
        let arrs = [...billDetails.menuItems];

        let netAmt = 0;

        arrs.map((arr, key) => {
            if (arr["incTax"] === 0) {
                arr["rate"] = arr["mrp"]
            } else {
                arr["rate"] = ((arr["mrp"] * 100) / (100 + (arr["cgstper"] + arr["sgstper"])))
            }
            arr["rate"] = parseFloat(arr["rate"] - (arr["rate"] * billDetails.discPer / 100)).toFixed(2)

            arr["taxable"] = parseFloat(arr["rate"] * arr["qty"]).toFixed(2);
            arr["cgstamt"] = parseFloat(arr["taxable"] * arr["cgstper"] / 100).toFixed(2);
            arr["sgstamt"] = parseFloat(arr["taxable"] * arr["sgstper"] / 100).toFixed(2);
            arr["cessamt"] = parseFloat(arr["taxable"] * ((arr["cessper"]) ?? 0) / 100).toFixed(2);
            arr["amount"] = (Number(arr["taxable"] ?? 0) + Number(arr["cgstamt"] ?? 0) + Number(arr["sgstamt"] ?? 0) + Number(arr["igstamt"] ?? 0) + Number(arr["cessamt"] ?? 0)).toFixed(2);
            netAmt += Math.round(parseFloat(arr["amount"]));
        });
        setBillDetails({ ...billDetails, netAmt: netAmt, menuItems: arrs });
    };

    const handleSaveBill = async () => {
        const billData = {
            tableName: TableName,
            discPer: billDetails.discPer,
            customerID: billDetails.customer == null ? 0 : billDetails.customer.id,
        };
        setBillDetails({ ...billDetails, startSubmit: true });
        await api.post('api/rest/kot-bill/save-bill', JSON.stringify(billData))
            .then((response) => {
                if (response.status === 200) {
                    setSavedBillDetails({ ...savedBillDetails, billID: response.data.id, printBillTag: true });
                    NotificationManager.success("Bill Saved Successfully.");
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                console.log(JSON.stringify(error));
                NotificationManager.error(error.response.data);
            })
            .finally(() => {
            });

    };

    useEffect(() => {
        applyDiscount();
    }, [billDetails.discPer, billDetails.menuItems.length])

    useEffect(() => {
        (async () => {
            if (savedBillDetails.printBillTag == true && savedBillDetails.billID > 0) {
                console.log("Print Bill called !");
                await api.get('api/rest/kot-bill/print-bill?billID=' + savedBillDetails.billID)
                    .then((response) => {
                        window.open('' + response.data + '', 'sharer', 'toolbar=0,status=0,width=548,height=525');
                    })
                    .catch(error => {
                        NotificationManager.error(error.response.data);
                    }).finally(() => {
                        setSavedBillDetails({ ...savedBillDetails, printBillTag: false, sendSMSTag: true });
                    });
            }
        })();
    }, [savedBillDetails.printBillTag]);

    useEffect(() => {

        (async () => {
            if (billDetails.customer == null && savedBillDetails.sendSMSTag == true && savedBillDetails.billID > 0)
                navigate('/app/new-order');

            if (savedBillDetails.sendSMSTag == true && savedBillDetails.billID > 0) {
                await api.get('api/rest/kot-bill/send-sms-on-bill?billID=' + savedBillDetails.billID)
                    .then((response) => {
                    })
                    .catch(error => {
                        NotificationManager.error(error.message);
                    }).finally(() => {
                        navigate('/app/new-order');
                    });
            }
        })();
    }, [savedBillDetails.sendSMSTag]);






    return (
        <>
            <div className="card-wrapper">
                {/* HEADER */}
            <Header title="MAKE BILL" subtitle="manage item category" />
                <div className="col-md-12">
                    <Card>
                        {billDetails.startSubmit &&
                            <RctSectionLoader />
                        }
                        <CardHeader color="warning">
                            <div className="row">
                                <div className="col-md-9">
                                    <b>Table Sale (Table Name : {TableName})</b>
                                </div>
                                <div className="col-md-3">
                                    <Button color="danger" onClick={handleSaveBill} disabled={billDetails.startSubmit}>
                                        {billDetails.startSubmit ? 'Please wait...' : 'Save & Print'}<small>{'\u00A0'}{'\u00A0'}({'\u20B9'}{billDetails.netAmt})</small>
                                    </Button>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-2">
                                    <ApplyDiscount billDetails={billDetails} setBillDetails={setBillDetails} />
                                </div>
                                <div className="col-md-10">
                                    <SearchCustomer billDetails={billDetails} setBillDetails={setBillDetails} customerInputRef={customerInputRef} />
                                </div>
                            </div>
                            <RctCollapsibleCard>
                                <div className="table-responsive">
                                    <Table>
                                        <TableHead>
                                            <TableRow hover>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Qty</TableCell>
                                                <TableCell>Rate</TableCell>
                                                <TableCell>Taxable</TableCell>
                                                <TableCell>CGST</TableCell>
                                                <TableCell>SGST</TableCell>
                                                <TableCell>Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {billDetails.menuItems.length > 0
                                                && billDetails.menuItems.map((billData, key) => (
                                                    <TableRow hover key={key}>
                                                        <TableCell>{billData["itemName"]}</TableCell>
                                                        <TableCell>{billData["qty"]}</TableCell>
                                                        <TableCell>{billData["rate"]}</TableCell>
                                                        <TableCell>{billData["taxable"]}</TableCell>
                                                        <TableCell>{billData["cgstamt"]}</TableCell>
                                                        <TableCell>{billData["sgstamt"]}</TableCell>
                                                        <TableCell>{billData["amount"]}</TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </RctCollapsibleCard>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    )
}
