import React, { useState, useEffect } from 'react';
import { Typography, Box, useTheme, styled, IconButton } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';

import PrintQrCode from 'views/master/PrintQrCodeScan2Order';
import SMSSettingsMaster from 'views/master/SmsSettings';
import DayClosing from 'views/master/DayClosing';
import PrintInfo from 'views/master/PrintInfo';
import PermissionSettings from 'views/master/PermissionSettings';

import { tokens } from '../theme';
import Header from 'components/Header';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function VerticalTabs() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box m={'20px'}>
            <Header title="Downloads" subtitle="download helper apps" />
            <Box>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Download Billing Offline Tools</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                            <a href="http://downloads.hellopatna.com/BilltronRestaurantSetup.zip" target="_blank">
                                <DownloadOutlined />
                            </a>
                        </Typography>
                    <Typography variant='subtitle1'>
                        Step 1 : Enter Restaurant Id,Login Id and Password <br />
                        Step 2 : Click Load Master <br />
                        Step 3 : Restart Application <br />
                        Step 4 : Start Billing
                    </Typography>
                       
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Download Captain Ordering App</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                            <a href="https://play.google.com/store/apps/details?id=com.billtroncaptainorderingapp" target="_blank">
                                <DownloadOutlined />
                            </a>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/* <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Collapsible Group Item #3</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
                            lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion> */}
            </Box>
        </Box>
    );
}
