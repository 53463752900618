import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import api from 'api';
import { useFetchItemMaster} from '../../hooks/useFetchData'

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RctCollapsibleCard from 'component/RctCollapsibleCard/RctCollapsibleCard';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { Button } from 'reactstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Autocomplete, TextField } from '@mui/material';
import RctSectionLoader from 'component/RctSectionLoader/RctSectionLoader';
import Box from "@mui/material/Box";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';

function TabContainer({ children }) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

let selectedIndex = 0;
const ItemSelection = forwardRef((props, ref) => {
    const [categoryDatas, setCategoryDatas] = useState(undefined);
    // const [itemDatas, setItemDatas] = useState(undefined);

    const [filteredItems, setFilteredItems] = useState(undefined);
    const [activeIndex, setActiveIndex] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const [selectedItem, setSelectedItem] = useState({ item: null, qty: 0 });

    const qtyRef = useRef();
 
    useImperativeHandle(
        ref,
        () => ({
            applyDiscount(arrs, discPer) {
                calculateDiscount(arrs, discPer);
            }
        }),
    )
 
    useEffect(() => {
        itemDatas &&    (() => {
                if (categoryDatas !== undefined && itemDatas !== undefined) {
                let catName = categoryDatas[activeIndex];
                let tfilteredItem = (itemDatas.data.filter(e => e.categoryName == catName));
                selectedIndex = activeIndex; 
                setFilteredItems(tfilteredItem);
            }
        })();
    }, [activeIndex, searchText, categoryDatas]);

    // const onloadItems = (data) => {
      
    //     setCategoryDatas([...new Set(data.data.sort((a, b) => a.categoryName > b.categoryName ? 1 : -1).map(item => item.categoryName))]);
    //     setActiveIndex(0);
    // };

   const {data : itemDatas } =  useFetchItemMaster()

   if (categoryDatas == undefined && itemDatas != undefined ){ 
     // setItemDatas(data.data.sort((a, b) => a.itemName > b.itemName ? 1 : -1));
        setCategoryDatas([...new Set(itemDatas.data.sort((a, b) => a.categoryName > b.categoryName ? 1 : -1).map(item => item.categoryName))]);
        setActiveIndex(0); 
   }
   
    const calculateDiscount = (farrs, discPer) => {
        if (discPer < 0 || discPer > 100) {
            discPer = 0;
        }
        let arrs = farrs;
        let netAmt = 0;

        arrs.map((arr, key) => {
            if (arr["incTax"] === 0) {
                arr["rate"] = arr["mrp"]
            } else {
                arr["rate"] = ((arr["mrp"] * 100) / (100 + (arr["cgstper"] + arr["sgstper"])))
            }
            arr["rate"] = parseFloat(arr["rate"] - (arr["rate"] * discPer / 100)).toFixed(2)

            arr["taxable"] = arr["rate"] * arr["qty"]
            arr["cgstamt"] = arr["taxable"] * arr["cgstper"] / 100
            arr["sgstamt"] = arr["taxable"] * arr["sgstper"] / 100
            arr["cessamt"] = arr["taxable"] * ((arr["cessper"]) ?? 0) / 100
            arr["amount"] = (Number(arr["taxable"]) + Number(arr["cgstamt"]) + Number(arr["sgstamt"]) + Number(arr["igstamt"]) + Number(arr["cessamt"])).toFixed(2);
            netAmt += Math.round(parseFloat(arr["amount"]));
        });
        props.setBillDetails({ ...props.billDetails, netAmt: netAmt, menuItems: arrs });
    };

    const handleChange = (value) => {
        setActiveIndex(value);
    };

    const onItemClick = (itemCode, itemName, mrp, productChildID, cgstPer, sgstPer, cessPer, incTax, qty) => {
        let arrs = [...props.billDetails.menuItems];
        const elementsIndex = arrs.findIndex(element => element["productChildID"] == productChildID);
        if (elementsIndex === -1) {
            arrs = arrs.concat([{ "qty": parseFloat(qty), "itemCode": itemCode, "itemName": itemName, "mrp": mrp, "rate": mrp, "taxable": mrp, "cgstper": cgstPer, "cgstamt": 0, "sgstper": sgstPer, "sgstamt": 0, "igstper": 0, "igstamt": 0, "cessper": cessPer, "cessamt": 0, "productChildID": productChildID, "remarks": '', "amount": mrp, "incTax": incTax }]);
            props.setBillDetails({ ...props.billDetails, menuItems: arrs });
        } else {
            let currentQtyCount = arrs[elementsIndex]["qty"];
            arrs[elementsIndex]["qty"] = currentQtyCount + parseFloat(qty);
            props.setBillDetails({ ...props.billDetails, menuItems: arrs });
        }
        calculateDiscount(arrs, props.billDetails.discPer)

        setSelectedItem({ item: null, qty: 0 });
    };

    const callBackIncrement = (productChildID, action) => {
        let arrs = [...props.billDetails.menuItems];
        const elementsIndex = arrs.findIndex(element => element["productChildID"] == productChildID);
        let currentQtyCount = arrs[elementsIndex]["qty"];
        if (action === "+") {
            arrs[elementsIndex]["qty"] = currentQtyCount + 1;
        }
        else if (action === "-") {
            if (currentQtyCount === 1) {
                arrs.splice(elementsIndex, 1);
            } else {
                arrs[elementsIndex]["qty"] = currentQtyCount - 1;
            }
        }
        else {
            arrs.splice(elementsIndex, 1);
        }
        props.setBillDetails({ ...props.billDetails, menuItems: arrs });
        calculateDiscount(arrs, props.billDetails.discPer);
    };

    const onAddItemButtonClicked = () => {
        if (selectedItem.item == null) {
            NotificationManager.error("Select item to add !");
            props.itemInputRef.current.focus();
            return;
        }
        if (selectedItem.qty == 0) {
            NotificationManager.error("Enter qty !");
            qtyRef.current.focus();
            return;
        }
        let obj = selectedItem.item;
        onItemClick(obj.itemCode, obj.itemName, obj.mrp, obj.productChildId, obj.cgstPer, obj.sgstPer, obj.cessPer, obj.incTax, selectedItem.qty)
        props.itemInputRef.current.focus();
    };

 

    return (
        <>
            {loading &&
                <RctSectionLoader />
            } 
            <div className='row'>
                <div className='col-md-7'>
                    <div className='row mb-10'>
                        <div className='col-md-8'>
                            {itemDatas && <Autocomplete
                                value={selectedItem.item}
                                options={itemDatas.data}
                                sx={{ width: '100%' }}
                                getOptionLabel={(option) => option.itemName + " - " + option.itemCode + " - " + option.mrp}
                                onChange={(e, v) => {
                                    setSelectedItem({ ...selectedItem, item: v });
                                    qtyRef.current.focus();
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.id}>
                                        {option.itemName + " - " + option.itemCode + " - " + option.mrp}
                                    </Box>
                                )}
                                renderInput={
                                    (params) => <TextField {...params} label="Autocomplete (ItemName)"
                                        inputRef={props.itemInputRef} />
                                }
                            />}
                        </div>
                        <div className='col-md-3'>
                            <TextField type='number' fullWidth label="Qty" value={selectedItem.qty}
                                inputRef={qtyRef}
                                onChange={(e) => setSelectedItem({ ...selectedItem, qty: e.target.value.length == 0 ? 0 : e.target.value })}
                                onKeyPress={(event) => {
                                    if (event.charCode === 13) {
                                        onAddItemButtonClicked()
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className="col-md-12">
                            <AppBar position="static" color="primary"
                                style={{ borderRadius: "80px", backgroundColor: 'red' }}>
                                <Tabs
                                    orientation="horizontal"
                                    value={activeIndex}
                                    onChange={(e, value) => handleChange(value)}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    indicatorColor="primary"
                                    textColor="inherit">
                                    {
                                        categoryDatas !== undefined && categoryDatas !== null
                                            ? categoryDatas.map((obj, key) => (
                                                <Tab label={obj} key={key} style={{ fontWeight: 'bold', fontSize: '12px' }} />
                                            ))
                                            : null}
                                </Tabs>
                            </AppBar>

                            {activeIndex === selectedIndex && <TabContainer>
                                {filteredItems !== undefined
                                    ? filteredItems.map((obj, key) => ( 
                                            <Button key={obj.itemCode} className='mr-10 mb-10' type="submit" color={obj.redTag == 1 ? "danger" : "success"}
                                                style={{
                                                    width: 130, height: 50, fontSize: '10px', textAlign: 'center', wordBreak: 'keep-all'
                                                }}
                                                onClick={() => onItemClick(obj.itemCode, obj.itemName, obj.mrp, obj.productChildId, obj.cgstPer, obj.sgstPer, obj.cessPer, obj.incTax, 1)}                                               
                                            >
                                                {obj.itemName}
                                            </Button> 
                                    ))
                                    : null}
                            </TabContainer>}
                            {/* 
button with word-break
<button type="submit">
<span style={{wordBreak:'keep-all',display: 'block',
width: '50px', 
margin: 0,textAlign:'center'}}>My Button Example</span>
</button>  */}
                        </div>
                    </div>

                </div>
                <div className="col-md-5">
                    <RctCollapsibleCard>
                        <div className="table-responsive">
                            <Table>
                                <TableHead>
                                    <TableRow hover>
                                        <TableCell>Item</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <Fragment>
                                        {props.billDetails.menuItems && props.billDetails.menuItems.map((menuItem, key) => (
                                            <TableRow hover key={key}>
                                                <TableCell>{menuItem["itemName"]} <br /> (<small>Rate : {'\u20B9'}{menuItem["mrp"]}</small>)</TableCell>
                                                <TableCell>{menuItem["amount"]} <br /> <small><b>({menuItem["qty"]} * {menuItem["rate"]})</b></small></TableCell>
                                                <TableCell> <div>
                                                    <IconButton color="primary" onClick={() => callBackIncrement(menuItem["productChildID"], "+")}>
                                                        <AddCircleOutlineRoundedIcon></AddCircleOutlineRoundedIcon>
                                                    </IconButton>
                                                    <IconButton color="primary" onClick={() => callBackIncrement(menuItem["productChildID"], "-")}>
                                                        <RemoveCircleOutlineRoundedIcon></RemoveCircleOutlineRoundedIcon>
                                                    </IconButton>
                                                    <IconButton color="error" onClick={() => callBackIncrement(menuItem["productChildID"], "*")}>
                                                        <DeleteOutlineRoundedIcon></DeleteOutlineRoundedIcon>
                                                    </IconButton>
                                                </div></TableCell>
                                            </TableRow>
                                        ))}
                                    </Fragment>
                                </TableBody>
                            </Table>
                        </div>
                    </RctCollapsibleCard>
                </div>
            </div>
        </>
    )
})
export default ItemSelection;

