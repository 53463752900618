import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Tabs,Tab,Typography,Box,useTheme} from '@mui/material';  
  
import PrintQrCode from 'views/master/PrintQrCodeScan2Order';
import SMSSettingsMaster from 'views/master/SmsSettings';
import DayClosing from 'views/master/DayClosing';
import PrintInfo from 'views/master/PrintInfo';
import PermissionSettings from 'views/master/PermissionSettings';

import { tokens } from '../theme'; 
import Header from 'components/Header';

function TabPanel(props) {
   

    const { children, value, index } = props;

    return ( 
        <>
        {value === index && ( 
            <Box p={2} gridColumn="span 12" flex={1} display='grid'>  
                {children}
            </Box> 
        )}
        </>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box m={'20px'}>
             <Header title="Settings" subtitle="manager all the settings here" />
            <Box sx={{ flexGrow: 1, display: 'flex', height: '100%' }}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ borderRight: 2, borderColor: 'divider' }}
                    textColor="secondary"
                    indicatorColor="secondary"
                > 
                    <Tab label="Print QRCode (Feedback)" {...a11yProps(0)} />
                    <Tab label="SMS Settings" {...a11yProps(1)} />
                    <Tab label="Day Closing" {...a11yProps(2)} />
                    <Tab label="Print Info" {...a11yProps(3)} />
                    <Tab label="Permission" {...a11yProps(4)} />
                </Tabs> 
                <TabPanel value={value} index={0} >
                    <PrintQrCode />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SMSSettingsMaster />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <DayClosing />
                </TabPanel>
                <TabPanel value={value} index={3}>      
                    <PrintInfo /> 
                </TabPanel>
                <TabPanel value={value} index={4}>      
                    <PermissionSettings /> 
                </TabPanel>
            </Box>
        </Box>
    );
}