import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme, TextField, Box, Button, MenuItem, Stack ,Switch,FormControlLabel} from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';

export default function AddNewItemForm() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state.id;
    const items = location.state.items;

    const [addNewItemDetail, setAddNewItemDetail] = useState({
        id: 0,
        name: '',
        slug: '',
        description: '',
        metaTitle: '',
        metaKeywords: '',
        metaDescription: '',
        displayOrder: 0,
        parentId: 0,
        includeInMenu: false,
        isPublished: true,
        status: 'Active',
        thumbnailImageUrl: null,
        thumbnailImage: null
    });

    const [loading, setLoading] = useState(false); 

    const addNewItem = async () => {
        if (addNewItemDetail.name === '') {
            NotificationManager.error('Category Required !');
            return;
        }
        const dataArray = new FormData();
        dataArray.append('name', addNewItemDetail.name);
        dataArray.append('slug', addNewItemDetail.slug);
        dataArray.append('description', addNewItemDetail.description);
        dataArray.append('metaTitle', addNewItemDetail.metaTitle);
        dataArray.append('metaKeywords', addNewItemDetail.metaKeywords);
        dataArray.append('metaDescription', addNewItemDetail.metaDescription);
        dataArray.append('displayOrder', addNewItemDetail.displayOrder);
        dataArray.append('parentId', addNewItemDetail.parentId);
        dataArray.append('includeInMenu', addNewItemDetail.includeInMenu);
        dataArray.append('isPublished', addNewItemDetail.isPublished);
        dataArray.append('thumbnailImage', addNewItemDetail.thumbnailImage);
        setLoading(true);
        await api
            .post('api/master/product-department', dataArray, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Category Added Successfully.');
                    navigate('/app/category-master-list');
                } else {
                    NotificationManager.error(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (addNewItemDetail.id == 0 && id > 0) {
            (async () => {
                await api
                    .get('api/master/product-department/' + id)
                    .then((response) => {
                        setAddNewItemDetail({
                            ...addNewItemDetail,
                            id: response.data.prdGrpNo,
                            name: response.data.productGrp,
                            slug: response.data.slug == null ? '' : response.data.slug,
                            description: response.data.description ?? '',
                            metaTitle: response.data.metaTitle ?? '',
                            metaKeywords: response.data.metaKeywords ?? '',
                            metaDescription: response.data.metaDescription ?? '',
                            displayOrder: response.data.displayOrder ?? 0,
                            parentId: response.data.parentId ?? 0,
                            includeInMenu: response.data.includeInMenu ?? false,
                            isPublished: response.data.isPublished ?? false,
                            status: response.data.status,
                            thumbnailImageUrl: response.data.thumbnailImageUrl,
                            thumbnailImage: null
                        });
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error));
                    });
            })();
        }
    }, [id > 0]);

    const updateItem = async () => {
        if (addNewItemDetail.name === '') {
            NotificationManager.error('Category Required !');
            return;
        }
        const dataArray = new FormData();
        dataArray.append('id', addNewItemDetail.id);
        dataArray.append('name', addNewItemDetail.name);
        dataArray.append('slug', addNewItemDetail.slug);
        dataArray.append('description', addNewItemDetail.description);
        dataArray.append('metaTitle', addNewItemDetail.metaTitle);
        dataArray.append('metaKeywords', addNewItemDetail.metaKeywords);
        dataArray.append('metaDescription', addNewItemDetail.metaDescription);
        dataArray.append('displayOrder', addNewItemDetail.displayOrder);
        dataArray.append('parentId', addNewItemDetail.parentId);
        dataArray.append('includeInMenu', addNewItemDetail.includeInMenu);
        dataArray.append('isPublished', addNewItemDetail.isPublished);
        dataArray.append('thumbnailImage', addNewItemDetail.thumbnailImage);
        dataArray.append('status', addNewItemDetail.status);

        setLoading(true);
        await api
            .put('api/master/product-department', dataArray, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                NotificationManager.success('Category Updated Successfully.');
                navigate('/app/category-master-list');
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error.response.data));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="CATEGORY MASTER" subtitle="add/edit category" />
            </Box>
            {loading && <RctSectionLoader />}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                    <TextField
                        type="text"
                        label="Name"
                        value={addNewItemDetail.name}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, name: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Slug"
                        value={addNewItemDetail.slug}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, slug: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Meta Title"
                        value={addNewItemDetail.metaTitle}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, metaTitle: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Meta Keywords"
                        value={addNewItemDetail.metaKeywords}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, metaKeywords: e.target.value })}
                        multiline
                        rows={3}
                        variant="standard"
                    />
                    <TextField
                        label="Meta Description"
                        value={addNewItemDetail.metaDescription}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, metaDescription: e.target.value })}
                        multiline
                        rows={3}
                        variant="standard"
                    />
                     <TextField
                        label="Parent Category"
                        value={addNewItemDetail.parentId}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, parentId: e.target.value })}
                        select
                        fullWidth
                        variant="standard"
                    > 
                        <MenuItem selected key={0} value={0}>
                                Top
                            </MenuItem>
                            {items &&
                                items.map((item, key) => (
                                    <MenuItem selected key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                    </TextField> 
                    <TextField
                        label="Description"
                        value={addNewItemDetail.description}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, description: e.target.value })}
                        multiline
                        rows={3}
                        variant="standard"
                    />
                    <TextField
                        label="Display Order"
                        type="number"
                        value={addNewItemDetail.displayOrder}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, displayOrder: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Thumbnail Image"
                        type="file"
                        onChange={(e) =>
                            setAddNewItemDetail({
                                ...addNewItemDetail,
                                thumbnailImageUrl: URL.createObjectURL(e.target.files[0]),
                                thumbnailImage: e.target.files[0]
                            })
                        }
                        variant="standard"
                    />
                    <img src={addNewItemDetail.thumbnailImageUrl} alt="" height={200} width={200} />

                    <FormControlLabel
                        control={
                            <Switch
                                value={addNewItemDetail.includeInMenu}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, includeInMenu: e.target.value })}
                            />
                        }
                        label="Include in Menu"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                value={addNewItemDetail.isPublished}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, isPublished: e.target.value })}
                                defaultChecked
                            />
                        }
                        label="Is Published"
                    />
                     <TextField
                        label="Select Group"
                        value={addNewItemDetail.status}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, status: e.target.value })}
                        select
                        fullWidth
                        variant="standard"
                    > 
                       <MenuItem selected key={0} value="Active">
                                Active
                            </MenuItem>
                            <MenuItem key={1} value="InActive">
                                InActive
                            </MenuItem>
                    </TextField> 
                    {id == 0 ? (
                        <Button variant="contained" color="secondary" onClick={() => addNewItem()}>
                            Add
                        </Button>
                    ) : (
                        <Button variant="contained" color="secondary" onClick={() => updateItem()}>
                            Update
                        </Button>
                    )}

                    {'  '}
                    <Button variant="contained" color="error" onClick={() => navigate('/app/category-master-list')}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}
