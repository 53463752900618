import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { NotificationManager } from 'react-notifications';
// api
import api from 'api';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import { Link } from 'react-router-dom';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';


import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


//multiselect
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const AddNewItemForms = (props) => {


    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
        target: { value },
        } = event;
        setPersonName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
    };

    const [age, setAge] = React.useState('');

    const handleChanges = (event) => {
        setAge(event.target.value);
    };


    const [addNewItemDetail, setAddNewItemDetail] = useState({
        itemType: "Goods",
        departmentId: 0,
        brandId: 0,
        id: 0,
        itemCode: '',
        itemName: '',
        hsncode: '',
        mrp: 0,
        redTag: 0,
        openItem: 0,
        incTax: 0,
        status: 'Active',
        counterId: 0,
        GenerateChildId: "No",
        discountable: true,
        saleable: 1,
        cgstid: 0,
        sgstid: 0,
        igstid: 0,
        cessId: 0,
        unitId: 1,
        taxibility: "Taxable",

    });
    const [loading, setLoading] = useState(false);
    const [departments, setDepartments] = useState(null);
    const [brands, setBrands] = useState(null);
    const [counters, setCounters] = useState(null);
    const [gsts, setGsts] = useState(null);

    const onChangeAddNewItemDetails = (key, value) => {
        var arr = { ...addNewItemDetail };
        arr[key] = value;
        setAddNewItemDetail(prevState => arr);
    }

    const addNewItem = async () => {
        if (addNewItemDetail.itemCode === '') {
            NotificationManager.error("Item Code Required !");
            return;
        }
        if (addNewItemDetail.itemName === '') {
            NotificationManager.error("Item Name Required !");
            return;
        }
        //console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api.post('api/master/product', JSON.stringify(addNewItemDetail))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Item Added Successfully.");

                    props.setAddNewItemModal(false);
                    props.setEditItem(null);

                    props.loadItems();
                }
                else {
                    NotificationManager.error(JSON.stringify(response));
                    setLoading(false);
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
                setLoading(false);
            });
    }

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    }

    useEffect(() => {
        if (departments === null) {
            (async () => {
                await api.get('api/master/product-department')
                    .then((response) => {
                        setDepartments(response.data);
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error));
                    });
            })();
        }

        (async () => {
            await api.get('api/master/product-brand')
                .then((response) => {
                    setBrands(response.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });
        })();

        (async () => {
            await api.get('api/master/counter-master')
                .then((response) => {
                    setCounters(response.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });
        })();

        (async () => {
            await api.get('api/master/gst-master')
                .then((response) => {
                    setGsts(response.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });
        })();

        (async () => {
            await setItemDetails();
        })();


    }, [props.editItem]);

    const setItemDetails = async () => {
        console.log(props.editItem);
        if (props.editItem != null) {
            await api.get('api/master/product/' + props.editItem.id)
                .then((response) => {
                    console.log(response.data);
                    setAddNewItemDetail({
                        itemType: response.data.itemType,
                        departmentId: response.data.departmentId,
                        brandId: response.data.brandId,
                        id: response.data.id,
                        itemCode: response.data.itemCode,
                        itemName: response.data.itemName,
                        hsncode: response.data.hsncode,
                        mrp: response.data.mrp,
                        redTag: response.data.redTag,
                        openItem: response.data.openItem,
                        incTax: response.data.incTax,
                        status: response.data.status,
                        counterId: response.data.counterId,
                        GenerateChildId: "No",
                        discountable: response.data.discountable,
                        saleable: 1,
                        cgstid: response.data.cgstid,
                        sgstid: response.data.sgstid,
                        igstid: response.data.igstid,
                        cessId: response.data.cessId,
                        unitId: 1,
                        taxibility: "Taxable",

                    }

                    );
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });



            // if (props.editItem.redTag === 1) {

            // }
            // setAddNewItemDetail(props.editItem);
        }
    }


    const updateItem = async () => {
        if (addNewItemDetail.itemCode === '') {
            NotificationManager.error("Item Code Required !");
            return;
        }
        if (addNewItemDetail.itemName === '') {
            NotificationManager.error("Item Name Required !");
            return;
        }
        console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api.put('api/master/product?id=' + addNewItemDetail.id, JSON.stringify(addNewItemDetail))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Item Updated Successfully.");

                    props.setAddNewItemModal(false);
                    props.setEditItem(null);


                    props.loadItems();
                }
                else {
                    NotificationManager.error(JSON.stringify(response));
                    console.log(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
                console.log(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });


        // const { editItem } = this.state;

        // let self = this;
        // setTimeout(() => {
        //     self.setState({ users, loading: false });
        //     NotificationManager.success('User Updated!');
        // }, 2000);
    }

    return (
        <>
            <Modal className='modal-lg' isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>
                    Add New Item
                </ModalHeader>
                <ModalBody>
                    {loading &&
                        <RctSectionLoader />
                    }
                    <Form className='row'>
                        <FormGroup className='col-lg-6'>
                            <FormControl sx={{ m: 1, width: 300 }} size="small">
                                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChanges}
                                >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                        <FormGroup className='col-lg-6'>
                            <TextField fullWidth label="fullWidth" disableUnderline={true} defaultValue="Small" size="small" id="fullWidth" />
                        </FormGroup>
                        <FormGroup className='col-lg-6'>
                            <FormControl sx={{ m: 1, width: 300 }} size="small">
                                <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                                <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={personName}
                                onChange={handleChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                                >
                                {names.map((name) => (
                                    <MenuItem key={name} value={name}>
                                    <Checkbox checked={personName.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </FormGroup>
                        <FormGroup className='col-lg-6'>
                            <Label for="openItem">Open Item</Label>
                            <Switch checked={addNewItemDetail.openItem === 1} aria-label="checkedB" size="small" onChange={(e) => onChangeAddNewItemDetails('openItem', addNewItemDetail.openItem === 0 ? 1 : 0)}
                            />
                        </FormGroup>                    
                        <FormGroup className='col-lg-6'>
                            <Label for="openItem">Check Box</Label>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                                <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
                            </FormGroup>
                        </FormGroup>                  
                        <FormGroup className='col-lg-6'>
                            <Label id="demo-row-radio-buttons-group-label">Gender</Label>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                                <FormControlLabel
                                value="disabled"
                                disabled
                                control={<Radio />}
                                label="other"
                                />
                            </RadioGroup>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    {/* {editItem === null ?
                    <Button variant="contained" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                    : <Button variant="contained" color="primary" className="text-white" onClick={() => updateItem()}>Update</Button>
                }
                {' '}
                <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>Cancel</Button> */}
                    {props.editItem === null ?
                        <Button variant="contained" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                        :
                        <Button variant="contained" color="primary" className="text-white" onClick={() => updateItem()}>Update</Button>}

                    {'  '}
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>Cancel</Button>
                </ModalFooter>
            </Modal >
        </>
    )
}
export default AddNewItemForms;