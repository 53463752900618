import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTheme, TextField, Box, Button, MenuItem, Stack } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';

export default function AddNewItemForm() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();
 
    const [groups, setGroups] = useState(null);
    const [fys, setFys] = useState(null);
    const [branches, setBranches] = useState(null);

    const id = location.state.id;
    
    const [addNewItemDetail, setAddNewItemDetail] = useState({
        id: 0,
        userId: '',
        password: '',
        permissionGroupId: 0,
        fyId: 0,
        branchId: 0,
        branchesInIds: '',
        name: '',
        address: '',
        mobile: '',
        email: '',
        status: 'Active'
    });

    const [loading, setLoading] = useState(false);

    const addNewItem = async () => {
        if (addNewItemDetail.userId === '') {
            NotificationManager.error('`userId` Required !');
            return;
        }
        if (addNewItemDetail.password === 0) {
            NotificationManager.error('`password` Required !');
            return;
        }
        if (addNewItemDetail.permissionGroupId === 0) {
            NotificationManager.error('`permissionGroup` Required !');
            return;
        }
        if (addNewItemDetail.fyId === 0) {
            NotificationManager.error('`fyId` Required !');
            return;
        }
        if (addNewItemDetail.branchId === 0) {
            NotificationManager.error('`branchId` Required !');
            return;
        }
        if (addNewItemDetail.mobile === '') {
            NotificationManager.error('`mobile` Required !');
            return;
        }
        if (addNewItemDetail.email === '') {
            NotificationManager.error('`email` Required !');
            return;
        }
        setLoading(true);
        await api
            .post('api/master/login', JSON.stringify(addNewItemDetail))
            .then((response) => {
                console.log(response.data);
                NotificationManager.success('Added Successfully.');
                navigate('/app/user-master-list');
            })
            .catch((error) => { 
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (addNewItemDetail.id == 0 && id > 0) {
            setLoading(true);
            (async () => {
                await api
                    .get('api/master/login/' + id)
                    .then((response) => {
                        console.log(response.data);

                        setAddNewItemDetail({ 
                            ...addNewItemDetail,
                            id: response.data.uno,
                            userId: response.data.userId,
                            // password: response.data.password,
                            permissionGroupId: response.data.prmgrp,
                            fyId: response.data.fyId,
                            branchId: response.data.branchId,
                             branchesInIds: response.data.branchesIn,
                            name: response.data.userName,
                            address: response.data.address, 
                            mobile: response.data.mobile,
                            email: response.data.email,
                            status: response.data.status
                        }); 
                    })
                    .catch((error) => {
                    }).finally(()=>{
                        setLoading(false);
                    });
            })();
        }
    }, [id > 0]);

    const updateItem = async () => {
        if (addNewItemDetail.userId === '') {
            NotificationManager.error('`userId` Required !');
            return;
        }
        if (addNewItemDetail.password === 0) {
            NotificationManager.error('`password` Required !');
            return;
        }
        if (addNewItemDetail.permissionGroupId === 0) {
            NotificationManager.error('`permissionGroup` Required !');
            return;
        }
        if (addNewItemDetail.fyId === 0) {
            NotificationManager.error('`fyId` Required !');
            return;
        }
        if (addNewItemDetail.branchId === 0) {
            NotificationManager.error('`branchId` Required !');
            return;
        }
        if (addNewItemDetail.mobile === '') {
            NotificationManager.error('`mobile` Required !');
            return;
        }
        if (addNewItemDetail.email === '') {
            NotificationManager.error('`email` Required !');
            return;
        }

        setLoading(true);
        await api
            .put('api/master/login?id=' + id, JSON.stringify(addNewItemDetail))
            .then((response) => {
                NotificationManager.success('Updated Successfully.');
                navigate('/app/user-master-list');
            })
            .catch((error) => { 
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (groups === null) {
            (async () => {
                await api
                    .get('api/master/permission-group')
                    .then((response) => {
                        setGroups(response.data.data);
                    })
                    .catch((error) => { 
                    });
            })();
        } 
        if (fys === null) {
            (async () => {
                await api
                    .get('api/master/login/get-fy-year-list')
                    .then((response) => {
                        setFys(response.data);
                    })
                    .catch((error) => { 
                    });
            })();
        } 
        if (branches === null) {
            (async () => {
                await api
                    .get('api/master/branch')
                    .then((response) => {
                        setBranches(response.data);
                    })
                    .catch((error) => { 
                    });
            })();
        } 
    },[]);

    return (
        <Box m="20px">
            <Header title="USER MASTER" subtitle="login user" />
            {loading && <RctSectionLoader />}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                    <TextField
                        type="text"
                        label="UserId"
                        value={addNewItemDetail.userId}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, userId: e.target.value })}
                        variant="standard"
                        autoFocus={true}
                    />
                    <TextField
                        label="Password"
                        value={addNewItemDetail.password}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, password: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Permission Group"
                        value={addNewItemDetail.permissionGroupId}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, permissionGroupId: e.target.value })}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select Permission Group
                        </MenuItem>
                        {groups &&
                            groups.map((group) => (
                                <MenuItem key={group.id} value={group.id}>
                                    {group.groupName}
                                </MenuItem>
                            ))}
                    </TextField>
                    <Box textAlign="right" gridColumn="span 12">
                        <Link to={{ pathname: '/app/user-permission-group-master-list' }}>Add New Group</Link>
                    </Box>
                    <TextField
                        label="Fy-Year"
                        value={addNewItemDetail.fyId}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, fyId: e.target.value })}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select Fy-Year
                        </MenuItem>
                        {fys &&
                            fys.map((fy) => (
                                <MenuItem key={fy.no} value={fy.no}>
                                    {fy.companyName }
                                </MenuItem>
                            ))}
                    </TextField>
                    <TextField
                        label="Branch"
                        value={addNewItemDetail.branchId}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, branchId: e.target.value,branchesInIds:e.target.value.toString() })}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select Branch
                        </MenuItem>
                        {branches &&
                            branches.map((branch) => (
                                <MenuItem key={branch.id} value={branch.id}>
                                    {branch.branchName }
                                </MenuItem>
                            ))}
                    </TextField>  
                    <TextField
                        label="Name"
                        value={addNewItemDetail.name}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, name: e.target.value })}
                        variant="standard"
                    /> 
                    <TextField
                        label="Address"
                        value={addNewItemDetail.address}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, address: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        type={'number'}
                        label="Mobile"
                        value={addNewItemDetail.mobile}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, mobile: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Email"
                        type={'email'}
                        value={addNewItemDetail.email}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, email: e.target.value })}
                        variant="standard"
                    />
                    <TextField
                        label="Select Status"
                        value={addNewItemDetail.status}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, status: e.target.value })}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem selected key={0} value="Active">
                            Active
                        </MenuItem>
                        <MenuItem key={1} value="InActive">
                            InActive
                        </MenuItem>
                    </TextField>
                    <Stack direction="row" spacing={2}>
                        {id === 0 ? (
                            <Button variant="contained" color="secondary" className="text-white btn-success" onClick={() => addNewItem()}>
                                Add
                            </Button>
                        ) : (
                            <Button variant="contained" color="secondary" onClick={() => updateItem()}>
                                Update
                            </Button>
                        )}

                        {'  '}
                        <Button variant="contained" color="error" onClick={() => navigate('/app/user-master-list')}>
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}
