import React, { useState } from 'react';
import { TextField } from '@mui/material';

export default function ApplyDiscount(props) {
    return (
        <>
            <TextField
                type='number'
                label="Discount (%)"
                placeholder="%" value={props.billDetails.discPer}
                onChange={(e) => props.setBillDetails({ ...props.billDetails, discPer: e.target.value })}>
            </TextField>
        </>
    )
}