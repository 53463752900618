import React, { useEffect, useState } from 'react';
import { Stack, Button, useTheme, Box, TableContainer, Paper, IconButton, TextField } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';
var moment = require('moment');
const StockReport = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [asOnDate, setAsOnDate] = useState(new Date().toLocaleString());
    const [searchText, setSearchText] = useState('');

    const [orders, setOrders] = useState();
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            loadData();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            loadData(page);
        })();
    }, [page, rowsPerPage]);

    const loadData = async () => {
        setLoading(true);
        await api
            .get('api/rest/report/get-stock-report?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: {
                    asOnDate: asOnDate,
                    searchText: searchText
                }
            })
            .then((response) => {
                setOrders(response.data.data);
                setCount(response.data.totalRecords);
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const exportReport = async () => {
        setLoading(true);
        await api
            .get('api/rest/report/get-stock-report?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: {
                    asOnDate: asOnDate,
                    searchText: searchText,
                    export: true
                }
            })
            .then((msg) => {
                var a = document.createElement('a');
                a.href = msg.data;
                a.download = 'StockReport.xlsx';
                document.body.appendChild(a);
                a.click();
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChangePage = async (newPage) => {
        //setPage(page + 1);
        console.log('page........');
        console.log('New Page : ' + newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        console.log('Rows per page changed !');
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions() {
        const handleFirstPageButtonClick = () => {
            setPage(0);
            // loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            //loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            //loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            //loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <Box m="20px">
            <Header title="STOCK REPORT" subtitle="See stock here" />

            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                {/* searchText */}
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    p={2}
                >
                    <TextField
                        type="date"
                        label="Start Date"
                        value={moment(asOnDate).format('YYYY-MM-DD')}
                        onChange={(e) => setAsOnDate(e.target.value)}
                    />
                    <TextField
                        type="text"
                        label="Search Text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Button color="primary" variant="contained" onClick={loadData}>
                        Search
                    </Button>
                    <Button color="success" variant="contained" onClick={exportReport}>
                        Export
                    </Button>
                    {loading && <RctSectionLoader />}
                </Stack>

                <Box
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    sx={{
                        '& .MuiTableHead-root': {
                            backgroundColor: colors.greenAccent[700]
                        },
                        '& .MuiTableRow-footer': {
                            backgroundColor: colors.redAccent[700]
                        }
                    }}
                >
                    <TableContainer component={Paper}>
                        {orders && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Item Name</TableCell>
                                        <TableCell align="right">Item Category</TableCell>
                                        <TableCell align="right">Qty</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders &&
                                        orders.map((order, key) =>
                                            order.itemCategory != null ? (
                                                <TableRow key={key}>
                                                    <TableCell>
                                                        <div className="media">
                                                            <div className="media-left mr-15">{order.itemName}</div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="right">{order.itemCategory}</TableCell>
                                                    <TableCell align="right">{order.qty}</TableCell>
                                                </TableRow>
                                            ) : null
                                        )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="right">Total</TableCell>
                                        <TableCell align="right" style={{ font: 10, fontWeight: 'bold' }}>
                                            {orders[orders.length - 1].qty}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TablePagination
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                            labelRowsPerPage={<span>Rows:</span>}
                                            labelDisplayedRows={({ page }) => {
                                                return `Page: ${page + 1}`;
                                            }}
                                            backIconButtonProps={{
                                                color: 'secondary'
                                            }}
                                            nextIconButtonProps={{ color: 'secondary' }}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'page number'
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                            ActionsComponent={TablePaginationActions}
                                            //component={Box}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    );
};

export default StockReport;
