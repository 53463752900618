import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { Helmet } from "react-helmet";
import api from 'api';
// page title bar
import Header from 'components/Header';

import { Button } from 'reactstrap';

import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import ItemSelection from '../../component/CustomComponentRestaurant/ItemSelection';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';


import {
    Card,
    CardBody,
    CardHeader,
    CardFooter
} from 'reactstrap';

export default function KOT(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const billDetailsInitialValue = {
        menuItems: [],
        startSubmit: false,
        discPer: 0,
        netAmt: 0,
        mopDatas: [],
        paidAmount: 0,
        customer: null,
    };
    const [billDetails, setBillDetails] = useState(billDetailsInitialValue);

    const TableName = location.state.tableName;//props.location.state.tableName;

    const savedBillDetailsInatialValue = {
        kotNo: 0,
        printKOTTag: false,
        printVoidKOTTag: false
    };
    const [savedBillDetails, setSavedBillDetails] = useState(savedBillDetailsInatialValue);
    const itemInputRef = useRef();

    useEffect(() => {
        (async () => {
            if (savedBillDetails.printKOTTag == true) {
                await api.get('api/rest/kot/print-kot?kotNo=' + savedBillDetails.kotNo)
                    .then((response) => {
                        if (response.status === 200) {
                            window.open('' + response.data + '', 'sharer', 'toolbar=0,status=0,width=548,height=525');
                        }
                        else {
                            NotificationManager.success(JSON.stringify(response));
                        }
                    })
                    .catch(error => {
                        NotificationManager.error(error.message);
                    }).finally(() => {
                        setSavedBillDetails(savedBillDetailsInatialValue)
                        navigate('/app/new-order');
                    });
            }
        })();

    }, [savedBillDetails.printKOTTag]);

    useEffect(() => {
        (async () => {
            if (savedBillDetails.printVoidKOTTag == true) {
                await api.get('api/rest/kot/print-void-kot?kotNo=' + savedBillDetails.kotNo)
                    .then((response) => {
                        if (response.status === 200) {
                            window.open('' + response.data + '', 'sharer', 'toolbar=0,status=0,width=548,height=525');
                        }
                        else {
                            NotificationManager.success(JSON.stringify(response));
                        }
                    })
                    .catch(error => {
                        NotificationManager.error(error.message);
                    }).finally(() => {
                        setSavedBillDetails(savedBillDetailsInatialValue);
                        navigate('/app/new-order');
                    });
            }
        })();

    }, [savedBillDetails.printVoidKOTTag]);


    const handleSaveBill = async () => {
        //console.log(menuItems);
        if (billDetails.menuItems === undefined || billDetails.menuItems === null || billDetails.menuItems.length == 0) {
            NotificationManager.error("Select item to continue !");
            return;
        };
        const billData = {
            tableName: TableName,
            itemList: billDetails.menuItems,
        };
        setBillDetails({ ...billDetails, startSubmit: true });
        await api.post('api/rest/kot/save-kot', JSON.stringify(billData))
            .then((response) => {
                if (response.status === 200) {
                    setBillDetails(billDetailsInitialValue);
                    setSavedBillDetails({ ...savedBillDetails, kotNo: response.data, printKOTTag: true });
                    NotificationManager.success("KOT Saved Successfully.");
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
            }).finally(() => {

            });
    };

    const handleSaveVoidKOT = async () => {
        //console.log(menuItems);
        if (billDetails.menuItems === undefined || billDetails.menuItems === null || billDetails.menuItems.length === 0) {
            NotificationManager.error("Select item to continue !");
            return;
        };
        const billData = {
            tableName: TableName,
            itemList: billDetails.menuItems,
        };
        setBillDetails({ ...billDetails, startSubmit: true });
        await api.post('api/rest/kot/void-kot', JSON.stringify(billData))
            .then((response) => {
                if (response.status === 200) {
                    setBillDetails(billDetailsInitialValue);
                    setSavedBillDetails({ ...savedBillDetails, kotNo: response.data, printVoidKOTTag: true });
                    NotificationManager.success("KOT Saved Successfully.");
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.message);
            }).finally(() => {
            });
    };

    return (
        <div className="card-wrapper">
            {/* HEADER */}
            <Header title="KOT" subtitle="manage item category" />
            <Card>
                {billDetails.startSubmit &&
                    <RctSectionLoader />
                }
                <CardHeader color="warning">
                    <div className="row">
                        <div className="col-md-5">
                            <b>KOT (Table No. : {TableName})</b>
                        </div>
                        <div className="col-md-4">
                            <Button color="default" disabled={billDetails.startSubmit} onClick={handleSaveVoidKOT}>{billDetails.startSubmit ? 'Please wait...' : 'Save & Print (Void KOT)'}<small>{'\u00A0'}{'\u00A0'}({'\u20B9'}{billDetails.netAmt})</small></Button>
                        </div>
                        <div className="col-md-3">
                            <Button color="danger" disabled={billDetails.startSubmit} onClick={handleSaveBill}>{billDetails.startSubmit ? 'Please wait...' : 'Save & Print (KOT)'}<small>{'\u00A0'}{'\u00A0'}({'\u20B9'}{billDetails.netAmt})</small></Button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <RctCollapsibleCard>
                        <div className="row">
                            <ItemSelection itemInputRef={itemInputRef} billDetails={billDetails} setBillDetails={setBillDetails} />
                        </div>
                    </RctCollapsibleCard>
                </CardBody>
                <CardFooter></CardFooter>
            </Card>
        </div>

    )
}
