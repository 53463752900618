import React, { useState, useEffect } from 'react';
import {
    Card,
    CardImg,
    CardText,
    CardBody,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Button,
    CardLink,
    CardGroup,
    CardImgOverlay
} from 'reactstrap';
import Header from 'components/Header';
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';

import api from 'api';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MakePayment from '../../component/CustomComponentRestaurant/MakePayment';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import RctSectionLoader from 'component/RctSectionLoader/RctSectionLoader';

const getCardColor = (status) => {
    if (status === "OrderReceived") {
        return "primary";
    }
    if (status === "OrderConfirmed") {
        return "secondary";
    }
    if (status === "FoodPrepared") {
        return "warning";
    }
    if (status === "Dispatched") {
        return "danger";
    }
    if (status === "Delivered") {
        return "success";
    }
    return "info";
};

export default function PendingOrder(props) {
    const [pendingOrders, setPendingOrders] = useState([]);
    const [riderLists, setRiderLists] = useState([]);
    const [open, setOpen] = useState(false);
    const [riderName, setRiderName] = useState('');
    const [currentBillID, setCurrentBillID] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    const [openMOP, setOpenMOP] = React.useState(false);


    const [mopMaster, setMopMaster] = useState([]);
    const billDetailsInitialValue = {
        menuItems: [],
        startSubmit: false,
        discPer: 0,
        netAmt: 0,
        mopDatas: mopMaster,
        paidAmount: 0,
        customer: null,
        billID: 0
    };
    const [billDetails, setBillDetails] = useState(billDetailsInitialValue);

    const [startSubmit, setStartSubmit] = useState(false);

    const openCMenu = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;

    console.log(pendingOrders.length);

    //loading mop master
    useEffect(() => {
        if (mopMaster.length == 0) {
            (async () => {
                await api.get(`api/rest/kot/get-mop-master`)
                    .then((response) => {
                        setMopMaster(response.data);
                        setBillDetails({ ...billDetails, mopDatas: response.data });
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error));
                    }).finally(() => {
                    });
            })();
        }
    }, []);

    useEffect(() => {
        (async () => {
            LoadPendingOrders();
            await api.get('/api/rest/kot/get-waiter')
                .then((response) => {
                    setRiderLists(response.data);
                })
                .catch(error => {
                    NotificationManager.error(JSON.stringify(error));
                });

        })();
    }, []);

    const LoadPendingOrders = async () => {
        setStartSubmit(true);
        await api.get('api/rest/kot-bill/get-pending-bill-details')
            .then((response) => {
                setPendingOrders(response.data);
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            }).finally(() => {
                setStartSubmit(false);
            });
    };

    const handleRiderOpen = () => {
        setOpen(true);
        setAnchorEl(null);
    };

    const handleRiderClose = () => {
        setOpen(false);
        saveRider(currentBillID);
    };

    const saveRider = async () => {
        await api.post(`/api/rest/kot-bill/assign-rider?&riderName=${riderName}&billID=${currentBillID}`)
            .then((response) => {
                LoadPendingOrders();
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleCMenuClick = (e, billID, rider) => {
        setAnchorEl(e.currentTarget);
        setCurrentBillID(billID);
        setRiderName(rider);
    };

    const handleCMenuClose = () => {
        setAnchorEl(null);
    };

    const saveStatus = async (currentStatus, billID, billValue) => {
        setStartSubmit(true);
        let nextStatus = currentStatus;
        switch (currentStatus) {
            case 'OrderReceived':
                nextStatus = "OrderConfirmed";
                break;
            case 'OrderConfirmed':
                nextStatus = "FoodPrepared";
                break;
            case 'FoodPrepared':
                nextStatus = "Dispatched";
                break;
            case 'Dispatched':
                nextStatus = "Delivered";
                break;
            case 'Delivered':

                if (billDetails.mopDatas.length > 0) {
                    billDetails.mopDatas[0].amount = billValue;
                    billDetails.paidAmount = billValue;
                }

                setBillDetails({ ...billDetails, netAmt: billValue, billID: billID })

                setOpenMOP(true);
                setStartSubmit(false);
                return;
            case 'PaymentReceived':
                return;
            default:
                nextStatus = 'PaymentReceived';
        }


        await api.post(`/api/rest/kot-bill/change-bill-status?billID=${billID}`)
            .then((response) => {
                LoadPendingOrders();
            })
            .catch(error => {
                console.log(error);
            });

        setStartSubmit(false);

    }

    const handleMOPClose = async (mode) => {
        if (mode === "close") {
            setOpenMOP(false);
            return;
        }
        if (billDetails.paidAmount != billDetails.netAmt) {
            NotificationManager.error(`Payable amount must be same to paid amount !`);
            return;
        }

        const billData = {
            bIllID: billDetails.billID,
            mopList: billDetails.mopDatas,
        };
        //console.log(JSON.stringify(billData));
        setStartSubmit(true);
        await api.post('api/rest/kot-bill/settle-pending-bill', JSON.stringify(billData))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Payment Received Successfully.");
                    LoadPendingOrders();
                    setOpenMOP(false);
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.message);
            }).finally(() => {
                setStartSubmit(false);
            });

    }

    return (
        <div className="card-wracardpper">
             {/* HEADER */}
             <Header title="PENDING ORDER" subtitle="manage item category" />
            <RctCollapsibleCard>
                {startSubmit && <RctSectionLoader></RctSectionLoader>}
                <div className='row'>
                    <div className='col-md-12 text-right'>
                        <CachedRoundedIcon color='default' onClick={() => LoadPendingOrders()}></CachedRoundedIcon>
                    </div>
                </div>
                <div className="row">
                    {pendingOrders.length > 0 ?
                        pendingOrders.map((obj) => (
                            <div className="col-sm-12 col-md-4 col-lg-3 mb-30" key={obj.id}>
                                <Card body inverse color={getCardColor(obj.status)}>
                                    <CardHeader>
                                        <div className="row">
                                            <div className="col-md-9 col-9">
                                                <small><b>Order No : {obj.invoiceNo}</b></small>
                                            </div>
                                            <div className="col-md-3 col-3">
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleCMenuClick(e, obj.id, obj.rider)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id={obj.id}
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={openCMenu}
                                                    onClose={handleCMenuClose}
                                                    value={obj.invoiceNo}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5,
                                                            width: '20ch',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem onClick={(e) => handleRiderOpen()}>
                                                        Rider
                                                    </MenuItem>
                                                </Menu>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardText>
                                        <small><span color="black">
                                            <br />
                                            Order Time : {moment(obj.creationTime).format("hh:mm:ss a")}
                                            <br />
                                            Order Value : {obj.billValue}
                                            <br />
                                            Rider : {obj.rider}
                                            <br />
                                            Customer : {obj.contactPerson}
                                            <br />
                                            Mobile : {obj.mobile}

                                        </span></small>

                                        <Dialog
                                            disableEscapeKeyDown
                                            open={open}
                                        >
                                            <DialogContent>
                                                <form>
                                                    <FormControl className="col-lg-12">
                                                        <Select
                                                            native
                                                            value={riderName}
                                                            id="waiter-sample"
                                                            onChange={(e) => setRiderName(e.target.value)}
                                                        >
                                                            <option value="0">---Select Rider---</option>
                                                            {riderLists !== undefined
                                                                ?
                                                                riderLists.map((obj) => (
                                                                    <option value={obj.waiterName} key={obj.id}>{obj.waiterName}</option>
                                                                ))
                                                                : null}
                                                        </Select>
                                                    </FormControl>
                                                </form>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={(e) => handleRiderClose()} color="primary">
                                                    Set
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </CardText>
                                    <Button color="info" onClick={() => saveStatus(obj.status, obj.id, obj.billValue)} disabled={startSubmit}>
                                        {startSubmit ? 'Please wait...' : obj.status}<small>{'\u00A0'}{'\u00A0'}({'\u20B9'}{obj.billValue})</small>
                                    </Button>
                                </Card>
                            </div>
                        ))
                        :
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h2><span style={{ color: "black" }}>Oops....no pending order(s) found</span></h2>
                        </div>
                    }
                </div>
            </RctCollapsibleCard>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={openMOP}
                onClose={handleMOPClose}
            >
                <DialogContent>
                    <MakePayment billDetails={billDetails} setBillDetails={setBillDetails} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleMOPClose("save")} color="danger" disabled={startSubmit}>
                        {startSubmit ? 'Please wait...' : 'Save'}<small>{'\u00A0'}{'\u00A0'}({'\u20B9'}{billDetails.netAmt})</small>
                    </Button>
                    <Button onClick={(e) => handleMOPClose("close")} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div >
    );
} 
