import React, { useState, useEffect } from 'react';
import {
    Card,
    CardText,
    CardHeader,
    Button,
} from 'reactstrap';
import Header from 'components/Header';
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import { Link } from 'react-router-dom';
import api from 'api';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { NotificationManager } from 'react-notifications';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import { Tooltip } from '@mui/material';

const getCardColor = (docType) => {
    if (docType === "ConfirmKOT") {
        return "primary";
    }
    if (docType === "KOT") {
        return "warning";
    }
    return "info";
};

export default function NewOrder(props) {
    const [tableLists, setTableLists] = useState([]);
    const [waiterLists, setWaiterLists] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentTableName, setCurrentTableName] = useState('');
    const [loading, setLoading] = useState(false);

    const [customerInfo, setCustomerInfo] = useState({ "name": '', "mobile": '', "email": '', "address": '', "waiterName": '', "person": 1 });


    useEffect(() => {
        (async () => {
            await loadTable();
        })();
        (async () => {
            await loadWaiter();
        })();
    }, []);

    const loadWaiter = async () => {
        if (waiterLists.length == 0) {
            setLoading(true);
            await api.get('/api/rest/kot/get-waiter')
                .then((response) => {
                    setWaiterLists(response.data);
                })
                .catch(error => {
                    NotificationManager.error(JSON.stringify(error));
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    const loadTable = async () => {
        if (tableLists.length == 0) {
            setLoading(true);
            await api.get('api/rest/kot/get-table-list')
                .then((response) => {
                    setTableLists(response.data);
                })
                .catch(error => {
                    NotificationManager.error(JSON.stringify(error));
                }).finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleWaiterOpen = (tableName) => {
        setOpen(true);
        setCurrentTableName(tableName);
        var res = tableLists.filter(e => e.tableName == tableName);
        console.log(res[0].additionalInfo);
        if (res[0].additionalInfo != null && res[0].additionalInfo != "") {
            setCustomerInfo(JSON.parse(res[0].additionalInfo));
        } else {
            setCustomerInfo({ "name": '', "mobile": '', "email": '', "address": '', "person": 1, "waiterName": '' });
        }
    };

    const handleWaiterClose = async () => {
        //console.log("TableName" + currentTableName);
        console.log(JSON.stringify(customerInfo));
        await api.post(`api/rest/kot/assign-additional-info?tableName=${currentTableName}&additionalInfo=${JSON.stringify(customerInfo)}`)
            .then(async () => {
                await loadTable();
            })
            .catch(error => {
                console.log(error.message);
            }).finally(() => {
                setOpen(false);
            });
    };

    const updateAdditionalInfo = (e, key) => {
        var arr = { ...customerInfo };
        if (key == "person") {
            arr[key] = parseInt(e.target.value)
        }
        else {
            arr[key] = e.target.value;
        }
        setCustomerInfo(prevState => arr);
    }

    return (
        <div className="card-wracardpper">
            {/* HEADER */}
            <Header title="NEW ORDER" subtitle="manage item category" />
            <RctCollapsibleCard>
                <Grid container justify="flex-end">
                    <>
                        <Dialog
                            disableEscapeKeyDown
                            open={open}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">Set Additional Info</DialogTitle>
                            <DialogContent>
                                <form>
                                    <FormControl className="col-lg-12">
                                        <Select
                                            native
                                            value={customerInfo.waiterName}
                                            onChange={(e) => updateAdditionalInfo(e, "waiterName")}                                        >
                                            <option value="0">---Select Waiter---</option>
                                            {waiterLists !== undefined
                                                ?
                                                waiterLists.map((obj) => (
                                                    <option value={obj.waiterName} key={obj.id}>{obj.waiterName}</option>
                                                ))
                                                : null}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="person-simple">Person</InputLabel>
                                        <Input onChange={(e) => updateAdditionalInfo(e, "person")} type="number" value={customerInfo.person} />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="person-simple">Name</InputLabel>
                                        <Input value={customerInfo.name} onChange={(e) => updateAdditionalInfo(e, "name")} inputProps={{ maxLength: 50 }} />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="person-simple">Address</InputLabel>
                                        <Input value={customerInfo.address} onChange={(e) => updateAdditionalInfo(e, "address")} inputProps={{ maxLength: 100 }} />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="person-simple">Mobile</InputLabel>
                                        <Input value={customerInfo.mobile} onChange={(e) => updateAdditionalInfo(e, "mobile")} inputProps={{ maxLength: 10 }} />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="person-simple">Email</InputLabel>
                                        <Input value={customerInfo.email} onChange={(e) => updateAdditionalInfo(e, "email")} inputProps={{ maxLength: 50 }} />
                                    </FormControl>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={(e) => setOpen(!open)} color="danger">
                                    Cancel
                                </Button>
                                <Button onClick={(e) => handleWaiterClose()} color="primary">
                                    Set
                                </Button>
                            </DialogActions>
                        </Dialog>


                        <Link to={{ pathname: "/app/home-delivery", state: { tableName: "HomeDelivery", }, }}>
                            <Button color="danger" style={{ minWidth: 120, fontSize: 11 }}>
                                Home Delivery
                            </Button>
                        </Link>
                        {'\u00A0'}{'\u00A0'}
                        <Link to={{ pathname: "/app/take-away", state: { tableName: "TakeAway", }, }}>
                            <Button color="danger" style={{ minWidth: 120, fontSize: 11 }}>
                                Take Away
                            </Button>
                        </Link>
                    </>
                </Grid>
            </RctCollapsibleCard>
            <RctCollapsibleCard heading="Table List">
                <div className="row">
                    {loading &&
                        <RctSectionLoader />
                    }
                    {tableLists !== null && tableLists !== undefined ?
                        tableLists.map((obj) => (
                            <div className="col-sm-6 col-md-4 col-lg-2 col-6" key={obj.tableName} style={{ padding: 5 }}>
                                <Card body inverse color={getCardColor(obj.docType)}>
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <b>{obj.tableName}</b>
                                        </div>
                                    </div>
                                    <CardText>
                                        <Tooltip title={obj.additionalInfo} arrow>
                                            <a href="#" onClick={() => handleWaiterOpen(obj.tableName)} style={{ color: '#fff', fontSize: 10 }}>
                                                Additional.Info
                                            </a>
                                        </Tooltip>
                                    </CardText>
                                    {obj.docType === null || obj.docType === "" || obj.docType === "KOT" ?
                                        <Button color="danger" style={{ margin: 4 }}>
                                            <Link to='/app/kot' state={{ tableName: obj.tableName }} style={{ color: ' #fff', fontSize: 11 }}>Add Items</Link>
                                        </Button>
                                        :
                                        null}
                                    {obj.docType === "KOT" ?
                                        <Button color="danger" style={{ margin: 4 }}>
                                            <Link to='/app/make-bill' state={{ tableName: obj.tableName }} style={{ color: '#fff', fontSize: 11 }}>Make Bill</Link>
                                        </Button>
                                        :
                                        null}
                                    {obj.docType === "ConfirmKOT" ?
                                        <Button color="danger" style={{ margin: 4 }}>
                                            <Link to='/app/make-payment' state={{ tableName: obj.tableName }} style={{ color: '#fff', fontSize: 11 }} >Make Payment</Link>
                                        </Button>
                                        :
                                        null}
                                </Card>

                            </div>
                        ))
                        :
                        null
                    }
                </div>
            </RctCollapsibleCard >
        </div >
    );
}
