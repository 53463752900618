import React, { useState, useEffect, useRef } from 'react';
import { useFetchMopMaster} from '../../hooks/useFetchData';
import { NotificationManager } from 'react-notifications';
import { Helmet } from "react-helmet";
import api from 'api';
// page title bar
import Header from 'components/Header';

import { Button } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import ItemSelection from '../../component/CustomComponentRestaurant/ItemSelection';
import ApplyDiscount from '../../component/CustomComponentRestaurant/ApplyDiscount';
import SearchCustomer from '../../component/CustomComponentRestaurant/SearchCustomer';
import MakePayment from '../../component/CustomComponentRestaurant/MakePayment';

import {
    Card,
    CardBody,
    CardHeader,
    CardFooter
} from 'reactstrap';

export default function HomeDelivery(props) {
    const onMopMasterFetched = (data) =>{
        setBillDetails({...billDetails,mopDatas:data.data})
    }

    const {data : mopMaster} = useFetchMopMaster(onMopMasterFetched);

    const billDetailsInitialValue = {
        menuItems: [],
        startSubmit: false,
        discPer: 0,
        netAmt: 0,
        mopDatas:  mopMaster == undefined ? [] : mopMaster.data,
        paidAmount: 0,
        customer: null
    };
    const [billDetails, setBillDetails] = useState(billDetailsInitialValue);

    const savedBillDetailsInatialValue = {
        billID: 0,
        printBillTag: false,
        sendSMSTag: false
    };
    const [savedBillDetails, setSavedBillDetails] = useState(savedBillDetailsInatialValue);

    const [openMOP, setOpenMOP] = React.useState(false);

    const refsItemSelection = useRef(null)
    const customerInputRef = useRef(null);
    const itemInputRef = useRef(null);

    const clearAll = async () => {
        setBillDetails(billDetailsInitialValue);

        setOpenMOP(false);
    } 

    useEffect(() => {
        if (refsItemSelection.current !== null) {
            refsItemSelection.current.applyDiscount(billDetails.menuItems, billDetails.discPer);
        }
    }, [billDetails.discPer, billDetails.mopDatas]);


    const handleSaveBill = () => {

        if (billDetails.menuItems === undefined || billDetails.menuItems === null || billDetails.menuItems.length === 0) {
            NotificationManager.error("Select item to continue !");
            itemInputRef.current.focus();
            return;
        };
        if (billDetails.mopDatas.length > 0) {
            billDetails.mopDatas[0].amount = 0;
            setBillDetails({ ...billDetails, paidAmount: 0 });
        }
        if (billDetails.customer == null) {
            NotificationManager.error("Select customer !");
            customerInputRef.current.focus();
            return;
        }

        setOpenMOP(true);
    };

    const handleMOPClose = async (mode) => {
        if (mode === "close") {
            setOpenMOP(false);
        } else {
            // if (billDetails.paidAmount != billDetails.netAmt) {
            //     NotificationManager.error(`Payable amount must be same to paid amount !`);
            //     return;
            // }
            setOpenMOP(false);
            const billData = {
                docHeader: "HomeDelivery",
                itemList: billDetails.menuItems,
                discPer: billDetails.discPer,
                customerID: billDetails.customer == null ? 0 : billDetails.customer.id,
                mopList: billDetails.mopDatas,
            };
            setBillDetails({ ...billDetails, startSubmit: true });
            await api.post('api/rest/kot-bill/save-bill-takeaway', JSON.stringify(billData))
                .then((response) => {
                    if (response.status === 200) {
                        setSavedBillDetails({ ...savedBillDetails, billID: response.data.id, printBillTag: true });
                        NotificationManager.success("Bill Saved Successfully.");
                        clearAll();
                    }
                    else {
                        NotificationManager.success(JSON.stringify(response));
                    }
                })
                .catch(error => {
                    NotificationManager.error(error.message);
                }).finally(() => {

                });
        }

    };

    useEffect(() => {
        (async () => {
            if (savedBillDetails.printBillTag == true && savedBillDetails.billID > 0) {
                await api.get('api/rest/kot-bill/print-bill?billID=' + savedBillDetails.billID)
                    .then((response) => {
                        if (response.status === 200) {
                            window.open('' + response.data + '', 'sharer', 'toolbar=0,status=0,width=548,height=525');
                        }
                        else {
                            NotificationManager.success(JSON.stringify(response));
                        }
                    })
                    .catch(error => {
                        NotificationManager.error(error.message);
                    }).finally(() => {
                        setSavedBillDetails({ ...savedBillDetails, sendSMSTag: true, printBillTag: false });
                    });
            }
        })();
    }, [savedBillDetails.printBillTag]);

    useEffect(() => {
        (async () => {
            if (savedBillDetails.sendSMSTag == true) {
                await api.get('api/rest/kot-bill/send-sms-on-bill?billID=' + savedBillDetails.billID)
                    .then((response) => {
                    })
                    .catch(error => {
                    }).finally(() => {
                        setSavedBillDetails(savedBillDetailsInatialValue);
                    });
            }
        })();
    }, [savedBillDetails.sendSMSTag]);



    return (
        <>
            <div className="card-wrapper">
                {/* HEADER */}
            <Header title="HOME DELIVERY" subtitle="manage item category" />
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={openMOP}
                    onClose={handleMOPClose}
                >
                    <DialogContent>
                        <MakePayment billDetails={billDetails} setBillDetails={setBillDetails} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => handleMOPClose("save")} color="danger" disabled={billDetails.startSubmit}>
                            {billDetails.startSubmit ? 'Please wait...' : 'Save & Print'}<small>{'\u00A0'}{'\u00A0'}({'\u20B9'}{billDetails.netAmt})</small>
                        </Button>
                        <Button onClick={(e) => handleMOPClose("close")} color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Card>
                    <CardHeader color="warning">
                        <div className="row">
                            <div className="col-md-9">
                                <b>Home Delivery</b>
                            </div>
                            <div className="col-md-3 text-right">
                                <Button
                                    color="danger"
                                    onClick={handleSaveBill}
                                    disabled={billDetails.startSubmit} >{billDetails.startSubmit ? 'Please wait...' : 'Save'}<small>{'\u00A0'}{'\u00A0'}({'\u20B9'}{billDetails.netAmt})</small>
                                </Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <RctCollapsibleCard>
                            <div className="row">
                                <div className="col-md-2">
                                    <ApplyDiscount billDetails={billDetails} setBillDetails={setBillDetails} />
                                </div>
                                <div className="col-md-10">
                                    <SearchCustomer billDetails={billDetails} setBillDetails={setBillDetails} customerInputRef={customerInputRef} />
                                </div>
                            </div>
                        </RctCollapsibleCard>
                        <RctCollapsibleCard>
                            <div className="row">
                                <ItemSelection ref={refsItemSelection} itemInputRef={itemInputRef} billDetails={billDetails} setBillDetails={setBillDetails} />
                            </div>
                        </RctCollapsibleCard>
                    </CardBody>
                    <CardFooter></CardFooter>
                </Card>
            </div>
        </>
    )
}
