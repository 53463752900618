import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Switch, Button, MenuItem, TextField, Box, FormControlLabel, Typography, Stack, Divider, useTheme } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';

export default function AddNewItemForm(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state.id;

    const [addNewItemDetail, setAddNewItemDetail] = useState({
        roomType: '', 
        status: 'Active',
        id: 0
    });
    const [loading, setLoading] = useState(false); 

    const onChangeAddNewItemDetails = (key, value) => {
        var arr = { ...addNewItemDetail };
        arr[key] = value;
        setAddNewItemDetail((prevState) => arr);
    };

    const addNewItem = async () => {
        if (addNewItemDetail.roomType === '') {
            NotificationManager.error('Type Required !');
            return;
        } 
        setLoading(true);
        await api
            .post('api/master/hotel-room-type', JSON.stringify(addNewItemDetail))
            .then((response) => {
                NotificationManager.success('Room Category Added Successfully.');
                navigate('/app/room-type-master-list');
            })
            .catch((error) => { 
            })
            .finally(() => {
                setLoading(false);
            });
    };  

    const updateItem = async () => {
        if (addNewItemDetail.roomType === '') {
            NotificationManager.error('Room Category Required !');
            return;
        } 
        setLoading(true);
        await api
            .put('api/master/hotel-room-type', JSON.stringify(addNewItemDetail))
            .then((response) => { 
                    NotificationManager.success('Room Category Updated Successfully.');
                    navigate('/app/room-type-master-list');
            })
            .catch((error) => {             
            }).finally(()=>{
                setLoading(false);
            }); 
    };

    useEffect(() => {
        console.log()
        if (addNewItemDetail.id == 0 && id > 0) {
            setLoading(true);
            (async () => {
                await api
                    .get('api/master/hotel-room-type/' + id)
                    .then((response) => {
                        console.log(response.data);
                        setAddNewItemDetail({ 
                            roomType: response.data.roomType,
                            id: response.data.btno,  
                            status: response.data.status
                        });
                    })
                    .catch((error) => { 
                    }).finally(()=>setLoading(false));
            })();
        }
    }, [id > 0]);


    return (
        <Box m="20px">
            {/* HEADER */}
            <Header title="ROOM CATEGORY MASTER" subtitle="add/edit room category" />
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                    {loading && <RctSectionLoader />} 
                    <TextField 
                            type="text"
                            label="Enter Room Category"
                            value={addNewItemDetail.roomType}
                            onChange={(e) => onChangeAddNewItemDetails('roomType', e.target.value)}
                            variant="standard"
                        />   
                   <TextField
                        label="Select Status"
                        value={addNewItemDetail.status}
                        onChange={(e) => onChangeAddNewItemDetails('status', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem selected key={0} value="Active">
                            Active
                        </MenuItem>
                        <MenuItem key={1} value="InActive">
                            InActive
                        </MenuItem>
                    </TextField>
                    {id == 0 ? (
                        <Button variant="contained" color="secondary" onClick={() => addNewItem()}>
                            Add
                        </Button>
                    ) : (
                        <Button variant="contained" color="secondary" onClick={() => updateItem()}>
                            Update
                        </Button>
                    )}

                    {'  '}
                    <Button variant="contained" color="error" onClick={() => navigate('/app/room-type-master-list')}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}
