import React, { useEffect, useState } from 'react';
import { Button, useTheme } from '@mui/material';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import { NotificationManager } from 'react-notifications';
import { Grid, Typography, Divider, TextField, MenuItem, Box, Stack } from '@mui/material';
import Header from 'components/Header';
import { tokens } from '../../theme';
import moment from 'moment';
const ChangeBranch = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [branchID, setBranchID] = useState(0);
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        (async () => {
            await api
                .get('api/master/branch/get-branch-by-user')
                .then((response) => {
                    setBranches(response.data);
                })
                .catch((error) => {
                    NotificationManager.error(JSON.stringify(error));
                });
        })();
        setLoading(false);
        getDefaultBranch();
    }, []);

    const getDefaultBranch = async () => {
        setLoading(true);
        await api
            .get('api/master/login/get-default-branch-user')
            .then((response) => {
                console.log(response.data);
                setBranchID(response.data);
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
            });
        setLoading(false);
    };

    const saveBranch = async () => {
        setLoading(true);
        await api
            .post('api/master/login/set-default-branch-user?branchID=' + branchID)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    NotificationManager.success('SUCCESS');
                    getDefaultBranch();
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
            });
        setLoading(false);
    };

    return (
        <Box m="20px">
            <Grid item md={12} xs={12}>
                <Header title="CHANGE BRANCH" subtitle="switch to branches" />

                {loading && <RctSectionLoader />}
                <Grid md={6} xs={12} backgroundColor={colors.primary[400]}>
                    <Stack p={2} direction="row" spacing={2}>
                        <TextField label="Select Branch" 
                        value={branchID} onChange={(e) => setBranchID(e.target.value)}
                        variant='standard'
                        select fullWidth>
                            {branches &&
                                branches.map((branch) => (
                                    <MenuItem key={branch.id} value={branch.id}>
                                        {branch.branchName}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <Button variant="contained" color='secondary' onClick={saveBranch}>
                            Update Branch
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChangeBranch;
