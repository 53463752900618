import { Box, FormHelperText, IconButton, Tooltip, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'Auth';

import { useContext } from 'react';
import { ColorModeContext, tokens } from '../theme';
import InputBase from '@mui/material/InputBase';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import PasswordOutlined from '@mui/icons-material/PasswordOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { DownloadDoneOutlined, DownloadForOfflineOutlined, DownloadingOutlined, DownloadOutlined, SwitchAccessShortcutAddOutlined } from '@mui/icons-material';

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();
    const auth = useAuth();

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* SEARCH BAR */}
            <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
                <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                <IconButton type="button" sx={{ p: 1 }}>
                    <SearchIcon />
                </IconButton>
            </Box>

            {/* ICONS */}
            <Box display="flex">
                <Tooltip title="Mode">
                    <IconButton onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === 'dark' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download Section">
                    <IconButton onClick={() => navigate('/app/downloads')}>
                        <DownloadOutlined />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Change Branch">
                    <IconButton onClick={() => navigate('/app/change-branch')}>
                        <SwitchAccessShortcutAddOutlined />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Change Password">
                    <IconButton onClick={() => navigate('/app/change-password')}>
                        <PasswordOutlined />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Logout">
                    <IconButton onClick={() => auth.onLogout()}>
                        <LogoutOutlined />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default Topbar;
