import { Typography, Box, useTheme, Stack, Divider } from '@mui/material';
import { tokens } from '../theme';
import { Helmet } from "react-helmet";

const Header = ({ title, subtitle }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box mb="10px" justifyContent="space-between" alignItems="center" gridColumn="span 12">
             <Helmet>
                <title>{title}</title>
                <meta name={subtitle} content={title} />
            </Helmet>
            <Stack direction="column" spacing={1} >           
                <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{ m: '0 0 5px 0' }}>
                    {title}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[400]}>
                    {subtitle}
                </Typography>
                
                <Divider sx={{my:1,width:'100%'}} />
            </Stack>
            
        </Box>
    );
};

export default Header;
