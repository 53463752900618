 
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const RctSectionLoader = () => (
    <div className="d-flex justify-content-center loader-overlay">
        <CircularProgress color='secondary' />
    </div>
);

export default RctSectionLoader;
