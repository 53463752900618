import { useEffect, useState } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from 'Auth';
import {  ThemeProvider,CssBaseline } from '@mui/material'; 
import { ColorModeContext, useMode } from "./theme";
import 'react-notifications/lib/notifications.css'; 
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from 'layout-new/Layout';
import Dashboard from 'views/dashboard';
import Table from 'views/table/Table';

// project imports 
import Login from 'views/login/Login';
import NotFound from 'views/login/404'
import ChangePassword from 'views/login/ChangePassword';
import ChangeBranch from 'views/login/ChangeBranch';

import NewOrder from 'views/transaction/NewOrder';
import KOT from 'views/transaction/Kot';
import MakeBill from 'views/transaction/MakeBill';
import MakePayment from 'views/transaction/MakePayment';
import CancelBill from 'views/transaction/CancelBill';
import HomeDelivery from 'views/transaction/HomeDelivery';
import TakeAway from 'views/transaction/TakeAway';
import PendingOrder from 'views/transaction/PendingOrder';
import PurchaseEntry from 'views/transaction/PurchaseEntry';
import PurchaseEntryList from 'views/transaction/PurchaseEntryList';
import StockTransferList from 'views/transaction/StockTransferList';
import StockTransfer from 'views/transaction/StockTransfer';

import BillTaxReport from 'views/reports/BilltaxReport';
import StockReport from 'views/reports/StockReport';
import ItemReport from 'views/reports/ItemReport';
import StockTransferReport from 'views/reports/StockTransferReport';
import BrandCategoryRegister from 'views/reports/BrandCategoryRegister';

import ItemMasterList from 'views/master/itemMaster';
import PriceManagementMaster from 'views/master/itemMaster/PriceManagement';
import ItemMaster from 'views/master/itemMaster/AddNewItemForm';
import ImportItemMaster from 'views/master/itemMaster/ImportItems';

import BrandMasterList from 'views/master/brandMaster';
import BrandMaster from 'views/master/brandMaster/AddNewItemForm';

import CounterMasterList from 'views/master/counterMaster';
import CounterMaster from 'views/master/counterMaster/AddNewItemForm';

import CategoryMasterList from 'views/master/categoryMaster';
import CategoryMaster from 'views/master/categoryMaster/AddNewItemForm';

import RoomMaster from 'views/master/roomMaster/AddNewItemForm';
import RoomMasterList from 'views/master/roomMaster';

import UserMaster from 'views/master/userMaster/AddNewItemForm';
import UserMasterList from 'views/master/userMaster';

import UserPermissionGroupMaster from 'views/master/userPermissionGroupMaster/AddNewItemForm';
import UserPermissionGroupMasterList from 'views/master/userPermissionGroupMaster';

import RoomTypeMasterList from 'views/master/roomTypeMaster';
import RoomTypeMaster from 'views/master/roomTypeMaster/AddNewItemForm';

import RoomTarrifMaster from 'views/master/roomTariffMaster';

import RoomFloorMaster from 'views/master/floorMaster/AddNewItemForm';
import RoomFloorMasterList from 'views/master/floorMaster';

import AccountMaster from 'views/master/accountMaster/AddNewItemForm';
import AccountMasterList from 'views/master/accountMaster';

import MopMaster from 'views/master/mopMaster/AddNewItemForm';
import MopMasterList from 'views/master/mopMaster';

import Settings from 'views/Settings';
import Downloads from 'views/Downloads';

import Protected from 'ProtectedRoute';
import { NotificationContainer } from 'react-notifications'; 

 
// ==============================|| APP ||============================== //


const queryClient = new QueryClient();
const App = () => { 
    const [theme, colorMode] = useMode();
    const [isLoggedIn, setIsLoggedIn] = useState(null);
   
console.log(localStorage.getItem('user_id'))
console.log(localStorage.getItem('access_token'))
    useEffect(() => {
        if (isLoggedIn == null) {
            if (localStorage.getItem('access_token') == null) {
                setIsLoggedIn(false)
            } else {
                setIsLoggedIn(true)
            }
        }
    });

    return (
        <ColorModeContext.Provider value={colorMode}>
        <QueryClientProvider client={queryClient}>   
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <NotificationContainer />
                
                    <AuthProvider>                        
                        <Routes>
                            <Route path='/login' element={localStorage.getItem('access_token') == null || localStorage.getItem('user_id') == null ? <Login /> : <Navigate to="/" replace />} />
                            <Route path='/' element={<Protected isLoggedIn={localStorage.getItem('access_token') != null && localStorage.getItem('user_id') != null}><Layout /></Protected>}>
                                <Route index element={<Dashboard />} />
                                <Route path='app/table' element={<Table />} />

                                <Route path='app/change-password' element={<ChangePassword />} />
                                <Route path='app/change-branch' element={<ChangeBranch />} />

                                <Route path='app/new-order' element={<NewOrder />} />
                                <Route path='app/kot' element={<KOT />} />
                                <Route path='app/make-bill' element={<MakeBill />} />
                                <Route path='app/make-payment' element={<MakePayment />} />
                                <Route path='app/cancel-bill' element={<CancelBill />} />
                                <Route path='app/home-delivery' element={<HomeDelivery />} />
                                <Route path='app/take-away' element={<TakeAway />} />
                                <Route path='app/pending-order' element={<PendingOrder />} />
                                <Route path='app/purchase-entry' element={<PurchaseEntry />} />
                                <Route path='app/purchase-entry-list' element={<PurchaseEntryList />} />
                                <Route path='app/stock-transfer-list' element={<StockTransferList />} />
                                <Route path='app/stock-transfer' element={<StockTransfer />} />

                                <Route path='app/category-master-list' element={<CategoryMasterList />} />
                                <Route path='app/category-master' element={<CategoryMaster />} /> 

                                <Route path='app/item-master' element={<ItemMaster />} />
                                <Route path='app/price-management' element={<PriceManagementMaster />} />
                                <Route path='app/item-master-list' element={<ItemMasterList />} />
                                <Route path='app/import-item-master' element={<ImportItemMaster />} />

                                <Route path='app/brand-master' element={<BrandMaster />} />
                                <Route path='app/brand-master-list' element={<BrandMasterList />} />

                                <Route path='app/counter-master' element={<CounterMaster />} />
                                <Route path='app/counter-master-list' element={<CounterMasterList />} /> 

                                <Route path='app/room-master' element={<RoomMaster />} />
                                <Route path='app/room-master-list' element={<RoomMasterList />} />

                                <Route path='app/user-master' element={<UserMaster />} />
                                <Route path='app/user-master-list' element={<UserMasterList />} />

                                <Route path='app/user-permission-group-master' element={<UserPermissionGroupMaster />} />
                                <Route path='app/user-permission-group-master-list' element={<UserPermissionGroupMasterList />} />

                                <Route path='app/room-floor-master' element={<RoomFloorMaster />} />
                                <Route path='app/room-floor-master-list' element={<RoomFloorMasterList />} />

                                <Route path='app/room-type-master-list' element={<RoomTypeMasterList />} />
                                <Route path='app/room-type-master' element={<RoomTypeMaster />} />
                                
                                <Route path='app/room-tariff-master' element={<RoomTarrifMaster />} /> 

                                <Route path='app/account-master-list' element={<AccountMasterList />} />
                                <Route path='app/account-master' element={<AccountMaster />} />
                                
                                <Route path='app/mop-master-list' element={<MopMasterList />} />
                                <Route path='app/mop-master' element={<MopMaster />} />

                                <Route path='app/settings' element={<Settings />} />
                                <Route path='app/Downloads' element={<Downloads />} />

                                <Route path='app/bill-tax-report' element={<BillTaxReport />} />
                                <Route path='app/item-report' element={<ItemReport />} />
                                <Route path='app/stock-report' element={<StockReport />} />
                                <Route path='app/stock-transfer-report' element={<StockTransferReport />} />
                                <Route path='app/brand-category-register' element={<BrandCategoryRegister />} />

                            </Route>
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </AuthProvider>
                    
                </ThemeProvider> 
            <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </QueryClientProvider>
        </ColorModeContext.Provider>
    );
};

export default App;

