import { useState, useEffect } from 'react';
 
import { Stack, Button, useTheme, Box, TableContainer, Paper, IconButton, TextField,Grid
,Typography } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';

var moment = require('moment');

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);    

    const [orders, setOrders] = useState(null);

    const [startDate, setStartDate] = useState(new Date().toLocaleString());
    const [endDate, setEndDate] = useState(new Date().toLocaleString()); 
    const [loading, setLoading] = useState(false);

        const [docType,setDocType]=useState('S');
        const [itemType,setItemType]=useState(null);
        const [partyId,setPartyId]=useState(0);
        const [salesmanId,setSalesmanId]=useState(0);
        const [departmentId,setDepartmentId]=useState(0);
        const [brandId,setBrandId]=useState(0);
        const [warehouseId,setWarehouseId]=useState(0);

        const [warehouses, setWarehouses] = useState(null);
        const [salesMans, setSalesMans] = useState(null);

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    console.log(orders);

    const loadWareHouse = async () => {
        await api.get('/api/stock-transfer/get-warehouse-list')
            .then((response) => {
                setWarehouses(response.data);
            })
            .catch(error => {
            });
    }

    const loadSalesMan = async () => {
        await api.get('/api/master/sales-person-master')
            .then((response) => {
                setSalesMans(response.data);
                console.log(response.data);
            })
            .catch(error => {
            });
    }

    useEffect(() => {
        (async () => { 
        await loadWareHouse();
    })();

        (async () => {
 await loadSalesMan();
        })();

        (async () => {
 
        })();

        (async () => {
 
        })();

        (async () => {
 
        })();

        (async () => {
            loadData(1);
        })(); 
    }, []);

    useEffect(() => {
        (async () => {
            loadData(page);
        })();
    }, [page, rowsPerPage]);

    const loadData = async () => {
        setLoading(true);
        await api
            .get('api/rest/report/get-brand-category-register?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: {
                    fromDate: startDate,
                    toDate: endDate,
                    docType: docType,
                    itemType:itemType,
                    partyId:partyId,
                    salesmanId:salesmanId,
                    departmentId:departmentId,
                    brandId:brandId,
                    warehouseId:warehouseId
                }
            })
            .then((response) => {
                setCount(response.data.totalRecords);
                setOrders(response.data.data);
                console.log(response.data.totalRecords);
                console.log(response.data.data);
            })
            .catch((error) => { 
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const exportReport = async () => {
        setLoading(true);
        await api
            .get('api/rest/report/get-brand-category-register?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: {
                    fromDate: startDate,
                    toDate: endDate,
                    docType: docType,
                    itemType:itemType,
                    partyId:partyId,
                    salesmanId:salesmanId,
                    departmentId:departmentId,
                    brandId:brandId,
                    warehouseId:warehouseId,
                    export: true
                }
            })
            .then((msg) => {
                console.log(msg);
                var a = document.createElement('a');
                a.href = msg.data;
                a.download = 'BrandCategoryRegister.xlsx';
                document.body.appendChild(a);
                a.click();
            })
            .catch((error) => { 
            })
            .finally(() => {
                setLoading(false);
            });
    }; 

    const handleChangePage = async (newPage) => {
        //setPage(page + 1);
        console.log('page........');
        console.log('New Page : ' + newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        console.log('Rows per page changed !');
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions() {
        const handleFirstPageButtonClick = () => {
            setPage(0);
            // loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            //loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            //loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            //loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <Box m="20px">
            {/* HEADER */}
            <Header title="BRAND CATEGORY REGISTER" subtitle="See your items" />

            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                {/* searchText */}
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1 }} 
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    p={2}
                >
                    <TextField
                        type="date"
                        label="Start Date"
                        value={moment(startDate).format('YYYY-MM-DD')}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <TextField
                        type="date"
                        label="End Date"
                        value={moment(endDate).format('YYYY-MM-DD')}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <div className='col-md-2 mt-10'>
                        <Select  
                                        value={warehouseId}
                                        onChange={(e) => setWarehouseId(e.target.value)}
                                        style={{width:'100%'}} 
                                    >
                                        <MenuItem key={0} value={0} selected>-warehouse-</MenuItem>
                                        {warehouses && warehouses.map((warehouse) => <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.wname}</MenuItem>)}
                        </Select>  
                    </div>
                    <div className='col-md-2 mt-10'>
                            <Select  
                                        value={salesmanId}
                                        onChange={(e) => setSalesmanId(e.target.value)}
                                        style={{width:'100%'}} 
                                    >
                                        <MenuItem key={0} value={0}>-sales man-</MenuItem>
                                        {salesMans && salesMans.map((salesMan) => <MenuItem key={salesMan.id} value={salesMan.id}>{salesMan.salesPersonName}</MenuItem>)}
                            </Select>  
                    </div>
                    <div className='col-md-2 mt-10'>
                            <Select  
                                    value={itemType}
                                    onChange={(e) => setItemType(e.target.value)}
                                    style={{width:'100%'}} 
                                    >
                                        <MenuItem key={'null'} value={null}>{'-item type-'}</MenuItem>
                                        <MenuItem key={'Goods'} value={'Goods'}>{'Goods'}</MenuItem>
                                        <MenuItem key={'Service'} value={'Service'}>{'Service'}</MenuItem>
                            </Select>  
                    </div>
                    {/* <TextField type="text" label="Search Text" value={searchText} onChange={(e) => setSearchText(e.target.value)} /> */}
                    <Button size="large" color="primary" variant="contained" onClick={() => loadData(0)}>
                        Search
                    </Button>
                    <Button color="success" variant="contained" onClick={exportReport}>
                        Export
                    </Button>
                    {loading && <RctSectionLoader />}
                </Stack>
       

                {/* tables */}
                <Box
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    sx={{
                        '& .MuiTableHead-root': {
                            backgroundColor: colors.greenAccent[700]
                        },
                        '& .MuiTableRow-footer': {
                            backgroundColor: colors.redAccent[700]
                        }
                    }}
                >
                    <TableContainer component={Paper}>
                        {orders && (
                            <Table>
                                <TableHead>
                                    <TableRow> 
                                        <TableCell>Item Code</TableCell>
                                        <TableCell>Item Name</TableCell>
                                        <TableCell>Doc. No.</TableCell>
                                        <TableCell>Party</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell align="right">Qty</TableCell>
                                        <TableCell align="right">Rate</TableCell>
                                        <TableCell align="right">Taxable</TableCell> 
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders &&
                                        orders.map((order, key) =>
                                            order.itemType != null ? (
                                                <TableRow key={key}> 
                                                   <TableCell>{order.itemCode}</TableCell>
                                                    <TableCell> {order.itemName}</TableCell>
                                                    <TableCell> {order.docNo}</TableCell>
                                                    <TableCell> {order.partyName}</TableCell>
                                                    <TableCell>{moment(order.docDate).format('DD-MMM-YYYY hh:mm a')}</TableCell>
                                                    <TableCell align="right">{order.quantity}</TableCell>
                                                    <TableCell align="right">{order.rate}</TableCell>
                                                    <TableCell align="right">{order.taxable}</TableCell>
                                                   
                                                </TableRow>
                                            ) : null
                                        )}
                                </TableBody>
                                <TableFooter bgcolor={'info.light'}>
                                    <TableRow> 
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell align="right">Total : </TableCell>
                                        <TableCell align="right">{orders[orders.length - 1].quantity}</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell align="right">{orders[orders.length - 1].taxable}</TableCell> 
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                    <TablePagination
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                        labelRowsPerPage={<span>Rows:</span>}
                        labelDisplayedRows={({ page }) => {
                            return `Page: ${page + 1}`;
                        }}
                        backIconButtonProps={{
                            color: 'secondary'
                        }}
                        nextIconButtonProps={{ color: 'secondary' }}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'page number'
                            }
                        }}
                        showFirstButton={true}
                        showLastButton={true}
                        ActionsComponent={TablePaginationActions}
                        component={Box}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
