/**
 * User Management Page
 */
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet"; 
import { NotificationManager } from 'react-notifications';


// api
import api from 'api';

// delete confirmation dialog
import DeleteConfirmationDialog from '../../component/DeleteConfirmationDialog/DeleteConfirmationDialog';

import Header from 'components/Header';


// rct card box
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';

// rct section loader
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import moment from 'moment';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import { Link } from 'react-router-dom'; 
import { TextField } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';

export default function PurchaseEntryList(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const deleteConfirmationDialog = useRef(null);
    const [startDate, setStartDate] = useState(new Date().toLocaleString());
    const [endDate, setEndDate] = useState(new Date().toLocaleString());

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (items.length === 0) {
            (async () => {
                loadItems();
            })();
        }
    }, []);

    useEffect(() => {
        (async () => {
            loadItems(page);
        })();
    }, [page, rowsPerPage]);

    const loadItems = async () => {
        setLoading(true);
        await api.get('api/stock-transfer/get-stock-transfer-list?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
            params: {
                fromDate: startDate,
                toDate: endDate
            }
        })
            .then((response) => {
                setCount(response.data.totalRecords);
                setItems(response.data.data);
            })
            .catch(error => {
                console.log(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });
    }

    const onReload = async (e) => {
        e.preventDefault();
        await loadItems();
        setTimeout(() => {
        }, 2000);
    }

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(item);
    }

    const deleteItemPermanently = async () => {
        setLoading(true);
        deleteConfirmationDialog.current.close();
        await api.delete('api/stock-transfer/delete-document?id=' + selectedItem.id)
            .then(async (response) => {
                if (response.status === 200) {
                    NotificationManager.success('Document Deleted!');
                    await loadItems();
                }
                else {
                    NotificationManager.error(JSON.stringify(response.message));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.message));
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleChangePage = async (newPage) => {
        //setPage(page + 1); 
        console.log("page........");
        console.log("New Page : " + newPage);
    }

    const handleChangeRowsPerPage = async (event) => {
        console.log("Rows per page changed !");
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions() {

        const handleFirstPageButtonClick = () => {
            setPage(0);
            // loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            //loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            //loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            //loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <div className="user-management">
             {/* HEADER */}
  <Header title="STOCK TRANSFER LIST" subtitle="ledgers" />    
            <RctCollapsibleCard>
                {loading && <RctSectionLoader />}
                <div className="row">
                    <div className='col-md-6'>
                        <TextField
                            type="date"
                            label="Start Date"
                            value={moment(startDate).format("YYYY-MM-DD")}
                            onChange={(e) => setStartDate(e.target.value)}
                        >
                        </TextField>{'  '}
                        <TextField
                            type="date"
                            label="End Date"
                            value={moment(endDate).format("YYYY-MM-DD")}
                            onChange={(e) => setEndDate(e.target.value)}
                        >
                        </TextField>
                        <button
                            className="btn btn-primary ml-2">
                            <i className="ti-reload" onClick={(e) => loadItems()}></i>
                        </button>
                    </div>
                    <div className='col-md-3'></div>
                    <div className='col-md-3 text-right'>
                        <Link to='/app/stock-transfer' state={{ billID: 0 }} className="btn btn-primary d-flex"><AddIcon />Add New Entry</Link>
                    </div>
                </div>
            </RctCollapsibleCard>
            <RctCollapsibleCard fullBlock>
                <div className="table-responsive"> 
                        {items.length > 0 && items && <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Doc No.</TableCell>
                                    <TableCell>Doc Date</TableCell>
                                    <TableCell>Godown (From - To)</TableCell> 
                                    <TableCell align="center">MRP Value ({'\u20B9'})</TableCell>
                                    <TableCell align="center">Purchase Value ({'\u20B9'})</TableCell>
                                    <TableCell align="center">Qty</TableCell>
                                    <TableCell>EntryBy</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items && items.map((item, key) => (
                                    (item.docNo != null ?
                                        <TableRow key={key}>
                                            <TableCell>{item.docNo}</TableCell>
                                            <TableCell>
                                                <div className="media-left mr-15">
                                                    {moment(item.docDate).format("DD-MMM-YYYY")}
                                                </div>
                                            </TableCell>
                                            <TableCell>{item.godownFrom} - {item.godownTo}</TableCell>
                                            <TableCell align='center'>{item.mrpValue}</TableCell>
                                            <TableCell align='center'>{item.purchaseValue}</TableCell>
                                            <TableCell align='center'>{item.totalQty}</TableCell>
                                            <TableCell>
                                                {item.createdBy}<br />
                                                {moment(item.creationTime).format("DD-MMM-YYYY hh:mm a")}
                                            </TableCell>
                                            <TableCell>
                                                <Link to='/app/stock-transfer' state={{ billID: item.id }}>
                                                    <EditIcon />
                                                </Link>{'   '}{'   '}{'   '}
                                                <DeleteIcon onClick={() => onDelete(item)} />
                                            </TableCell>
                                        </TableRow> : null)
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>-</TableCell>
                                    <TableCell>-</TableCell>
                                    <TableCell align='center'>Total :  </TableCell>
                                    <TableCell align='center' style={{ fontWeight: 'bold', fontSize: 16 }}>{items[items.length - 1].mrpValue}</TableCell>
                                    <TableCell align='center' style={{ fontWeight: 'bold', fontSize: 16 }}>{items[items.length - 1].purchaseValue}</TableCell>
                                    <TableCell align='center' style={{ fontWeight: 'bold', fontSize: 16 }}>{items[items.length - 1].totalQty}</TableCell>
                                    <TableCell>-</TableCell>
                                    <TableCell>-</TableCell> 
                                </TableRow>
                                <TableRow>
                                    <TablePagination
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                        labelRowsPerPage={<span>Rows:</span>}
                                        labelDisplayedRows={({ page }) => {
                                            return `Page: ${page + 1}`;
                                        }}
                                        backIconButtonProps={{
                                            color: "secondary"
                                        }}
                                        nextIconButtonProps={{ color: "secondary" }}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "page number"
                                            }
                                        }}
                                        showFirstButton={true}
                                        showLastButton={true}
                                        ActionsComponent={TablePaginationActions}
                                        //component={Box}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>} 

                        {items.length == 0 ? <center><h1><br /><br /><br /><br />Oops... no document found <br /><br /><br /><br /><br /></h1></center> : null}
                </div>
            </RctCollapsibleCard >
            < DeleteConfirmationDialog
                ref={deleteConfirmationDialog}
                title="Are You Sure Want To Delete?"
                message="This will delete document permanently."
                onConfirm={() => deleteItemPermanently()
                }
            />
        </div >
    );

}
