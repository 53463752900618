import React from 'react';
import RctCollapsibleCard from 'component/RctCollapsibleCard/RctCollapsibleCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TextField } from '@mui/material';

export default function MakePayment(props) {
    const handleChange = (val, mopid) => {
        let arrs = props.billDetails.mopDatas;
        let paid = 0;
        arrs.map((arr, key) => {
            if (arr["mopid"] === mopid) {
                if (val.length == 0)
                    arr["amount"] = 0;
                else
                    arr["amount"] = val;
            }

            paid += parseFloat(arr["amount"]);
        });
        props.setBillDetails({ ...props.billDetails, mopDatas: arrs, paidAmount: paid });
    };

    return (
        <>
            <RctCollapsibleCard>
                <TextField
                    id="standard-read-only-input-2"
                    label="Payable Amount"
                    value={props.billDetails.netAmt}
                    defaultValue={props.billDetails.netAmt}
                    InputProps={{
                        readOnly: true,
                        style: { textAlign: "center", backgroundColor: "#5cb85c", color: "#FFF" }
                    }}
                    variant="outlined"
                />
                {'  '}
                <TextField
                    id="standard-read-only-input-1"
                    label="Paid Amount"
                    value={props.billDetails.paidAmount}
                    defaultValue={props.billDetails.paidAmount}
                    InputProps={{
                        readOnly: true,
                        style: { textAlign: "center", backgroundColor: "#F32013", color: "#FFF" }
                    }}
                    variant="outlined"
                />

            </RctCollapsibleCard>
            <RctCollapsibleCard>
                <div className="table-responsive">
                    <Table>
                        <TableBody>
                            <>
                                {props.billDetails.mopDatas.length > 0 && props.billDetails.mopDatas && props.billDetails.mopDatas.map((mopData, key) => (
                                    <TableRow hover key={key}>
                                        <TableCell>{mopData["mopname"]}</TableCell>
                                        <TableCell>
                                            <input type="number" value={mopData["amount"]} className="form-control" onChange={(e) => handleChange(e.target.value, mopData["mopid"])} min="0" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        </TableBody>
                    </Table>
                </div>
            </RctCollapsibleCard>
        </>
    )
}