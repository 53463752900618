import { useState, useEffect, Fragment } from 'react';
import { Button, Stack, Checkbox, Box, useTheme, TextField } from '@mui/material';

import '@trendmicro/react-paginations/dist/react-paginations.css';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableContainer } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';
export default function PriceManagement() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [branches, setBranches] = useState([]);
    const [branchID, setBranchID] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (branches.length === 0) {
            (async () => {
                getBranchList();
            })();
        }
    }, []);

    const getBranchList = async () => {
        setLoading(true);
        await api
            .get('api/master/branch')
            .then((response) => {
                if (response.status === 200) {
                    setBranches(response.data);
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const loadItems = async (tbranchID) => {
        setLoading(true);
        await api
            .get('api/master/product/get-product-list-branch?branchID=' + tbranchID)
            .then((response) => {
                setItems(response.data);
            })
            .catch((error) => {
                console.log(JSON.stringify(error.message.data));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onReload = async (e) => {
        e.preventDefault();
        await loadItems(branchID);
    };

    const handleBranchChange = async (e) => {
        setBranchID(e.target.value);
        await loadItems(e.target.value);
    };

    const updatePrice = async () => {
        setLoading(true);
        await api
            .post('api/master/product/update-product-list-branch', JSON.stringify(items))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Items Saved Successfully.');
                    loadItems();
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChangePrice = (value, id, valueFor) => {
        let arrs = [...items];
        const elementsIndex = arrs.findIndex((element) => element['id'] == id);
        if (valueFor == 'mrp') {
            arrs[elementsIndex]['mrp'] = value;
        } else {
            arrs[elementsIndex]['tag'] = value;
        }
        setItems((prevState) => arrs);
    };

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Price Management" subtitle="branch wise price management" />
            </Box>
            {loading && <RctSectionLoader />}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                    {loading && <RctSectionLoader />}
                    <a href="#" onClick={(e) => onReload(e)} className="btn-outline-default mr-10">
                        <i className="ti-reload"></i>
                    </a>

                    <TextField label="Category" value={branchID} onChange={handleBranchChange} select fullWidth variant="standard">
                        <MenuItem value="0">---Select Branch---</MenuItem>
                        {branches &&
                            branches.map((branch) => (
                                <MenuItem key={branch.id} value={branch.id}>
                                    {branch.branchCode}
                                </MenuItem>
                            ))}
                    </TextField>

                    <Button variant="contained" color="secondary" onClick={updatePrice}>
                        Update Price
                    </Button>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow hover>
                                    <TableCell>Delete</TableCell>
                                    <TableCell>ItemCode</TableCell>
                                    <TableCell>ItemName</TableCell>
                                    <TableCell>Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Fragment>
                                    {items &&
                                        items.map((item, key) => (
                                            <TableRow hover key={key}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={item['tag']}
                                                        onChange={(e) => onChangePrice(!item['tag'], item.id, 'tag')}
                                                    />
                                                </TableCell>
                                                <TableCell>{item['itemCode']}</TableCell>
                                                <TableCell>{item['itemName']}</TableCell>
                                                <TableCell>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            value={item['mrp']}
                                                            onChange={(e) => onChangePrice(e.target.value, item.id, 'mrp')}
                                                        ></input>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </Fragment>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>
        </Box>
    );
}
