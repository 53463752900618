import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Switch, Button, MenuItem, TextField, Box, FormControlLabel, Typography, Stack, Divider, useTheme } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';

const AddNewItemForm = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state.id;

    console.log(id);

    const initialItem = {
        itemType: 'Goods',
        departmentId: 0,
        brandId: 0,
        id: 0,
        itemCode: '',
        itemName: '',
        hsncode: '',
        mrp: 0,
        redTag: 0,
        openItem: 0,
        incTax: 0,
        status: 'Active',
        counterId: 0,
        GenerateChildId: 'No',
        discountable: true,
        saleable: 1,
        cgstid: 0,
        unitId: 1,
        taxibility: 'Taxable'
    };
    const [addNewItemDetail, setAddNewItemDetail] = useState(initialItem);
    const [loading, setLoading] = useState(false);
    const [departments, setDepartments] = useState(null);
    const [brands, setBrands] = useState(null);
    const [counters, setCounters] = useState(null);
    const [gsts, setGsts] = useState(null);
    const [units, setUnits] = useState(null);

    const onChangeAddNewItemDetails = (key, value) => {
        var arr = { ...addNewItemDetail };
        arr[key] = value;
        setAddNewItemDetail((prevState) => arr);
    };

    const addNewItem = async () => {
        if (addNewItemDetail.departmentId === 0) {
            NotificationManager.error('Category Required !');
            return;
        }
        if (addNewItemDetail.brandId === 0) {
            NotificationManager.error('Brand Required !');
            return;
        }
        if (addNewItemDetail.itemCode === '') {
            NotificationManager.error('Item Code Required !');
            return;
        }
        if (addNewItemDetail.itemName === '') {
            NotificationManager.error('Item Name Required !');
            return;
        }
        //console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api
            .post('api/master/product', JSON.stringify(addNewItemDetail))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Item Added Successfully.');
                    navigate('/app/item-master-list');
                } else {
                    NotificationManager.error(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(error.response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (departments === null) {
            (async () => {
                await api
                    .get('api/master/product-department')
                    .then((response) => {
                        setDepartments(response.data);
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error));
                    });
            })();
        }

        if (units === null) {
            (async () => {
                await api
                    .get('api/master/unit-master')
                    .then((response) => {
                        setUnits(response.data);
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error));
                    });
            })();
        }

        (async () => {
            await api
                .get('api/master/product-brand?PageNumber=1&PageSize=100')
                .then((response) => {
                    setBrands(response.data.data);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                });
        })();

        (async () => {
            await api
                .get('api/master/counter-master?PageNumber=1&PageSize=100')
                .then((response) => {
                    setCounters(response.data.data);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                });
        })();

        if (gsts === null) {
            (async () => {
                await api
                    .get('api/master/gst-master')
                    .then((response) => {
                        console.log(response.data);
                        setGsts(response.data);
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error));
                    });
            })();
        }
    }, []);

    const updateItem = async () => {
        if (addNewItemDetail.itemCode === '') {
            NotificationManager.error('Item Code Required !');
            return;
        }
        if (addNewItemDetail.itemName === '') {
            NotificationManager.error('Item Name Required !');
            return;
        }
        console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api
            .put('api/master/product?id=' + addNewItemDetail.id, JSON.stringify(addNewItemDetail))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Item Updated Successfully.');

                    navigate('/app/item-master-list');
                } else {
                    NotificationManager.error(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (addNewItemDetail.id == 0 && id > 0) {
            (async () => {
                await api
                    .get('api/master/product/' + id)
                    .then((response) => {
                        console.log(response.data);
                        setAddNewItemDetail({
                            itemType: response.data.itemType,
                            departmentId: response.data.departmentId,
                            brandId: response.data.brandId,
                            id: response.data.id,
                            itemCode: response.data.itemCode,
                            itemName: response.data.itemName,
                            hsncode: response.data.hsncode,
                            mrp: response.data.mrp,
                            redTag: response.data.redTag,
                            openItem: response.data.openItem,
                            incTax: response.data.incTax,
                            status: response.data.status,
                            counterId: response.data.counterId,
                            GenerateChildId: 'No',
                            discountable: response.data.discountable,
                            saleable: 1,
                            cgstid: response.data.cgstid,
                            unitId: response.data.unitId,
                            taxibility: 'Taxable'
                        });
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error));
                    });
            })();
        }
    }, [id > 0]);

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="ITEM MASTER" subtitle="add/edit item" />
            </Box>
            {loading && <RctSectionLoader />}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>                    
                    <TextField
                        label="Category"
                        value={addNewItemDetail.departmentId}
                        onChange={(e) => onChangeAddNewItemDetails('departmentId', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select Category
                        </MenuItem>
                        {departments &&
                            departments.map((department) => (
                                <MenuItem key={department.id} value={department.id}>
                                    {department.name}
                                </MenuItem>
                            ))}
                    </TextField>
                    <TextField
                        label="Brand"
                        value={addNewItemDetail.brandId}
                        onChange={(e) => onChangeAddNewItemDetails('brandId', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select Brand
                        </MenuItem>
                        {brands &&
                            brands.map((brand) => (
                                <MenuItem key={brand.brdGrpNo} value={brand.brdGrpNo}>
                                    {brand.brandGrp}
                                </MenuItem>
                            ))}
                    </TextField>
                    <Box textAlign="right" gridColumn="span 12">
                        <Link to={{ pathname: '/app/brand-master-list' }}>Add New Brand</Link>
                    </Box>
                    <TextField
                        label="Counter"
                        value={addNewItemDetail.counterId}
                        onChange={(e) => onChangeAddNewItemDetails('counterId', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select Counter
                        </MenuItem>
                        {counters &&
                            counters.map((counter) => (
                                <MenuItem key={counter.id} value={counter.id}>
                                    {counter.counterName}
                                </MenuItem>
                            ))}
                    </TextField>
                    <Box textAlign="right" gridColumn="span 12">
                        <Link to={{ pathname: '/app/counter-master-list' }}>Add New Counter</Link>
                    </Box>
                    <TextField
                        label="GST"
                        value={addNewItemDetail.cgstid}
                        onChange={(e) => onChangeAddNewItemDetails('cgstid', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select GST
                        </MenuItem>
                        {gsts &&
                            gsts.map((gst) => (
                                <MenuItem key={gst.id} value={gst.id}>
                                    {gst.gstname}
                                </MenuItem>
                            ))}
                    </TextField>
                    <TextField
                        label="Unit"
                        value={addNewItemDetail.unitId}
                        onChange={(e) => onChangeAddNewItemDetails('unitId', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select Unit
                        </MenuItem>
                        {units &&
                            units.map((unit) => (
                                <MenuItem key={unit.id} value={unit.id}>
                                    {unit.upperunit} - {unit.lowerUnit} - {unit.contains}
                                </MenuItem>
                            ))}
                    </TextField>
                    <TextField
                        fullWidth
                        label="Item Code"
                        value={addNewItemDetail.itemCode}
                        onChange={(e) => onChangeAddNewItemDetails('itemCode', e.target.value)}
                        variant="standard"
                    />
                    <TextField
                        fullWidth
                        label="Item Name"
                        value={addNewItemDetail.itemName}
                        onChange={(e) => onChangeAddNewItemDetails('itemName', e.target.value)}
                        variant="standard"
                    />
                    <TextField
                        fullWidth
                        label="HSN Code"
                        value={addNewItemDetail.hsncode}
                        onChange={(e) => onChangeAddNewItemDetails('hsncode', e.target.value)}
                        variant="standard"
                    />
                    <TextField
                        fullWidth
                        label="Price"
                        type={'number'}
                        value={addNewItemDetail.mrp}
                        onChange={(e) => onChangeAddNewItemDetails('mrp', e.target.value)}
                        variant="standard"
                    />
                    <TextField
                        label="Select Status"
                        value={addNewItemDetail.status}
                        onChange={(e) => onChangeAddNewItemDetails('status', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem selected key={0} value="Active">
                            Active
                        </MenuItem>
                        <MenuItem key={1} value="InActive">
                            InActive
                        </MenuItem>
                    </TextField>
                    <FormControlLabel
                        label="Non-Veg"
                        control={
                            <Switch
                                checked={addNewItemDetail.redTag === 1}
                                onChange={(e) => onChangeAddNewItemDetails('redTag', addNewItemDetail.redTag === 0 ? 1 : 0)}
                            />
                        }
                    />
                    <FormControlLabel
                        label="Open Item"
                        control={
                            <Switch
                                checked={addNewItemDetail.openItem === 1}
                                onChange={(e) => onChangeAddNewItemDetails('openItem', addNewItemDetail.openItem === 0 ? 1 : 0)}
                            />
                        }
                    />
                    <FormControlLabel
                        label="Inclusive Tax"
                        control={
                            <Switch
                                checked={addNewItemDetail.incTax === 1}
                                onChange={(e) => onChangeAddNewItemDetails('incTax', addNewItemDetail.incTax === 0 ? 1 : 0)}
                            />
                        }
                    />
                    <FormControlLabel
                        label="Discountable"
                        control={
                            <Switch
                                checked={addNewItemDetail.discountable === true}
                                onChange={(e) => onChangeAddNewItemDetails('discountable', !addNewItemDetail.discountable)}
                            />
                        }
                    />

                    {id == 0 ? (
                        <Button variant="contained" color="secondary" onClick={() => addNewItem()}>
                            Add
                        </Button>
                    ) : (
                        <Button variant="contained" color="secondary" onClick={() => updateItem()}>
                            Update
                        </Button>
                    )}

                    {'  '}
                    <Button variant="contained" color="error" onClick={() => navigate('/app/item-master-list')}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};
export default AddNewItemForm;
