import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Switch, Button, MenuItem, TextField, Box, FormControlLabel, Typography, Stack, Divider, useTheme } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';

const AddNewItemForm = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state.id;

    var initialState = {
        id: 0,
        counterName: '',
        status: 'Active'
    };
    const [addNewItemDetail, setAddNewItemDetail] = useState(initialState);
    const [loading, setLoading] = useState(false);

    const addNewItem = async () => {
        if (addNewItemDetail.counterName === '') {
            NotificationManager.error('Counter Required !');
            return;
        }
        setLoading(true);
        await api
            .post('api/master/counter-master', JSON.stringify(addNewItemDetail))
            .then((response) => {
                NotificationManager.success('Counter Added Successfully.');

                navigate('/app/counter-master-list');
            })
            .catch((error) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const updateItem = async () => {
        if (addNewItemDetail.counterName === '') {
            NotificationManager.error('Counter name required !');
            return;
        }
        setLoading(true);
        await api
            .put('api/master/counter-master?id=' + addNewItemDetail.id, JSON.stringify(addNewItemDetail))
            .then((response) => {
                NotificationManager.success('Counter Updated Successfully.');
                navigate('/app/counter-master-list');
            })
            .catch((error) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (addNewItemDetail.id == 0 && id > 0) {
            (async () => {
                await api
                    .get('api/master/counter-master/' + id)
                    .then((response) => {
                        console.log(response.data);
                        setAddNewItemDetail({
                            id: response.data.id,
                            counterName: response.data.counterName,
                            status: response.data.status
                        });
                    })
                    .catch((error) => {});
            })();
        }
    }, [id > 0]);

    return (
        <Box m="20px">
         <Header title="COUNTER MASTER" subtitle="add/edit counter" />
            {loading && <RctSectionLoader />}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                    <TextField
                    variant='standard'
                        autoFocus={true}
                        title="Enter counter name"
                        value={addNewItemDetail.counterName}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, counterName: e.target.value })}
                    />
                    <TextField
                        select
                        title="Status"
                        variant='standard'
                        value={addNewItemDetail.status}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, status: e.target.value })}
                    >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="InActive">InActive</MenuItem>
                    </TextField>
                    {id == 0 ? (
                        <Button variant="contained" color="secondary" onClick={() => addNewItem()}>
                            Add
                        </Button>
                    ) : (
                        <Button variant="contained" color="secondary" onClick={() => updateItem()}>
                            Update
                        </Button>
                    )}

                    {'  '}
                    <Button variant="contained" color="error" onClick={() => navigate('/app/counter-master-list')}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default AddNewItemForm;
