import { useState, useEffect } from 'react';
import { Button, TableContainer, TextField, useTheme, Paper, Box, Stack } from '@mui/material';
import Header from 'components/Header';
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import api from 'api';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';

export default function CancelBill(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [billDatas, setBillDatas] = useState([]);
    const [found, setFound] = useState(false);
    const [orderNo, setOrderNo] = useState('');
    const [startSubmit, setStartSubmit] = useState(false);

    useEffect(() => {}, []);

    const getBillDetails = async () => {
        await api
            .get('api/rest/kot-bill/get-billdetails-by-billno?billNo=' + orderNo)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        setBillDatas(response.data);
                        setFound(true);
                        setStartSubmit(false);
                        NotificationManager.success('Order Found');
                    } else {
                        setBillDatas([]);
                        setFound(false);
                        setStartSubmit(false);
                        NotificationManager.error('Order Not Found');
                    }
                } else {
                    NotificationManager.success(JSON.stringify(response));
                    setStartSubmit(false);
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
                setStartSubmit(false);
            });
    };

    const cancelBill = async () => {
        //console.log(billDatas[0].id);
        if (billDatas.length === 0) {
            NotificationManager.error('Order details not found');
            return;
        }
        setStartSubmit(true);
        await api
            .post('api/rest/kot-bill/cancel-bill?billID=' + billDatas[0].id)
            .then((response) => {
                if (response.status === 200) {
                    setBillDatas([]);
                    setFound(false);
                    setOrderNo('');
                    setStartSubmit(false);
                    NotificationManager.success('Bill Cancelled Successfully.');
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {})
            .finally(() => {
                setStartSubmit(false);
            });
    };

    const printBill = async () => {
        if (billDatas.length === 0) {
            NotificationManager.error('Order details not found');
            return;
        }
        setStartSubmit(true);
        console.log('api/rest/kot-bill/print-bill?billID=' + billDatas[0].id);
        await api
            .get('api/rest/kot-bill/print-bill?billID=' + billDatas[0].id)
            .then((response) => {
                if (response.status === 200) {
                    window.open('' + response.data + '', 'sharer', 'toolbar=0,status=0,width=548,height=525');
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {})
            .finally(() => {
                setStartSubmit(false);
            });
    };

    return (
        <Box m="20px">
            {/* HEADER */}
            <Header title="CANCEL BILL" subtitle="cancel bill / duplicate print" />

            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    p={2}
                >
                    <TextField type="text" placeholder="Enter order no." 
                    value={orderNo} onChange={(e) => setOrderNo(e.target.value)}
                    variant='standard' />
                    <Button variant='contained' color="secondary" onClick={getBillDetails}>
                        Get Bill Detail
                    </Button>
                    <Button color="secondary" disabled={!found || startSubmit} onClick={cancelBill}>
                        {startSubmit ? 'Please wait...' : 'Cancel Order'}
                    </Button>
                    <Button color='secondary' disabled={!found || startSubmit} onClick={printBill}>
                        {startSubmit ? 'Please wait...' : 'Print Duplicate Bill'}
                    </Button>
                </Stack>
            </Box>

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 1, md: 1 }}
                gridColumn="span 12"
                backgroundColor={colors.primary[400]}
                p={2}
                mt={'20px'}
                mb='20px'
            >
                <TextField type="text" label="Order No." readOnly value={billDatas.length > 0 ? billDatas[0].billNo : ''} />
                <TextField type="text" label="Order Date" readOnly value={billDatas.length > 0 ? billDatas[0].billDate : ''} />
                <TextField type="text" label="Bill Value" readOnly value={billDatas.length > 0 ? billDatas[0].billValue : ''} />
            </Stack>

            <Box
                gridColumn="span 12"
                backgroundColor={colors.primary[400]}
                sx={{
                    '& .MuiTableHead-root': {
                        backgroundColor: colors.greenAccent[700]
                    },
                    '& .MuiTableRow-footer': {
                        backgroundColor: colors.redAccent[700]
                    }
                }}
            >
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow hover>
                                <TableCell>Item</TableCell>
                                <TableCell>Qty</TableCell>
                                <TableCell>Rate</TableCell>
                                <TableCell>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {billDatas &&
                                    billDatas.map((billData, key) => (
                                        <TableRow hover key={key}>
                                            <TableCell>{billData['itemName']}</TableCell>
                                            <TableCell>{billData['qyantity']}</TableCell>
                                            <TableCell>{billData['rate']}</TableCell>
                                            <TableCell>{billData['amount']}</TableCell>
                                        </TableRow>
                                    ))}
                            </>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}
