import React, { useEffect, useState } from 'react';
import { Stack, Button, useTheme, Box, TableContainer,Paper,IconButton,TextField } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter'; 
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../components/Header';  
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';


var moment = require('moment');

const ItemReport = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [orders, setOrders] = useState(null);

    const [startDate, setStartDate] = useState(new Date().toLocaleString());
    const [endDate, setEndDate] = useState(new Date().toLocaleString());
    const [searchText, setSearchText] = useState('');

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            loadData(page);
        })();
    }, [page, rowsPerPage]);

    const loadData = async (page) => {
        setLoading(true);
        console.log(page);
        await api
            .get('api/rest/report/get-item-report?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    searchText: searchText
                }
            })
            .then((response) => {
                setCount(response.data.totalRecords);
                setOrders(response.data.data);
            })
            .catch((error) => {
                console.log(JSON.stringify(error.response));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const exportReport = async (page) => {
        setLoading(true);
        await api
            .get('api/rest/report/get-item-report', {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    searchText: searchText,
                    export: true
                }
            })
            .then((msg) => {
                var a = document.createElement('a');
                a.href = msg.data;
                a.download = 'ItemReport.xlsx';
                document.body.appendChild(a);
                a.click();
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const printReport = async (page) => {
        setLoading(true);
        await api
            .get('api/rest/report/get-item-report', {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    searchText: searchText,
                    print: true
                }
            })
            .then((msg) => {
                // var a = document.createElement("a");
                // a.href = msg.data;
                // a.download = "ItemReport.xlsx";
                // document.body.appendChild(a);
                // a.click();
                console.log(msg);
                window.open(msg.data, '_blank');

                // let objFra = document.createElement('iframe');     // Create an IFrame.
                // objFra.style.visibility = 'hidden';                // Hide the frame.
                // objFra.src = msg.data;                   // Set source.
                // document.body.appendChild(objFra);  // Add the frame to the web page.
                // objFra.contentWindow.focus();       // Set focus.
                // objFra.contentWindow.print();
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChangePage = async (newPage) => {
        //setPage(page + 1);
        console.log('page........');
        console.log('New Page : ' + newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        console.log('Rows per page changed !');
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions() {
        const handleFirstPageButtonClick = () => {
            setPage(0);
            loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <Box m="20px">
              <Header title="ITEM REPORT" subtitle="See items here" />

            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                {/* searchText */}
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    p={2}
                >
                    <TextField
                        type="date"
                        label="Start Date"
                        value={moment(startDate).format('YYYY-MM-DD')}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <TextField
                        type="date"
                        label="End Date"
                        value={moment(endDate).format('YYYY-MM-DD')}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <TextField 
                        type="text"
                        label="Search Text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Button color="primary" variant="contained" onClick={() => loadData(0)}>
                        Search
                    </Button>
                    <Button color="secondary" variant="contained" onClick={printReport}>
                        Print
                    </Button>
                    {loading && <RctSectionLoader />}
                </Stack>


              <Box
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    sx={{
                        '& .MuiTableHead-root': {
                            backgroundColor: colors.greenAccent[700]
                        },
                        '& .MuiTableRow-footer': {
                            backgroundColor: colors.redAccent[700]
                        }
                    }}
                >
                    <TableContainer component={Paper}> 
                        {orders && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <b>Item Category</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Item Name</b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>Quantity</b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>Rate</b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>Amount</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders &&
                                        orders.map((order, key) =>
                                            order.itemCategory != null ? (
                                                <TableRow key={key}>
                                                    <TableCell>#</TableCell>
                                                    <TableCell>{order.itemCategory}</TableCell>
                                                    <TableCell>{order.itemName}</TableCell>
                                                    <TableCell align="right">{order.qty}</TableCell>
                                                    <TableCell align="right">{order.rate}</TableCell>
                                                    <TableCell align="right">{order.amount}</TableCell>
                                                </TableRow>
                                            ) : null
                                        )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>#</TableCell>
                                        <TableCell>#</TableCell>
                                        <TableCell>#</TableCell>
                                        <TableCell align="right">
                                            <b>Total</b>
                                        </TableCell>
                                        <TableCell align="right">{orders[orders.length - 1].amount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TablePagination
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                            labelRowsPerPage={<span>Rows:</span>}
                                            labelDisplayedRows={({ page }) => {
                                                return `Page: ${page + 1}`;
                                            }}
                                            backIconButtonProps={{
                                                color: 'secondary'
                                            }}
                                            nextIconButtonProps={{ color: 'secondary' }}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'page number'
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                            ActionsComponent={TablePaginationActions} 
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        )}
                   </TableContainer> 
            </Box>
        </Box>
        </Box>
    );
};
export default ItemReport;
