import { useState, useEffect } from 'react';
 
import { Stack, Button, useTheme, Box, TableContainer, Paper, IconButton, TextField,Grid
,Typography } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';

var moment = require('moment');

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [todayBillCount, setTodayBillCount] = useState(0);
    const [todayTotalSale, setTodayTotalSale] = useState(0);
    const [yesterdayBillCount, setYesterdayBillCount] = useState(0);
    const [yesterdayTotalSale, setYesterdayTotalSale] = useState(0);

    const [sevendayBillCount, setSevendayBillCount] = useState(0);
    const [sevendayTotalSale, setSevendayTotalSale] = useState(0);

    const [thirtydayBillCount, setThirtydayBillCount] = useState(0);
    const [thirtydayTotalSale, setThirtydayTotalSale] = useState(0);

    const [ninetydayBillCount, setNinetydayBillCount] = useState(0);
    const [ninetydayTotalSale, setNinetydayTotalSale] = useState(0);

    const [orders, setOrders] = useState(null);

    const [startDate, setStartDate] = useState(new Date().toLocaleString());
    const [endDate, setEndDate] = useState(new Date().toLocaleString());
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);

    const [mopDatas, setMopDatas] = useState(undefined);

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await api
                .get('api/rest/report/get-sale-summary?valueFor=0')
                .then((response) => {
                    setTodayTotalSale(response.data[0].total);
                    setTodayBillCount(response.data[0].count);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();

        (async () => {
            await api
                .get('api/rest/report/get-sale-summary?valueFor=1')
                .then((response) => {
                    setYesterdayTotalSale(response.data[0].total);
                    setYesterdayBillCount(response.data[0].count);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();

        (async () => {
            await api
                .get('api/rest/report/get-sale-summary?valueFor=30')
                .then((response) => {
                    setThirtydayTotalSale(response.data[0].total);
                    setThirtydayBillCount(response.data[0].count);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();

        (async () => {
            await api
                .get('api/rest/report/get-sale-summary?valueFor=90')
                .then((response) => {
                    setNinetydayTotalSale(response.data[0].total);
                    setNinetydayBillCount(response.data[0].count);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();

        (async () => {
            await api
                .get('api/rest/report/get-sale-summary?valueFor=7')
                .then((response) => {
                    setSevendayTotalSale(response.data[0].total);
                    setSevendayBillCount(response.data[0].count);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();

        (async () => {
            loadData(1);
        })();

        (async () => {
            loadMOPData();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            loadData(page);
        })();
    }, [page, rowsPerPage]);

    const loadData = async () => {
        setLoading(true);
        await api
            .get('api/rest/report/get-billtax-report?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    searchText: searchText
                }
            })
            .then((response) => {
                setCount(response.data.totalRecords);
                setOrders(response.data.data);
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const exportReport = async () => {
        setLoading(true);
        await api
            .get('api/rest/report/get-billtax-report?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    searchText: searchText,
                    export: true
                }
            })
            .then((msg) => {
                console.log(msg);
                var a = document.createElement('a');
                a.href = msg.data;
                a.download = 'BillTaxRegister.xlsx';
                document.body.appendChild(a);
                a.click();
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const loadMOPData = async () => {
        setLoading(true);
        await api
            .get('api/rest/report/get-mop-report', {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
            .then((response) => {
                console.log(response.data);
                setMopDatas(response.data);
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChangePage = async (newPage) => {
        //setPage(page + 1);
        console.log('page........');
        console.log('New Page : ' + newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        console.log('Rows per page changed !');
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions() {
        const handleFirstPageButtonClick = () => {
            setPage(0);
            // loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            //loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            //loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            //loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <Box m="20px">
            {/* HEADER */}
            <Header title="BILL TAX REPORT" subtitle="See your bills" />

            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                {/* searchText */}
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1 }} 
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    p={2}
                >
                    <TextField
                        type="date"
                        label="Start Date"
                        value={moment(startDate).format('YYYY-MM-DD')}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <TextField
                        type="date"
                        label="End Date"
                        value={moment(endDate).format('YYYY-MM-DD')}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <TextField type="text" label="Search Text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                    <Button size="large" color="primary" variant="contained" onClick={() => loadData(0)}>
                        Search
                    </Button>
                    <Button color="success" variant="contained" onClick={exportReport}>
                        Export
                    </Button>
                    {loading && <RctSectionLoader />}
                </Stack>

                {/* Summary */}
                <Box gridColumn="span 12" backgroundColor={colors.primary[400]} p={1}>
                    <Grid container my={2}>
                        <Grid item md={2.4} xs={6}>
                            <Box bgcolor={'secondary.light'} p={1} mr={2} my={1} textAlign="center">
                                <Typography variant="h6">Today</Typography>
                                <Typography variant="subtitle1">
                                    {'\u20B9'}
                                    {todayTotalSale}
                                </Typography>
                                <Typography variant="subtitle2">{todayBillCount} Order(s)</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={2.4} xs={6}>
                            <Box bgcolor={'success.light'} p={1} mr={2} my={1} textAlign="center">
                                <Typography variant="h6">Yesterday</Typography>
                                <Typography variant="subtitle1">
                                    {'\u20B9'}
                                    {yesterdayTotalSale}
                                </Typography>
                                <Typography variant="subtitle1">{yesterdayBillCount} Order</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={2.4} xs={6}>
                            <Box bgcolor={'warning.light'} p={1} mr={2} my={1} textAlign="center">
                                <Typography variant="h6">Last 7 Days</Typography>
                                <Typography variant="subtitle1">
                                    {'\u20B9'}
                                    {sevendayTotalSale}
                                </Typography>
                                <Typography variant="subtitle1">{sevendayBillCount} Order</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={2.4} xs={6}>
                            <Box bgcolor={'info.light'} p={1} mr={2} my={1} textAlign="center">
                                <Typography variant="h6">Last 30 Days</Typography>
                                <Typography variant="subtitle1">
                                    {'\u20B9'}
                                    {thirtydayTotalSale}
                                </Typography>
                                <Typography variant="subtitle1">{thirtydayBillCount} Order</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={2.4} xs={6}>
                            <Box bgcolor={'error.light'} p={1} my={1} textAlign="center">
                                <Typography variant="h6">Last 90 Days</Typography>
                                <Typography variant="subtitle1">
                                    {'\u20B9'}
                                    {ninetydayTotalSale}
                                </Typography>
                                <Typography variant="subtitle1">{ninetydayBillCount} Order</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* tables */}
                <Box
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    sx={{
                        '& .MuiTableHead-root': {
                            backgroundColor: colors.greenAccent[700]
                        },
                        '& .MuiTableRow-footer': {
                            backgroundColor: colors.redAccent[700]
                        }
                    }}
                >
                    <TableContainer component={Paper}>
                        {orders && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Order Type</TableCell>
                                        <TableCell>Order No</TableCell>
                                        <TableCell>Invoice No</TableCell>
                                        <TableCell>Order date</TableCell>
                                        <TableCell align="right">Amount ({'\u20B9'})</TableCell>
                                        <TableCell align="right">Paid ({'\u20B9'})</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Customer</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders &&
                                        orders.map((order, key) =>
                                            order.orderType != null ? (
                                                <TableRow key={key}>
                                                    <TableCell>{order.orderType}</TableCell>
                                                    <TableCell>{order.orderNo}</TableCell>
                                                    <TableCell> {order.invoiceNo}</TableCell>
                                                    <TableCell>{moment(order.invoiceDate).format('DD-MMM-YYYY hh:mm a')}</TableCell>
                                                    <TableCell align="right">{order.netAmount}</TableCell>
                                                    <TableCell align="right">
                                                        {order.paid} - ({order.mop})
                                                    </TableCell>
                                                    <TableCell>
                                                        <span
                                                            className={
                                                                order.status == 'Cancel' ? 'badge badge-danger' : 'badge badge-primary'
                                                            }
                                                        >
                                                            {order.status}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{order.customerDetails}</span>
                                                    </TableCell>
                                                </TableRow>
                                            ) : null
                                        )}
                                </TableBody>
                                <TableFooter bgcolor={'info.light'}>
                                    <TableRow>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell align="right">Total : </TableCell>
                                        <TableCell align="right">{orders[orders.length - 1].netAmount}</TableCell>
                                        <TableCell align="right">{orders[orders.length - 1].paid}</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                    <TablePagination
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                        labelRowsPerPage={<span>Rows:</span>}
                        labelDisplayedRows={({ page }) => {
                            return `Page: ${page + 1}`;
                        }}
                        backIconButtonProps={{
                            color: 'secondary'
                        }}
                        nextIconButtonProps={{ color: 'secondary' }}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'page number'
                            }
                        }}
                        showFirstButton={true}
                        showLastButton={true}
                        ActionsComponent={TablePaginationActions}
                        component={Box}
                    />

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>User Name</TableCell>
                                    <TableCell>MOP</TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>Amount ({'\u20B9'})</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mopDatas &&
                                    mopDatas.map((order, key) => (
                                        <TableRow key={key}>
                                            <TableCell>{order.userName}</TableCell>
                                            <TableCell>{order.mopname}</TableCell>
                                            <TableCell align="right">{order.amount}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                            <TableFooter bgcolor={'info.light'}>
                                <TableRow>
                                    <TableCell>-</TableCell>
                                    <TableCell>-</TableCell>
                                    <TableCell align="right">
                                        {mopDatas && mopDatas.reduce((a, v) => (a = a + v.amount), 0)} ({'\u20B9'})
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
