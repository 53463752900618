import React, { useState } from 'react';
import Button from '@mui/material/Button';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
// page title bar
import Header from 'components/Header';
import api from 'api';
// intl messages
import { Grid, TextField, Box, Stack, useTheme } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';
const ChangePassword = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(false);
    const [pwdInfo, setPwdInfo] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const savePassword = async () => {
        if (pwdInfo.oldPassword.length === 0) {
            NotificationManager.error('Old password required !');
            return;
        }
        if (pwdInfo.newPassword.length === 0) {
            NotificationManager.error('New password required !');
            return;
        }
        if (pwdInfo.confirmPassword.length === 0) {
            NotificationManager.error('New confirm password required !');
            return;
        }
        setLoading(true);
        await api
            .post('api/master/login/change-password', JSON.stringify(pwdInfo))
            .then((response) => {
                NotificationManager.success('SUCCESS');
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error.response.data));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChangePwdInfo = (key, value) => {
        var arr = { ...pwdInfo };
        arr[key] = value;
        setPwdInfo((prevState) => arr);
    };

    return (
        <Box m="20px">
            <Grid item md={12}>
                {/* HEADER */}
                <Header title="CHANGE PASSWORD" subtitle="secure your account" />

                {loading && <RctSectionLoader />}
                <Grid md={6} xs={12} backgroundColor={colors.primary[400]}>
                    <Stack p={2} direction={'column'} spacing={2} borderRadius={2}>
                        <TextField
                            label="Old Password"
                            fullWidth
                            value={pwdInfo.oldPassword}
                            onChange={(e) => onChangePwdInfo('oldPassword', e.target.value)}
                            type="Password"
                            variant="standard"
                        />
                        <TextField
                            label="New Password"
                            fullWidth
                            value={pwdInfo.newPassword}
                            onChange={(e) => onChangePwdInfo('newPassword', e.target.value)}
                            type="Password"
                            variant="standard"
                        />
                        <TextField
                            label="New Confirm Password"
                            fullWidth
                            value={pwdInfo.confirmPassword}
                            onChange={(e) => onChangePwdInfo('confirmPassword', e.target.value)}
                            type="Password"
                            variant="standard"
                        />
                        <Button variant="contained" color="secondary" className="text-white btn-danger" onClick={savePassword}>
                            Change Password
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};
export default ChangePassword;
