import { useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ReportOffOutlined from '@mui/icons-material/ReportOffOutlined';
import ReportOutlined from '@mui/icons-material/ReportOutlined';
import ReportGmailerrorredOutlined from '@mui/icons-material/ReportGmailerrorredOutlined';
import logo from 'assets/img/site-logo.png';
import moment from 'moment';
import api from 'api';
import {
    CancelOutlined,
    CardMembershipOutlined,
    CategoryOutlined,
    ChairAltOutlined,
    DataArrayOutlined,
    InstallMobileOutlined,
    OnlinePredictionOutlined,
    PendingActionsOutlined,
    PrintOutlined,
    ProductionQuantityLimitsOutlined,
    QrCodeOutlined,
    ReportOffRounded,
    RoomOutlined,
    SettingsOutlined,
    SmsOutlined,
    SportsHockeyOutlined,
    StackedLineChartOutlined,
    VerifiedUserOutlined
} from '@mui/icons-material';
const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Tooltip title={title}>
            <MenuItem
                active={selected === title}
                style={{
                    color: colors.grey[100]
                }}
                onClick={() => setSelected(title)}
                icon={icon}
            >
                <Typography>{title}</Typography>
                <Link to={to} />
            </MenuItem>
        </Tooltip>
    );
};

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState('Dashboard');
    const [currentDate, setCurrentDate] = useState();
    const [branch, setBranch] = useState('');
    console.log(localStorage.getItem('permissions'));
    const permissions = localStorage.getItem('permissions') === 'undefined' ? [] : JSON.parse(localStorage.getItem('permissions'));

    console.log(permissions);

    useEffect(() => {
        getDate();
        getDefaultBranch();
    }, []);

    const getDefaultBranch = async () => {
        console.log('Loading branch default ....');
        await api
            .get('api/master/login/get-default-branch-name-user')
            .then((response) => {
                setBranch(response.data);
            })
            .catch((error) => {});
    };

    const getDate = async () => {
        console.log('Loading current date default ....');
        await api
            .get('api/rest/day-closing/get-billing-date')
            .then((response) => {
                if (response.status === 200) {
                    setCurrentDate(response.data.dateOfSale);
                    localStorage.setItem('DateOfSale', moment(response.data.dateOfSale).format('DD-MMM-yyyy'));
                }
            })
            .catch((error) => {});
    };

    return (
        <Box
            sx={{
                '& .pro-sidebar-inner': {
                    background: `${colors.primary[400]} !important`
                },
                '& .pro-icon-wrapper': {
                    backgroundColor: 'transparent !important'
                },
                '& .pro-inner-item': {
                    padding: '5px 35px 5px 20px !important'
                },
                '& .pro-inner-item:hover': {
                    color: '#868dfb !important'
                },
                '& .pro-menu-item.active': {
                    color: '#6870fa !important'
                },
                '& .pro-sidebar-label': {
                    color: `${colors.primary[100]} !important`
                }
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 20px 0',
                            color: colors.grey[100]
                        }}
                    >
                        {!isCollapsed && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                                <Typography variant="h3" color={colors.grey[100]}>
                                    <img src={logo} height={40} width={110} />
                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={`../../assets/user.png`}
                                    style={{ cursor: 'pointer', borderRadius: '50%' }}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{ m: '10px 0 0 0' }}>
                                    {localStorage.getItem('user_id')}
                                </Typography>
                                <Typography variant="h5" color={colors.greenAccent[500]}>
                                    {branch} - {localStorage.getItem('tenantID')} - {moment(currentDate).format('DD-MMM-yyyy')}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                        <Item title="Dashboard" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />

                        <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                            Pages
                        </Typography>
                        <SubMenu title="Transaction" icon={<ContactsOutlinedIcon />}>
                            {/* {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 1701) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 1702) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 1703) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="New Order"
                                        to="/app/new-order"
                                        icon={<OnlinePredictionOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )} */}

                            {/* <Item
                                title="Pending Order(s)"
                                to="/app/pending-order"
                                icon={<PendingActionsOutlined />}
                                selected={selected}
                                setSelected={setSelected}
                            /> */}

                            {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 1707) > -1 ||
                                localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Cancel Bill"
                                        to="/app/cancel-bill"
                                        icon={<CancelOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )}

                            {/* {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 325) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 326) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 327) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 328) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Purchase Entry"
                                        to="/app/purchase-entry-list"
                                        icon={<InstallMobileOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )} */}

                            {/* {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 337) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 338) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 339) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 340) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Stock Transfer"
                                        to="/app/stock-transfer-list"
                                        icon={<SportsHockeyOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )} */}
                        </SubMenu>

                        <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                            Config
                        </Typography>
                        <SubMenu title="Settings" icon={<SettingsOutlined />}>
                            {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 133) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 134) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 135) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 136) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Account Master"
                                        to="app/account-master-list"
                                        icon={<PersonOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )}

                            {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 109) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 110) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 111) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 112) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Category Master"
                                        to="app/category-master-list"
                                        icon={<CategoryOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )}

                            {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 121) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 122) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 123) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 124) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Item Master"
                                        to="app/item-master-list"
                                        icon={<ProductionQuantityLimitsOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )}

                            {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 149) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 150) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 151) > -1 ||
                                    permissions.findIndex((e) => e.permissionConst === 152) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Room Master"
                                        to="app/room-master-list"
                                        icon={<RoomOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )}

{permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 5005) > -1 || 
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                            <Item
                                title="Mop Master"
                                to="app/mop-master-list"
                                icon={<CardMembershipOutlined />}
                                selected={selected}
                                setSelected={setSelected}
                            />)}

                            {localStorage.getItem('user_id').toLowerCase() === 'admin' && (
                                <Item
                                    title="User Master"
                                    to="app/user-master-list"
                                    icon={<VerifiedUserOutlined />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            )}

                            {localStorage.getItem('user_id').toLowerCase() === 'admin' && (
                                <Item
                                    title="Settings"
                                    to="app/settings"
                                    icon={<SettingsOutlined />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            )}
                        </SubMenu>

                        <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                            Reports
                        </Typography>

                        <SubMenu title="Reports" icon={<BarChartOutlinedIcon />}>
                            {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 415) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Bill Tax Report"
                                        to="/app/bill-tax-report"
                                        icon={<ReportOffOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )}
                            {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 418) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Item Report"
                                        to="/app/item-report"
                                        icon={<StackedLineChartOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )}
                            {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 433) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Stock Report"
                                        to="/app/stock-report"
                                        icon={<ChairAltOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )}
                                 {/* {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 442) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Stock Transfer Report"
                                        to="/app/stock-transfer-report"
                                        icon={<ChairAltOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )} */}
                                  {permissions &&
                                (permissions.findIndex((e) => e.permissionConst === 451) > -1 ||
                                    localStorage.getItem('user_id').toLowerCase() === 'admin') && (
                                    <Item
                                        title="Brand-Category Register"
                                        to="/app/brand-category-register"
                                        icon={<ChairAltOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )}
                        </SubMenu>
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
