import { useState, useEffect } from 'react';
import { useTheme, TextField, Box, Button, Stack } from '@mui/material';

import api from 'api';
import Header from '../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';
import moment from 'moment';
const DayClosing = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentDate, setCurrentDate] = useState();
    const [startSubmit, setStartSubmit] = useState(false);

    useEffect(() => {
        getDate();
    }, []);

    const getDate = async () => {
        await api
            .get('api/rest/day-closing/get-billing-date')
            .then((response) => {
                if (response.status === 200) {
                    setCurrentDate(response.data.dateOfSale);
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
            });
    };

    const saveDate = async () => {
        setStartSubmit(true);
        const billData = {
            dateOfSale: currentDate
        };
        await api
            .post('api/rest/day-closing/set-billing-date', JSON.stringify(billData))
            .then((response) => {
                if (response.status === 200) {
                    getDate();
                    NotificationManager.success('SUCCESS');
                    setStartSubmit(false);
                } else {
                    NotificationManager.success(JSON.stringify(response));
                    setStartSubmit(false);
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
                setStartSubmit(false);
            });
    };

    return (
        <Box m="20px">
             <Header title="DAY CLOSING" subtitle="Billing date" /> 
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Box gridColumn="span 12" component={'p'}>
                    Current Billing Date : {moment(currentDate).format('DD-MMM-yyyy')}
                </Box>
                <Stack gridColumn="span 12"  direction={'row'} spacing={2} backgroundColor={colors.primary[400]} 
                    p={2}>
                    <TextField
                        type="date"
                        label="Date"
                        placeholder="dd/MM/yyyy"
                        value={moment(currentDate).format('YYYY-MM-DD')}
                        onChange={(e) => setCurrentDate(e.target.value)}
                    />
                    <Button color="secondary" onClick={saveDate} disabled={startSubmit} variant="contained">
                        {startSubmit ? 'Please wait...' : 'Set Date'}
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default DayClosing;
