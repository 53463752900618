import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import {useFetchCustomerMaster} from '../../hooks/useFetchData';
import api from 'api';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { CircularProgress } from '@mui/material';
import Box from "@mui/material/Box";

import { Autocomplete, TextField } from '@mui/material';

export default function SearchCustomer(props) { 
    const {data : customers , refetch : refetchCustomers} = useFetchCustomerMaster();
    const [modal, setModal] = useState(false);

    const initialCustomer = {
        customerName: '',
        address1: '',
        address2: '',
        mobile: '',
        email: '',
        gstNumber: ''
    };
    const [customer, setCustomer] = useState(initialCustomer);
    const [loading, setLoading] = useState(false);

    const toggle = () => {
        setModal(!modal);
        setCustomer(initialCustomer);
    }; 

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (customer.customerName === '') { return NotificationManager.error("CustomerName required !") };
        if (customer.mobile === '') { return NotificationManager.error("Mobile required !") };

        setLoading(true);
        await api.post('api/rest/kot-bill/insert-customer-details', JSON.stringify(customer))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Saved Successfully.");
                    refetchCustomers();
                }
                else {
                    NotificationManager.success(JSON.stringify(response.data));
                }
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
            }).finally(() => {
                setLoading(false);
            });
    };
 

 

    return (
        <>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Add New Customer
                </ModalHeader>
                <ModalBody>
                    <div className="container ">
                        <FormControl fullWidth>
                            <InputLabel htmlFor="name-simple">Customer name</InputLabel>
                            <Input
                                value={customer.customerName} onChange={(e) => setCustomer({ ...customer, customerName: e.target.value })}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="name-simple">Mobile</InputLabel>
                            <Input
                                type='number' value={customer.mobile} onChange={(e) => setCustomer({ ...customer, mobile: e.target.value })}
                                inputProps={{ maxLength: 10 }}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="name-simple">Email</InputLabel>
                            <Input id="name-simple" value={customer.email} onChange={(e) => setCustomer({ ...customer, email: e.target.value })} />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="name-simple">GST Number</InputLabel>
                            <Input id="name-simple" value={customer.gstNumber} onChange={(e) => setCustomer({ ...customer, gstNumber: e.target.value })} />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="name-simple">Address1</InputLabel>
                            <Input id="name-simple" value={customer.address1} onChange={(e) => setCustomer({ ...customer, address1: e.target.value })} />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="name-simple">Address2</InputLabel>
                            <Input id="name-simple" value={customer.address2} onChange={(e) => setCustomer({ ...customer, address2: e.target.value })} />
                        </FormControl>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            className="text-white bg-primary"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? 'Please wait...' : 'Save'}
                        </Button>
                        {loading && (
                            <CircularProgress
                                style={{ alignSelf: 'center' }}
                                color="secondary"
                            />
                        )}
                        <Button
                            variant="contained"
                            className="btn-danger text-white bg-danger"
                            onClick={toggle}
                        >
                            Cancel
                        </Button>

                    </>
                </ModalFooter>
            </Modal >
            <div className='row'>
                <div className='col-md-5'>
                    {customers && <Autocomplete
                        value={props.billDetails.customer}
                        options={customers.data}
                        sx={{ width: 300 }}
                        getOptionLabel={(option) => option.customerID + " " + option.customerName}
                        onChange={(e, v) => props.setBillDetails({ ...props.billDetails, customer: v })}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                                {option.customerID + " " + option.customerName}
                            </Box>
                        )}
                        renderInput={(params) => <TextField {...params} label="Customer" inputRef={props.customerInputRef} />}
                    />}
                </div>
                <div className='col-md-3'>
                    <Button color="default" onClick={toggle}>Add New Customer</Button>
                </div>
            </div>
        </>
    )
}
