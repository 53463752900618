import React, { useEffect, useState } from 'react'; 
import Box from '@mui/material/Box'; 
import { useTheme } from '@mui/material'; 
import { Outlet } from 'react-router';

import Topbar from './Topbar';
import Sidebar from './Sidebar'; 

function DashboardContent() { 
    const [isSidebar, setIsSidebar] = useState(true);

    return (
        <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
                <Topbar setIsSidebar={setIsSidebar} />
                <Box  height="89vh" overflow={'auto'}>
                <Outlet />
                </Box>                
            </main>
        </div>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}
