import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Switch, Button, MenuItem, TextField, Box, FormControlLabel, Typography, Stack, Divider, useTheme } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';

export default function AddNewItemForm(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state.id;

    const [addNewItemDetail, setAddNewItemDetail] = useState({
        roomName: '',
        anoRoomType: 0,
        anoFloor: 0,
        cgstid: 0,
        maxAdult: 1,
        maxChild: 1,
        phone: '',
        status: 'Active',
        rmno: 0
    });
    const [loading, setLoading] = useState(false);
    const [roomTypes, setRoomTypes] = useState(null);
    const [floors, setFloors] = useState(null);
    const [gsts, setGsts] = useState(null);

    const onChangeAddNewItemDetails = (key, value) => {
        var arr = { ...addNewItemDetail };
        arr[key] = value;
        setAddNewItemDetail((prevState) => arr);
    };

    const addNewItem = async () => {
        if (addNewItemDetail.roomName === '') {
            NotificationManager.error('Room Name Required !');
            return;
        } 
        setLoading(true);
        await api
            .post('api/master/hotel-room', JSON.stringify(addNewItemDetail))
            .then((response) => {
                NotificationManager.success('Room Added Successfully.');
                navigate('/app/room-master-list');
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error.response.data));
            })
            .finally(() => {
                setLoading(false);
            });
    }; 

    useEffect(() => {
        if (floors === null) {
            (async () => {
                await api
                    .get('api/master/hotel-room-floor/get-floor-list')
                    .then((response) => {
                        console.log(response.data);
                        setFloors(response.data);
                    })
                    .catch((error) => { 
                    });
            })();
        }

        if (roomTypes === null) {
            (async () => {
                await api
                    .get('api/master/hotel-room-type/get-room-type-list')
                    .then((response) => {
                        setRoomTypes(response.data);
                    })
                    .catch((error) => {
                    });
            })();
        }

        (async () => {
            await api
                .get('api/master/gst-master')
                .then((response) => {
                    setGsts(response.data);
                })
                .catch((error) => {
                });
        })();
 
    }, []); 

    const updateItem = async () => {
        if (addNewItemDetail.roomName === '') {
            NotificationManager.error('Room Name Required !');
            return;
        } 
        setLoading(true);
        await api
            .put('api/master/hotel-room', JSON.stringify(addNewItemDetail))
            .then((response) => { 
                    NotificationManager.success('Room Updated Successfully.');
                    navigate('/app/room-master-list');
            })
            .catch((error) => {             
            }).finally(()=>{
                setLoading(false);
            }); 
    };

    useEffect(() => {
        console.log()
        if (addNewItemDetail.rmno == 0 && id > 0) {
            (async () => {
                await api
                    .get('api/master/hotel-room/' + id)
                    .then((response) => {
                        console.log(response.data);
                        setAddNewItemDetail({ 

                            roomName: response.data.roomName,
                            anoRoomType: response.data.anoRoomType,
                            anoFloor: response.data.anoFloor,
                            cgstid: response.data.cgstid,
                            maxAdult: response.data.maxAdult,
                            maxChild: response.data.maxChild,
                            phone: response.data.phone,
                            status: response.data.status,
                            rmno: response.data.rmno
                        });
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error));
                    });
            })();
        }
    }, [id > 0]);


    return (
        <Box m="20px">
            <Header title="Room MASTER" subtitle="add/edit item" />
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                    {loading && <RctSectionLoader />}
                    <Link to={{ pathname: '/app/room-floor-master-list' }}>+</Link>
                    <TextField
                        label="Floor"
                        value={addNewItemDetail.anoFloor}
                        onChange={(e) => onChangeAddNewItemDetails('anoFloor', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select Floor
                        </MenuItem>
                        {floors &&
                            floors.map((floor) => (
                                <MenuItem key={floor.flrNo} value={floor.flrNo}>
                                    {floor.floorName}
                                </MenuItem>
                            ))}
                    </TextField>
                    <Link to={{ pathname: '/app/room-type-master-list' }}>+</Link>
                    <TextField
                        label="Room Type"
                        value={addNewItemDetail.anoRoomType}
                        onChange={(e) => onChangeAddNewItemDetails('anoRoomType', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select Room Type
                        </MenuItem>
                        {roomTypes &&
                            roomTypes.map((roomType) => (
                                <MenuItem key={roomType.btno} value={roomType.btno}>
                                    {roomType.roomType}
                                </MenuItem>
                            ))}
                    </TextField> 
                    <TextField
                        label="GST"
                        value={addNewItemDetail.cgstid}
                        onChange={(e) => onChangeAddNewItemDetails('cgstid', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>
                            Select GST
                        </MenuItem>
                        {gsts &&
                            gsts.map((gst) => (
                                <MenuItem key={gst.id} value={gst.id}>
                                    {gst.gstname}
                                </MenuItem>
                            ))}
                    </TextField>
                    <TextField 
                            type="text"
                            label="Enter Room Name/No."
                            value={addNewItemDetail.roomName}
                            onChange={(e) => onChangeAddNewItemDetails('roomName', e.target.value)}
                            variant="standard"
                        />
                         <TextField 
                            type="text"
                            label="Phone No."
                            value={addNewItemDetail.phone}
                            onChange={(e) => onChangeAddNewItemDetails('phone', e.target.value)}
                            variant="standard"
                        />
                           <TextField 
                            type="number"
                            label="Max Adult"
                            value={addNewItemDetail.maxAdult}
                            onChange={(e) => onChangeAddNewItemDetails('maxAdult', e.target.value)}
                            variant="standard"
                        /> 
                    <TextField 
                            type="number"
                            label="Max Child"
                            value={addNewItemDetail.maxChild}
                            onChange={(e) => onChangeAddNewItemDetails('maxChild', e.target.value)}
                            variant='standard'
                        />
                   <TextField
                        label="Select Status"
                        value={addNewItemDetail.status}
                        onChange={(e) => onChangeAddNewItemDetails('status', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem selected key={0} value="Active">
                            Active
                        </MenuItem>
                        <MenuItem key={1} value="InActive">
                            InActive
                        </MenuItem>
                    </TextField>
                    {id == 0 ? (
                        <Button variant="contained" color="secondary" onClick={() => addNewItem()}>
                            Add
                        </Button>
                    ) : (
                        <Button variant="contained" color="secondary" onClick={() => updateItem()}>
                            Update
                        </Button>
                    )}

                    {'  '}
                    <Button variant="contained" color="error" onClick={() => navigate('/app/room-master-list')}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}
