import { useState, useEffect } from 'react';
import { TextField, Box, Switch, MenuItem, Stack, useTheme, Button, FormControlLabel } from '@mui/material';

import api from 'api';
import Header from '../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import moment from 'moment';
export default function PrintQrCode(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tables, setTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState(0);

    useEffect(() => {
        getTableList();

        getImage(0);
    }, []);

    const getTableList = async () => {
        setLoading(true);
        await api
            .get('api/rest/kot/get-table-list')
            .then((response) => {
                if (response.status === 200) {
                    setTables(response.data);
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getImage = async (table) => {
        setSelectedTable(table);

        setLoading(true);
        await api
            .get('api/rest/kot/generate-qr-code?tableName=' + table)
            .then((response) => {
                if (response.status === 200) {
                    setImage(response.data);
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onDownloadImage = () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = image;
        downloadLink.download = 'QRCode_' + selectedTable + '_' + localStorage.getItem('tenantID');
        downloadLink.click();
    };

    return (
        <Box m="20px">
            <Header title="Download QR Code" subtitle="Qr code will help to see munu" />
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                {loading && <RctSectionLoader />}
                <Box backgroundColor={colors.primary[400]} gridColumn="span 12" p={2}>
                    <TextField
                        label="Select Table"
                        value={selectedTable}
                        onChange={(e) => getImage(e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        {tables &&
                            tables.map((table) => (
                                <MenuItem key={table.tableName} value={table.tableName}>
                                    {table.tableName}
                                </MenuItem>
                            ))}
                    </TextField>
                </Box>
                <Stack direction={'column'} backgroundColor={colors.primary[400]} gridColumn="span 12" p={2}
                 spacing={2} alignItems='center'
                >
                    <img src={image} height={300} width={300} alt="Select table to get image" />
                    <Button variant="contained" color="secondary" onClick={onDownloadImage}>
                        Download
                    </Button>
                </Stack>
                
            </Box>
        </Box>
    );
}
