import { useState, useEffect, Fragment } from 'react';
import { Button, Stack, Checkbox, Box, useTheme, TextField } from '@mui/material';

import '@trendmicro/react-paginations/dist/react-paginations.css';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableContainer } from '@mui/material';

import RctSectionLoader from 'component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from 'components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';
export default function PriceManagement() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [groupID, setGroupID] = useState(0);
    const [items, setItems] = useState([
        {
            isChecked: false,
            permissionName: 'Take Away Sale',
            permissionValue: 1702
        },
        {
            isChecked: false,
            permissionName: 'Home Delivery Sale',
            permissionValue: 1703
        },
        {
            isChecked: false,
            permissionName: 'Table Sale',
            permissionValue: 1701
        },
        {
            isChecked: false,
            permissionName: 'Cancel Bill',
            permissionValue: 1706
        },
        {
            isChecked: false,
            permissionName: 'Duplicate Print',
            permissionValue: 1705
        },
        {
            isChecked: false,
            permissionName: 'Purchase Entry [Create]',
            permissionValue: 325
        },
        {
            isChecked: false,
            permissionName: 'Purchase Entry [Modify]',
            permissionValue: 326
        },
        {
            isChecked: false,
            permissionName: 'Purchase Entry[Delete]',
            permissionValue: 327
        },
        {
            isChecked: false,
            permissionName: 'Purchase Entry [View]',
            permissionValue: 328
        },
        {
            isChecked: false,
            permissionName: 'Stock Transfer [Create]',
            permissionValue: 337
        },
        {
            isChecked: false,
            permissionName: 'Stock Transfer [Modify]',
            permissionValue: 338
        },
        {
            isChecked: false,
            permissionName: 'Stock Transfer [Delete]',
            permissionValue: 339
        },
        {
            isChecked: false,
            permissionName: 'Stock Transfer [View]',
            permissionValue: 340
        },
        {
            isChecked: false,
            permissionName: 'Account Master [Create]',
            permissionValue: 133
        },
        {
            isChecked: false,
            permissionName: 'Account Master [Modify]',
            permissionValue: 134
        },
        {
            isChecked: false,
            permissionName: 'Account Master [Delete]',
            permissionValue: 135
        },
        {
            isChecked: false,
            permissionName: 'Account Master [View]',
            permissionValue: 136
        },
        {
            isChecked: false,
            permissionName: 'Item Category Master [Create]',
            permissionValue: 109
        },
        {
            isChecked: false,
            permissionName: 'Item Category Master [Modify]',
            permissionValue: 110
        },
        {
            isChecked: false,
            permissionName: 'Item Category Master [Delete]',
            permissionValue: 111
        },
        {
            isChecked: false,
            permissionName: 'Item Category Master [View]',
            permissionValue: 112
        },
        {
            isChecked: false,
            permissionName: 'Item Master [Create]',
            permissionValue: 121
        },
        {
            isChecked: false,
            permissionName: 'Item Master [Modify]',
            permissionValue: 122
        },
        {
            isChecked: false,
            permissionName: 'Item Master [Delete]',
            permissionValue: 123
        },
        {
            isChecked: false,
            permissionName: 'Item Master [View]',
            permissionValue: 124
        },
        {
            isChecked: false,
            permissionName: 'Room Master [Create]',
            permissionValue: 149
        },
        {
            isChecked: false,
            permissionName: 'Room Master [Modify]',
            permissionValue: 150
        },
        {
            isChecked: false,
            permissionName: 'Room Master [Delete]',
            permissionValue: 151
        },
        {
            isChecked: false,
            permissionName: 'Room Master [View]',
            permissionValue: 152
        },
        {
            isChecked: false,
            permissionName: 'MOP Master [Create]',
            permissionValue: 5005
        },
        {
            isChecked: false,
            permissionName: 'Bill Tax Report [View]',
            permissionValue: 415
        },
        {
            isChecked: false,
            permissionName: 'Item Report [View]',
            permissionValue: 418
        },
        {
            isChecked: false,
            permissionName: 'Stock Report [View]',
            permissionValue: 433
        },
        {
            isChecked: false,
            permissionName: 'Stock Transfer Register [View]',
            permissionValue: 442
        },
        {
            isChecked: false,
            permissionName: 'Brand Category Register [View]',
            permissionValue: 451
        }
    ]);

    useEffect(() => {
        if (groups.length === 0) {
            (async () => {
                setLoading(true);
                await api
                    .get('api/master/permission-group')
                    .then((response) => {
                        setGroups(response.data.data);
                    })
                    .catch((error) => {})
                    .finally(() => {
                        setLoading(false);
                    });
            })();
        }
    }, []);

    useEffect(() => {
        if (groupID > 0) {
            (async () => {
                setLoading(true);
                await api
                    .get('api/settings/permission-settings?pgroupId=' + groupID)
                    .then((response) => {
                        var data = response.data;
                        console.log(data);
                        var itemsTemp = [...items];
                        itemsTemp.map((item, key) => {
                            let index = data.findIndex((e) => e.permissionConst === item.permissionValue);
                            if (index === -1) {
                                item.isChecked = false;
                            } else {
                                item.isChecked = true;
                            }
                        });

                        setItems(itemsTemp);
                    })
                    .catch((error) => {})
                    .finally(() => {
                        setLoading(false);
                    });
            })();
        }
    }, [groupID]);

    const onChangePermissionValue = (checked, pv) => {
        var tempItems = [...items];
        var index = tempItems.findIndex((e) => e.permissionValue === pv);
        if (index !== -1) {
            tempItems[index].isChecked = checked;
        }
        setItems(tempItems);
    };

    const updatePermission = async (e) => {
        var values = new Array();
        items.map((item, key) => {
            if (item.isChecked) {
                var pval = {};
                pval.value = item.permissionValue;
                values.push(pval);
            }
        });

        var data = {
            permissionGroupId: groupID,
            values: values
        };
        console.log(JSON.stringify(data));
        setLoading(true);
        await api
            .post('api/settings/permission-settings', JSON.stringify(data))
            .then((response) => {
                NotificationManager.success('Saved Successfully.');
            })
            .catch((error) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Permission Settings" subtitle="set user group wise permission" />
            </Box>

            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                    <TextField
                        label="Group"
                        value={groupID}
                        onChange={(e) => setGroupID(e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem value="0">---Select Group---</MenuItem>
                        {groups &&
                            groups.map((branch) => (
                                <MenuItem key={branch.id} value={branch.id}>
                                    {branch.groupName}
                                </MenuItem>
                            ))}
                    </TextField>
                    <Button variant="contained" color="secondary" onClick={() => updatePermission()}>
                        Update Permision
                    </Button>

                    {loading && <RctSectionLoader />}

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow hover>
                                    <TableCell></TableCell>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items &&
                                    items.map((item, key) => (
                                        <TableRow key={item.permissionValue}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={item.isChecked}
                                                    onChange={(e) => onChangePermissionValue(e.target.checked, item.permissionValue)}
                                                />
                                            </TableCell>
                                            <TableCell>{item.permissionName}</TableCell>
                                            <TableCell>{item.permissionValue}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>
        </Box>
    );
}
