import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Switch, Button, MenuItem, TextField, Box, FormControlLabel, Typography, Stack, Divider, useTheme } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';

const AddNewItemForm = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state.id;

    console.log(id);

    const [addNewItemDetail, setAddNewItemDetail] = useState({
        brdGrpNo: 0,
        brandGrp: '',
        status: 'Active'
    });
    const [loading, setLoading] = useState(false);

    const onChangeAddNewItemDetails = (key, value) => {
        var arr = { ...addNewItemDetail };
        arr[key] = value;
        setAddNewItemDetail((prevState) => arr);
    };

    const addNewItem = async () => {
        if (addNewItemDetail.brandGrp === '') {
            NotificationManager.error('Brand Required !');
            return;
        }
        //console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api
            .post('api/master/product-brand', JSON.stringify(addNewItemDetail))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Brand Added Successfully.');

                    navigate('/app/brand-master-list');
                } else {
                    NotificationManager.error(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateItem = async () => {
        if (addNewItemDetail.brandGrp === '') {
            NotificationManager.error('Brand Required !');
            return;
        } 
        setLoading(true);
        await api
            .put('api/master/product-brand?id=' + id, JSON.stringify(addNewItemDetail))
            .then((response) => { 
                    NotificationManager.success('Brand Updated Successfully.');
                    navigate('/app/brand-master-list');               
            })
            .catch((error) => {  
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (addNewItemDetail.brdGrpNo == 0 && id > 0) {
            (async () => {
                await api
                    .get('api/master/product-brand/' + id)
                    .then((response) => {
                        console.log(response.data);
                        setAddNewItemDetail({
                            brdGrpNo: response.data.brdGrpNo,
                            brandGrp: response.data.brandGrp, 
                            status: response.data.status
                        });
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error));
                    });
            })();
        }
    }, [id > 0]);

    return (
        <Box m="20px">
            <Header title="BRAND MASTER" subtitle="add/edit brand" />
            {loading && <RctSectionLoader />}
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                                        <TextField
                        type="text"
                        label="Enter brand"
                        value={addNewItemDetail.brandGrp}
                        onChange={(e) => onChangeAddNewItemDetails('brandGrp', e.target.value)}
                        variant="standard"
                    />

                    <TextField
                        select
                        fullWidth
                        label="Status"
                        variant="standard"
                        value={addNewItemDetail.status}
                        onChange={(e) => onChangeAddNewItemDetails('status', e.target.value)}
                    >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="InActive">InActive</MenuItem>
                    </TextField>
                    {id == 0 ? (
                        <Button variant="contained" color="secondary" onClick={() => addNewItem()}>
                            Add
                        </Button>
                    ) : (
                        <Button variant="contained" color="secondary" onClick={() => updateItem()}>
                            Update
                        </Button>
                    )}

                    {'  '}
                    <Button variant="contained" color="error" onClick={() => navigate('/app/brand-master-list')}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default AddNewItemForm;
