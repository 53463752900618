import  { useState, useEffect, useRef } from 'react';
import { Box, TableContainer, useTheme, MenuItem, TextField, Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import '@trendmicro/react-paginations/dist/react-paginations.css';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import TableFooter from '@mui/material/TableFooter';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import DeleteConfirmationDialog from '../../../component/DeleteConfirmationDialog/DeleteConfirmationDialog';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme'; 
import moment from 'moment';
const BrandMaster = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]); 
    const [selectedItem, setSelectedItem] = useState(null); 
    const deleteConfirmationDialog = useRef(null);
    const [searchBy, setSearchBy] = useState({
        status: 'Active',
        searchText: ''
    });

    useEffect(() => {
        if (items.length === 0) {
            loadItems();
        }
    }, []);

    useEffect(() => {
        (async () => {
            loadItems(page);
        })();
    }, [page, rowsPerPage]);

    const loadItems = async () => {
        (async () => {
            await api.get('/api/master/product-brand?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: searchBy
                })
            .then((response) => {
                setCount(response.data.totalRecords);
                    setItems(response.data.data);
                })
                .catch(error => {
                })

        })();
    }

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    }

    const deleteItemPermanently = async () => {
        setLoading(true);
        deleteConfirmationDialog.current.close();
        await api.delete('api/master/product-brand?id=' + selectedItem.brdGrpNo)
            .then(async (response) => {
                await loadItems();
            })
            .catch(error => { 
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleChangePage = async (newPage) => {
        //setPage(page + 1);
        console.log('page........');
        console.log('New Page : ' + newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        console.log('Rows per page changed !');
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions() {
        const handleFirstPageButtonClick = () => {
            setPage(0);
            // loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            //loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            //loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            //loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }
    return (
        <Box m="20px">
        <Header title="BRAND MANAGEMENT" subtitle="manage item brand" />
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
            <Stack direction={'row'} spacing={2} justifyContent="flex-end" gridColumn="span 12">
                <Link to="/app/brand-master" state={{ id: 0, items: items }}>
                    Add New Brand
                </Link>
            </Stack>

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 1, md: 1 }}
                gridColumn="span 12"
                backgroundColor={colors.primary[400]}
                p={2}
            > 
              <TextField
                        label="Search Text" 
                        value={searchBy.searchText}
                        onChange={(e) => setSearchBy({ ...searchBy, searchText: e.target.value })}
                        variant="standard"
                        fullWidth
                    />
                    <TextField
                        label="Select Status"
                        value={searchBy.status}
                        onChange={(e) => setSearchBy({ ...searchBy, status: e.target.value })}                        
                        select 
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem selected key={0} value="Active">
                            Active
                        </MenuItem>
                        <MenuItem key={1} value="InActive">
                            InActive
                        </MenuItem>
                    </TextField>
                <Button disabled={loading} onClick={(e) => onReload(e)} color="secondary">
                    Search
                </Button>
                {loading && <RctSectionLoader />}
            </Stack>

            <Box
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    sx={{
                        '& .MuiTableHead-root': {
                            backgroundColor: colors.greenAccent[700]
                        },
                        '& .MuiTableRow-footer': {
                            backgroundColor: colors.redAccent[700]
                        }
                    }}
                >
                    <TableContainer>
                        {items && <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Brand</b></TableCell>
                                    <TableCell><b>Status</b></TableCell>
                                    <TableCell><b>Actions</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items && items.map((item, key) => (

                                    <TableRow key={key}>
                                        <TableCell>
                                            <div className="media">
                                                <div className="media-left mr-15">
                                                    {item.brandGrp}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell>{item.status}</TableCell>
                                        <TableCell>
                                        <Link to="/app/brand-master" state={{ id: item.brdGrpNo }}>
                                            <IconButton 
                                                aria-label="Edit"
                                                size='small'
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            </Link>
                                            <IconButton
                                                onClick={() => onDelete(item)}
                                                aria-label="Delete"
                                                size='small'
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                            <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                            labelRowsPerPage={<span>Rows:</span>}
                                            labelDisplayedRows={({ page }) => {
                                                return `Page: ${page + 1}`;
                                            }}
                                            backIconButtonProps={{
                                                color: 'secondary'
                                            }}
                                            nextIconButtonProps={{ color: 'secondary' }}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'page number'
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                        </Table>}

                    </TableContainer>
                </Box> 
            </Box> 
            <DeleteConfirmationDialog
                ref={deleteConfirmationDialog}
                title="Are You Sure Want To Delete?"
                message="This will delete permanently."
                onConfirm={() => deleteItemPermanently()}
            /> 
        </Box>
    );
}

export default BrandMaster;