import { useState, useEffect } from 'react';
import { TextField, Box, Switch, MenuItem, Stack, useTheme, Button, FormControlLabel } from '@mui/material';

import api from 'api';
import Header from '../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';
import moment from 'moment';
export default function PrintInfo(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [printInfo, setPrintInfo] = useState({
        branchId: 0,
        documentFor: 'Restaurant',
        billHeading: '',
        compName: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        address5: '',
        note1: '',
        printKot: false,
        printBill: false,
        billCopy: 0,
        kotcopy: 0,
        partyId: 0,
        defaultPartyAcGroup: 0,
        getItemFromBranchId: 0,
        defaultSaleWarehouseId: 0
    });
    const [startSubmit, setStartSubmit] = useState(false);
    const [branches, setBranches] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [accountGroups, setAccountGroups] = useState([]);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        getBranchList();
        getWarehouseList();
        getAccountGroupList();
        getAccountList();
    }, []);

    const getBranchList = async () => {
        await api
            .get('api/master/branch')
            .then((response) => {
                if (response.status === 200) {
                    setBranches(response.data);
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
            });
    };

    const getWarehouseList = async () => {
        await api
            .get('api/master/warehouse')
            .then((response) => {
                if (response.status === 200) {
                    setWarehouses(response.data);
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
            });
    };

    const getAccountGroupList = async () => {
        await api
            .get('api/master/account-group')
            .then((response) => {
                if (response.status === 200) {
                    setAccountGroups(response.data);
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
            });
    };

    const getAccountList = async () => {
        await api
            .get('api/master/account/get-account-list')
            .then((response) => { 
                    console.log(response.data);
                    setAccounts(response.data); 
            })
            .catch((error) => { 
            });
    };

    const getPrintInfo = async (lbranchID) => {
        await api
            .get('api/settings/get-print-info?documentFor=Restaurant&BranchID=' + lbranchID)
            .then((response) => {
                if (response.status === 200) {
                    let localPrintInfo = { ...printInfo };
                    localPrintInfo = response.data;
                    setPrintInfo(localPrintInfo);
                } else {
                    var arr = { ...printInfo };
                    arr['branchId'] = lbranchID;
                    arr['billHeading'] = '';
                    arr['compName'] = '';
                    arr['address1'] = '';
                    arr['address2'] = '';
                    arr['address3'] = '';
                    arr['address4'] = '';
                    arr['address5'] = '';
                    arr['note1'] = '';
                    arr['printKot'] = false;
                    arr['printBill'] = false;
                    arr['billCopy'] = 0;
                    arr['kotcopy'] = 0;
                    arr['partyId'] = 0;
                    arr['defaultPartyAcGroup'] = 0;
                    arr['getItemFromBranchId'] = 0;
                    arr['defaultSaleWarehouseId'] = 0;
                    setPrintInfo((prevState) => arr);
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
            });
    };

    const savePrintInfo = async () => {
        setStartSubmit(true);
        console.log(JSON.stringify(printInfo));
        await api
            .post('api/settings/set-print-info', JSON.stringify(printInfo))
            .then((response) => {
                if (response.status === 200) {
                    getPrintInfo(printInfo.branchId);
                    NotificationManager.success('SUCCESS');
                    setStartSubmit(false);
                } else {
                    NotificationManager.success(JSON.stringify(response));
                    setStartSubmit(false);
                }
            })
            .catch((error) => {
                NotificationManager.error(error.message);
                setStartSubmit(false);
            });
    };

    const onChangePrintInfo = (key, value) => {
        var arr = { ...printInfo };
        arr[key] = value;
        setPrintInfo((prevState) => arr);
        if (key === 'branchId') {
            getPrintInfo(value);
        }
    };

    return (
        <Box m="20px">
            <Header title="Print Settings" subtitle="Set print header and footer here" />
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Box backgroundColor={colors.primary[400]} gridColumn="span 12" p={2}>
                    <TextField
                        label="Select Branch"
                        value={printInfo.branchId}
                        onChange={(e) => onChangePrintInfo('branchId', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                         <MenuItem key={0} value={0}>---Select Branch---</MenuItem>
                        {branches &&
                            branches.map((branch) => (
                                <MenuItem key={branch.id} value={branch.id}>
                                    {branch.branchCode}
                                </MenuItem>
                            ))}
                    </TextField>
                </Box>

                <Stack gridColumn="span 12" direction={'column'} spacing={2} backgroundColor={colors.primary[400]} p={2}>
                    <TextField
                        label="Bill Heading"
                        fullWidth
                        value={printInfo.billHeading}
                        onChange={(e) => onChangePrintInfo('billHeading', e.target.value)}
                        variant='standard'
                    />
                    <TextField
                        label="Restaurant Name"
                        fullWidth
                        value={printInfo.compName}
                        onChange={(e) => onChangePrintInfo('compName', e.target.value)}
                        variant='standard'
                    />
                    <TextField
                        label="Address1"
                        fullWidth
                        value={printInfo.address1}
                        onChange={(e) => onChangePrintInfo('address1', e.target.value)}
                        variant='standard'
                    />
                    <TextField
                        label="Address2"
                        fullWidth
                        value={printInfo.address2}
                        onChange={(e) => onChangePrintInfo('address2', e.target.value)}
                        variant='standard'
                    />
                    <TextField
                        label="Address3"
                        fullWidth
                        value={printInfo.address3}
                        onChange={(e) => onChangePrintInfo('address3', e.target.value)}
                        variant='standard'
                    />
                    <TextField
                        label="Address4"
                        fullWidth
                        value={printInfo.address4}
                        onChange={(e) => onChangePrintInfo('address4', e.target.value)}
                        variant='standard'
                    />
                    <TextField
                        label="Address5"
                        fullWidth
                        value={printInfo.address5}
                        onChange={(e) => onChangePrintInfo('address5', e.target.value)}
                        variant='standard'
                    />
                    <TextField
                        label="Note"
                        fullWidth
                        multiline
                        value={printInfo.note1}
                        onChange={(e) => onChangePrintInfo('note1', e.target.value)}
                        variant='standard'
                    />

                    <TextField
                        label="Get Item From Branch"
                        value={printInfo.getItemFromBranchId}
                        onChange={(e) => onChangePrintInfo('getItemFromBranchId', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem key={0} value={0}>---select branch---</MenuItem>
                        {branches &&
                            branches.map((branch) => (
                                <MenuItem key={branch.id} value={branch.id}>
                                    {branch.branchCode}
                                </MenuItem>
                            ))}
                    </TextField>
                    <TextField
                        label="Default Sale Warehouse"
                        value={printInfo.defaultSaleWarehouseId}
                        onChange={(e) => onChangePrintInfo('defaultSaleWarehouseId', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                         <MenuItem key={0} value={0}>---select warehouse---</MenuItem>
                        {warehouses &&
                            warehouses.map((warehouse) => (
                                <MenuItem key={warehouse.id} value={warehouse.id}>
                                    {warehouse.wname}
                                </MenuItem>
                            ))}
                    </TextField>
                    <TextField
                        label="Default A/c Group"
                        value={printInfo.defaultPartyAcGroup}
                        onChange={(e) => onChangePrintInfo('defaultPartyAcGroup', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                         <MenuItem key={0} value={0}>---select group---</MenuItem>
                        {accountGroups &&
                            accountGroups.map((accountGroup) => (
                                <MenuItem key={accountGroup.no} value={accountGroup.no}>
                                    {accountGroup.groupName}
                                </MenuItem>
                            ))}
                    </TextField>
                    <TextField
                        label="Default Part"
                        value={printInfo.partyId}
                        onChange={(e) => onChangePrintInfo('partyId', e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                         <MenuItem key={0} value={0}>---select default party---</MenuItem>
                        {accounts &&
                            accounts.map((accountGroup) => (
                                <MenuItem key={accountGroup.id} value={accountGroup.id}>
                                    {accountGroup.accountName}
                                </MenuItem>
                            ))}
                    </TextField>
                    <FormControlLabel
                        label="Print KOT"
                        control={
                            <Switch checked={printInfo.printKot} onChange={(e) => onChangePrintInfo('printKot', !printInfo.printKot)} />
                        }
                    />
                    <FormControlLabel
                        label="Print Bill"
                        control={
                            <Switch checked={printInfo.printBill} onChange={(e) => onChangePrintInfo('printBill', !printInfo.printBill)} />
                        }
                    />
                    <TextField
                        label="KOT Copy" 
                        type="number"
                        variant='standard'
                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                        value={printInfo.kotcopy}
                        onChange={(e) => onChangePrintInfo('kotcopy', e.target.value)}
                    />
                    <TextField
                        label="Bill Copy"
                        type="number"
                        variant='standard'
                        inputProps={{ min: 0, style: { textAlign: 'center' } }} // the change is here
                        value={printInfo.billCopy}
                        onChange={(e) => onChangePrintInfo('billCopy', e.target.value)}
                    />
                    <Button variant="contained" color="secondary" onClick={savePrintInfo} disabled={startSubmit}>
                        {startSubmit ? 'Please wait...' : 'Save'}
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}
