import  { useState, useEffect, useRef } from 'react';
import { Box, TableContainer, useTheme } from '@mui/material'; 
import { Link } from 'react-router-dom';

import '@trendmicro/react-paginations/dist/react-paginations.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import DeleteConfirmationDialog from '../../../component/DeleteConfirmationDialog/DeleteConfirmationDialog';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from 'components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme'; 
import { RefreshOutlined } from '@mui/icons-material'; 
import moment from 'moment'; 

export default function CategoryMaster(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const deleteConfirmationDialog = useRef(null);

    useEffect(() => {
        if (items.length === 0) {
            loadItems();
        }
    }, [items]);

    const loadItems = async () => {
        (async () => {
            await api.get('api/master/product-department')
                .then((response) => {
                    console.log(response.data);
                    setItems(response.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });
        })();
    }

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    }

    const deleteItemPermanently = async () => {
        deleteConfirmationDialog.current.close();
        setLoading(true);
        await api.delete('api/master/product-department?id=' + selectedItem.id)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Deleted!');
                    loadItems();
                }
                else {
                    NotificationManager.error(JSON.stringify(response.message.data));
                    console.log(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.response.data));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
          <Box m="20px">
            {/* HEADER */}
            <Header title="CATEGORY MANAGEMENT" subtitle="manage item category" />
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
            <Box gridColumn="span 6" p={1} >
                    <IconButton onClick={(e) => onReload(e)}>
                        <RefreshOutlined />
                    </IconButton> 
                    {loading && <RctSectionLoader />}
                </Box>
                <Box gridColumn="span 6" p={1}
                 justifyContent="flex-end" alignItems={'flex-end'} textAlign="right">
                    <Link to="/app/category-master" state={{ id: 0, items: items }}>
                        Add New Category
                    </Link>
                </Box> 
             
            <Box gridColumn="span 12" backgroundColor={colors.primary[400]} p={1}>
                    <TableContainer>
                    {items && <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Department</b></TableCell>
                                <TableCell><b>Is Published?</b></TableCell>
                                <TableCell><b>IncludeInMenu?</b></TableCell>
                                <TableCell><b>Image</b></TableCell>
                                <TableCell><b>Status</b></TableCell>
                                <TableCell><b>Actions</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items && items.map((item, key) => (

                                <TableRow key={key}>
                                    <TableCell>
                                        <div className="media">
                                            <div className="media-left mr-15">
                                                {item.name}
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{item.isPublished ? "Yes" : "No"}</TableCell>
                                    <TableCell>{item.includeInMenu ? "Yes" : "No"}</TableCell>
                                    <TableCell><img src={item.imageUrl} alt="" height={100} width={100} /></TableCell>
                                    <TableCell>
                                        <span className={item.status == "InActive" ? "badge badge-danger" : "badge badge-primary"}>{item.status}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <Link to='/app/category-master' state={{ id: item.id, items: items }}>
                                            <IconButton
                                                aria-label="Edit"
                                                size='small'
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Link>

                                        <IconButton
                                            onClick={() => onDelete(item)}
                                            aria-label="Delete"
                                            size='small'
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                            )}
                        </TableBody>
                    </Table>}
                </TableContainer>
            </Box>


            <DeleteConfirmationDialog
                ref={deleteConfirmationDialog}
                title="Are You Sure Want To Delete?"
                message="This will delete permanently."
                onConfirm={() => deleteItemPermanently()}
            /> 
        </Box>
        </Box>
    );
}
