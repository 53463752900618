import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Switch, Button, MenuItem, TextField, Box, Select, Typography, Stack, InputLabel, useTheme } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';
export default function AddNewItemForm() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState(null);

    const id = location.state.id;

    const [addNewItemDetail, setAddNewItemDetail] = useState({
        id: 0,
        mopName: '',
        mopType: 'Cash',
        accountID: 0,
        status: 'Active'
    });

    const [loading, setLoading] = useState(false);

    const addNewItem = async () => {
        if (addNewItemDetail.mopName === '') {
            NotificationManager.error('MopName Required !');
            return;
        }
        setLoading(true);
        console.log(JSON.stringify(addNewItemDetail));
        await api
            .post('api/master/mop', JSON.stringify(addNewItemDetail))
            .then((response) => {
                console.log(response.data);
                NotificationManager.success('Added Successfully.');
                navigate('/app/mop-master-list');
            })
            .catch((error) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (addNewItemDetail.id == 0 && id > 0) {
            (async () => {
                setLoading(true);
                await api
                    .get('api/master/mop/' + id)
                    .then((response) => {
                        console.log(response.data);

                        setAddNewItemDetail({
                            ...addNewItemDetail,
                            id: response.data.id,
                            mopName: response.data.mopname,
                            mopType: response.data.moptype,
                            accountID: response.data.accountId,
                            status: response.data.status
                        });
                    })
                    .catch((error) => {})
                    .finally(() => {
                        setLoading(false);
                    });
            })();
        }
    }, [id > 0]);

    useEffect(() => {
        if (accounts === null) {
            (async () => {
                await api
                    .get('api/master/account/get-account-list-for-mop')
                    .then((response) => {
                        console.log(response.data);
                        setAccounts(response.data);
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error.response.data));
                    });
            })();
        }
    });

    const updateItem = async () => {
        if (addNewItemDetail.mopName === '') {
            NotificationManager.error('MopName Required !');
            return;
        }

        setLoading(true);
        await api
            .put('api/master/mop?id=' + id, JSON.stringify(addNewItemDetail))
            .then((response) => {
                NotificationManager.success('Updated Successfully.');
                navigate('/app/mop-master-list');
            })
            .catch((error) => {
                NotificationManager.error(JSON.stringify(error.response.data));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="ITEM MASTER" subtitle="add/edit item" />
            </Box> 
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>
                    {loading && <RctSectionLoader />}
                    <TextField
                        type="text"
                        label="MOP Name"
                        value={addNewItemDetail.mopName}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, mopName: e.target.value })}
                        style={{ width: '100%', marginBottom: 15 }}
                        variant="standard"
                        autoFocus={true}
                    />
                    <InputLabel id="demo-simple-select-standard-label">MOP Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        value={addNewItemDetail.mopType}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, mopType: e.target.value })} 
                        variant="standard"
                    >
                        <MenuItem selected key={0} value="Cash">
                            Cash
                        </MenuItem>
                        <MenuItem key={1} value="Credit Card">
                            Credit Card
                        </MenuItem>
                        <MenuItem key={1} value="Credit Sale">
                            Credit Sale
                        </MenuItem>
                        <MenuItem key={1} value="CRM Point Redeem">
                            CRM Point Redeem
                        </MenuItem>
                    </Select>

                    <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        value={addNewItemDetail.accountID}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, accountID: e.target.value })} 
                        variant="standard"
                    >
                        <MenuItem selected key={0} value={0}>
                            ---Select Account---
                        </MenuItem>
                        {accounts &&
                            accounts.map((account) => (
                                <MenuItem key={account.id} value={account.id}>
                                    {account.accountName}
                                </MenuItem>
                            ))}
                    </Select>
                    <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        value={addNewItemDetail.status}
                        onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, status: e.target.value })}
                        style={{ width: '100%', marginBottom: 15 }}
                        variant="standard"
                    >
                        <MenuItem selected key={0} value="Active">
                            Active
                        </MenuItem>
                        <MenuItem key={1} value="InActive">
                            InActive
                        </MenuItem>
                    </Select>
                    {id == 0 ? (
                        <Button variant="contained" color="secondary" className="text-white btn-success" onClick={() => addNewItem()}>
                            Add
                        </Button>
                    ) : (
                        <Button variant="contained" color="secondary" className="text-white" onClick={() => updateItem()}>
                            Update
                        </Button>
                    )}

                    {'  '}
                    <Button variant="contained" color="error" className="text-white" onClick={() => navigate('/app/mop-master-list')}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}
