import { useState, useEffect } from 'react';
import { TextField, Box, Switch, MenuItem, Stack, useTheme, Button, FormControlLabel } from '@mui/material';

import api from 'api';
import Header from '../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../theme';
import moment from 'moment';

export default function SMSSettingsMaster() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selectedSection, setSelectedSection] = useState('0');
    const [smsSettings, setSmsSettings] = useState({
        sms: '',
        emailSubject: '',
        email: '',
        section: selectedSection,
        sendSmsonTransaction: false,
        smsapi: '',
        emailApi: ''
    }); 
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedSection == '0') {
            var arr = { ...smsSettings };
            arr['sms'] = '';
            arr['emailSubject'] = '';
            arr['email'] = '';
            arr['sendSmsonTransaction'] = false;
            arr['smsapi'] = '';
            arr['emailApi'] = '';
            setSmsSettings((prevState) => arr);
        } else {
            setLoading(true);
            (async () => {
                await api
                    .get('api/settings/get-sms-settings?section=' + selectedSection)
                    .then((response) => {
                        setSmsSettings(response.data);
                    })
                    .catch((error) => {})
                    .finally(() => {
                        setLoading(false);
                    });
            })();
        }
    }, [selectedSection]);

    const saveSMSSettings = async () => {
        if (selectedSection == '0') {
            NotificationManager.error('Select Section !');
            return;
        }

        var temp = smsSettings;
        temp.section=selectedSection; 
        console.log(JSON.stringify(temp)); 
        setLoading(true);
       
       
        await api
            .post('api/settings/set-sms-settings', JSON.stringify(temp))
            .then((response) => { 
                    NotificationManager.success('SUCCESS'); 
            })
            .catch((error) => { 
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChangeSMSSettings = (key, value) => {
        var arr = { ...smsSettings };
        arr[key] = value;
        setSmsSettings((prevState) => arr);
    };

    return (
        <Box m="20px">
            <Header title="SMS Settings" subtitle="Set sms/email settings here" />
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Box backgroundColor={colors.primary[400]} gridColumn="span 12" p={2}>
                    <TextField
                        label="Select Branch"
                        value={selectedSection}
                        onChange={(e) => setSelectedSection(e.target.value)}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem value="0">---Select Section---</MenuItem>
                        <MenuItem key="RestaurantSalebill" value="RestaurantSalebill">
                            Restaurant Sale Bill
                        </MenuItem>
                    </TextField>
                </Box>

                <Stack gridColumn="span 12" direction={'column'} spacing={2} backgroundColor={colors.primary[400]} p={2}>
                    <TextField
                        variant="standard"
                        label="SMS Template"
                        fullWidth
                        value={smsSettings.sms}
                        onChange={(e) => onChangeSMSSettings('sms', e.target.value)}
                    />
                    <TextField
                        label="Email Subject"
                        fullWidth
                        value={smsSettings.emailSubject}
                        onChange={(e) => onChangeSMSSettings('emailSubject', e.target.value)}
                        variant="standard"
                    />
                    <TextField
                        label="Email Body Template"
                        fullWidth
                        value={smsSettings.email}
                        onChange={(e) => onChangeSMSSettings('email', e.target.value)}
                        variant="standard"
                    />
                    <TextField
                        label="SMS API"
                        fullWidth
                        value={smsSettings.smsapi}
                        onChange={(e) => onChangeSMSSettings('smsapi', e.target.value)}
                        variant="standard"
                    />
                    <TextField
                        label="Email API"
                        fullWidth
                        value={smsSettings.emailApi}
                        onChange={(e) => onChangeSMSSettings('emailApi', e.target.value)}
                        variant="standard"
                    />

                    <Button variant="contained" color="secondary" onClick={saveSMSSettings} disabled={loading}>
                        {loading ? 'Please wait...' : 'Save'}
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}
