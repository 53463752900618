import { useEffect, useState } from 'react';
import { Box, Stack, IconButton, Typography, useTheme, Grid } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import LineChart from '../../components/LineChart';
import GeographyChart from '../../components/GeographyChart';
import BarChart from '../../components/BarChart';
import StatBox from '../../components/StatBox';
import ProgressCircle from '../../components/ProgressCircle';
import { CategoryOutlined, PaymentOutlined, ReceiptLongOutlined, PointOfSale, DownloadOutlined } from '@mui/icons-material';
import api from 'api';
var moment = require('moment');

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(false);
    const [totalReceipt, setTotalReceipt] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [totalPurchase, setTotalPurchase] = useState(0);
    const [startDate, setStartDate] = useState(new Date().toLocaleString());
    const [endDate, setEndDate] = useState(new Date().toLocaleString());

    const [transactionCount, setTransactionCount] = useState(0);
    const [transactions, setTransactions] = useState([]);

    const [totalSaleAllBranch, setTotalSaleAllBranch] = useState(0.0);

    useEffect(() => {
        LoadReport();
        (async () => {
            await LoadLastTransaction();
        })();
    }, []);

    const LoadReport = async () => {
        setLoading(true);
        (async () => {
            await api
                .get('api/accounts/get-total-receipt?period=daily')
                .then((response) => {
                    setTotalReceipt(response.data);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });

            await api
                .get('api/accounts/get-total-payment?period=daily')
                .then((response) => {
                    setTotalPayment(response.data);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });

            await api
                .get('api/sales/get-total-sale?period=daily')
                .then((response) => {
                    setTotalSales(response.data);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });

            await api
                .get('api/purchase/get-total-purchase?period=daily')
                .then((response) => {
                    setTotalPurchase(response.data);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();
    };

    const LoadLastTransaction = async () => {
        await api
            .get('api/rest/report/get-billtax-report?PageNumber=1&PageSize=10', {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    searchText: null
                }
            })
            .then((response) => {
                setTransactionCount(response.data.totalRecords);
                setTransactions(response.data.data);
                console.log(response.data.data);
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box m="20px">
            {/* HEADER */}
            <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
            <Box display="flex" justifyContent="space-between" alignItems="center">
               

                {/* <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px'
                        }}
                    >
                        <DownloadOutlined sx={{ mr: '10px' }} />
                        Download Reports
                    </Button>
                </Box> */}
            </Box>
            {/* ROW 1 */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} md={3}>
                    <Box backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" p={3}>
                        <StatBox
                            title={totalSales}
                            subtitle="Sales"
                            progress="0.75"
                            //increase="+14%"
                            icon={<PointOfSale sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" p={3}>
                        <StatBox
                            title={totalPurchase}
                            subtitle="Purchase"
                            progress="0.50"
                            //increase="+21%"
                            icon={<CategoryOutlined sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" p={3}>
                        <StatBox
                            title={totalReceipt}
                            subtitle="Receipt"
                            progress="0.30"
                            //increase="+5%"
                            icon={<ReceiptLongOutlined sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" p={3}>
                        <StatBox
                            title={totalPayment}
                            subtitle="Payment"
                            progress="0.80"
                            //increase="+43%"
                            icon={<PaymentOutlined sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
                        />
                    </Box>
                </Grid>
            </Grid>
            {/* ROW 2 */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} md={8}>
                    <Box height={300} backgroundColor={colors.primary[400]} p={3}>
                        <Box mt="25px" p="0 30px" display="flex " justifyContent="space-between" alignItems="center">
                            <Box>
                                <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                                    Revenue Generated
                                </Typography>
                                <Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
                                    Rs.{totalSaleAllBranch}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton>
                                    <DownloadOutlined sx={{ fontSize: '26px', color: colors.greenAccent[500] }} />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box height="250px" m="-20px 0 0 0">
                            <LineChart isDashboard={true} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box height={300} backgroundColor={colors.primary[400]} overflow="auto" p={3}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            colors={colors.grey[100]}
                            p="15px"
                        >
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                                Recent Transactions
                            </Typography>
                        </Box>
                        {transactions &&
                            transactions.map((transaction, i) =>
                                transaction.orderType != null ? (
                                    <Box
                                        key={`${transaction.orderNo}-${i}`}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        borderBottom={`4px solid ${colors.primary[500]}`}
                                        p="15px"
                                    >
                                        <Box>
                                            <Typography color={colors.greenAccent[500]} variant="h5" fontWeight="600">
                                                {transaction.invoiceNo}
                                            </Typography>
                                            <Typography color={colors.grey[100]}>{transaction.user}</Typography>
                                        </Box>
                                        <Box color={colors.grey[100]}>{moment(transaction.invoiceDate).format('YYYY-MM-DD hh:mm A')}</Box>
                                        <Box backgroundColor={colors.greenAccent[500]} p="5px 10px" borderRadius="4px">
                                            Rs.{transaction.netAmount}
                                        </Box>
                                    </Box>
                                ) : null
                            )}
                    </Box>
                </Grid>
            </Grid>
            {/* ROW 3 */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} md={4}>
                    <Box height={300} backgroundColor={colors.primary[400]} p={3}>
                        <Typography variant="h5" fontWeight="600">
                            Campaign
                        </Typography>
                        <Box display="flex" flexDirection="column" alignItems="center" mt="25px">
                            <ProgressCircle size="125" />
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{ mt: '15px' }}>
                                $48,352 revenue generated
                            </Typography>
                            <Typography>Includes extra misc expenditures and costs</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box height={300} backgroundColor={colors.primary[400]} p={3}>
                        <Typography variant="h5" fontWeight="600" sx={{ padding: '30px 30px 0 30px' }}>
                            Sales Quantity
                        </Typography>
                        <Box height="250px" mt="-20px">
                            <BarChart isDashboard={true} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box height={300} backgroundColor={colors.primary[400]} p={3}>
                        <Typography variant="h5" fontWeight="600" sx={{ marginBottom: '15px' }}>
                            Geography Based Traffic
                        </Typography>
                        <Box height="200px">
                            <GeographyChart isDashboard={true} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
