import React, { useState, useEffect } from 'react';
import {useFetchMopMaster} from '../../hooks/useFetchData';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { Helmet } from "react-helmet";
import api from 'api';
// page title bar
import Header from 'components/Header';

import { Button } from 'reactstrap';

import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';



import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import MakePayment from '../../component/CustomComponentRestaurant/MakePayment';

import {
    Card,
    CardBody,
    CardHeader,
    CardFooter
} from 'reactstrap';

export default function TableList(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const {data : mopMaster} = useFetchMopMaster();

    const billDetailsInitialValue = {
        menuItems: [],
        startSubmit: false,
        discPer: 0,
        netAmt: 0,
        mopDatas: mopMaster == undefined ? [] : mopMaster.data,
        paidAmount: 0,
        customer: null,
    };
    const [billDetails, setBillDetails] = useState(billDetailsInitialValue);
   

    const TableName = location.state.tableName; 


    useEffect(() => {
        (async () => {
            if (billDetails.menuItems.length == 0 && mopMaster != undefined) {
                await api.get(`/api/rest/kot/get-billdetails-table?tableName=${TableName}`)
                    .then((response) => {
                        var tmopDatas = billDetails.mopDatas;
                        tmopDatas[0].amount = response.data.netInvoiceValue;
                        setBillDetails({
                            ...billDetails,
                            menuItems: response.data, 
                            netAmt: response.data.netInvoiceValue,
                            paidAmount: response.data.netInvoiceValue,
                            mopDatas:tmopDatas
                        });
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error.response.data));
                    }).finally(() => {
                    });
            }
        })();
    }, []);

    const handleSave = async () => {
        if (billDetails.menuItems == undefined) {
            NotificationManager.error("No data found !");
            return
        }
        if (billDetails.paidAmount != billDetails.netAmt) {
            NotificationManager.error("Payable amount must be same as paid amount !");
            return
        }
        setBillDetails({ ...billDetails, startSubmit: true });

        //console.log(billDatas);
        const billData = {
            mopList: billDetails.mopDatas,
            bIllID: billDetails.menuItems.id,
        };
        //console.log(JSON.stringify(billData));
        await api.post('api/rest/kot/make-payment', JSON.stringify(billData))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Payment Received Successfully.");
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
            }).finally(() => {
                navigate('/app/new-order');
            });
    };


    return (
        <div className="card-wrapper">
            {/* HEADER */}
            <Header title="MAKE PAYMENT" subtitle="manage item category" />
            <div className="col-md-12">
                <Card>
                    <CardHeader color="warning">
                        {billDetails.startSubmit &&
                            <RctSectionLoader />
                        }
                        <div className="row">
                            <div className="col-md-10">
                                <b>Make Payment (Table Name : {TableName})</b>
                            </div>
                            <div className="col-md-2">
                                <Button color="danger"
                                    disabled={billDetails.startSubmit}
                                    onClick={handleSave} >{billDetails.startSubmit ? 'Please wait...' : 'Save'}</Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <RctCollapsibleCard>
                            <MakePayment billDetails={billDetails} setBillDetails={setBillDetails} />
                        </RctCollapsibleCard>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
